import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withFocus } from '@accedo/vdkweb-navigation';
import { TickIcon } from '#/components/Icons/Icons';
import { FocusButton } from '#/components/Button/Button';
import theme from './selectorItem.scss';

const getDisplayText = (item, type) => {
  return item.label || item.title || item.displayText || `${type}-${item.id}`;
};

const SelectorItem = ({
  containerStyle,
  data: item,
  displayType,
  height,
  keyProperty,
  onClick,
  nav,
  selectedItem,
  type,
  width
}) => {
  const wrapperStyle = {
    ...containerStyle,
    ...(width ? { width } : {}),
    ...(height ? { height } : {}),
    ...(displayType ? { display: displayType } : { display: 'inline-block' })
  };

  const displayText = getDisplayText(item, type);
  if (!item[keyProperty]) {
    // workaround for special items
    item[keyProperty] = `${type}-${item.id}`;
  }

  const onItemClick = useCallback(() => {
    onClick(item);
  }, [item, onClick]);

  const isSelected = selectedItem && selectedItem.id === item.id;

  return (
    <div style={wrapperStyle}>
      <FocusButton
        key={item.id}
        isSelected={isSelected}
        className={theme.button}
        displayText={displayText}
        theme={theme}
        nav={nav}
        onClick={onItemClick}
        Icon={isSelected ? TickIcon : null}
        iconClassName={theme.icon}
      />
    </div>
  );
};

SelectorItem.propTypes = {
  containerStyle: PropTypes.object,
  data: PropTypes.object.isRequired,
  displayType: PropTypes.string,
  height: PropTypes.any,
  keyProperty: PropTypes.string,
  nav: PropTypes.object,
  onClick: PropTypes.func,
  selectedItem: PropTypes.object,
  type: PropTypes.string,
  width: PropTypes.any
};

export default withFocus(SelectorItem);
