import React from 'react';
import PropTypes from 'prop-types';
import { withFocus } from '@accedo/vdkweb-navigation';
import { Button } from '#/components/Button/Button';
import ImageWithPreload from '#/components/ImageWithPreload/ImageWithPreload';
import { getTranslation } from '#/services/i18n';
import { GAME_STATUS, LEAGUES } from '#/config/constants';
import heroGradient from '#/static/images/hero/gradient.png';

import heroItemTheme from './heroItem.scss';

const HeroItem = ({ data, onClick, theme = heroItemTheme, nav = {} }) => {
  console.log('status data: ', data);
  const backgroundClassName = `${theme.background} ${
    nav.isFocused ? theme.backgroundFocused : ''
  }`;
  const isScheduled =
    data?.status === GAME_STATUS.SCHEDULED || data?.status === 'scheduled';
  const isLive =
    data?.status === GAME_STATUS.LIVE || data?.status === 'inprogress';
  const isFinal =
    data?.status === GAME_STATUS.FINAL || data?.status === 'closed';

  return (
    <div
      className={backgroundClassName}
      onMouseOver={nav.onMouseOver}
      onMouseOut={nav.onMouseOut}
    >
      <img src={data.image} alt="hero-banner" />
      <div className={heroItemTheme.bgGradient}>
        <img src={heroGradient} alt="hero-gradient" />
      </div>
      <div className={theme.contentContainer}>
        {/* Live tag indicator */}
        {isLive && (
          <div className={theme.tag}>
            <span className={theme.live} />
            {getTranslation('liveTag')}
          </div>
        )}
        {/* Match title with both teams */}
        <div className={theme.title}>
          {`${data?.homeCity} ${data?.homeTeamName} ${getTranslation('at')}`}
          <br />
          {`${data?.awayCity} ${data?.awayTeamName}`}
        </div>
        {/* Scheduled match date */}
        {isScheduled && <div className={theme.subtitle}>{data?.fullDate}</div>}
        {isScheduled && data?.league === LEAGUES.MLB && (
          <div
            className={theme.stadium}
          >{`${data?.stadiumName}, ${data?.stadiumCity}`}</div>
        )}
        {/* Team scores in case the game is on final state or live */}
        {(isFinal || isLive) && data?.league === LEAGUES.NBA && (
          <div className={theme.scoreContainer}>
            <div className={theme.metadata}>
              <img
                className={theme.teamLogo}
                src={data?.homeTeamLogo}
                alt="team-logo"
              />
              <span>{data?.homeTeam}</span>
              <span>{data?.homeTeamScore}</span>
            </div>
            <div className={theme.metadata}>
              <img
                className={theme.teamLogo}
                src={data?.awayTeamLogo}
                alt="team-logo"
              />
              <span>{data?.awayTeam}</span>
              <span>{data?.awayTeamScore}</span>
            </div>
          </div>
        )}
        {/* Actions container */}
        <div className={theme.descriptionContainer}>
          <span>{data?.description}</span>
        </div>
        <div className={theme.buttonContainer}>
          <Button
            className={theme.button}
            onClick={onClick}
            displayText="Watch Now"
            isFocused
          />
        </div>
      </div>
    </div>
  );
};

HeroItem.propTypes = {
  data: PropTypes.object,
  nav: PropTypes.object,
  onClick: PropTypes.func,
  theme: PropTypes.object
};

export default withFocus(HeroItem);
