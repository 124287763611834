/* eslint-disable eslint-comments/disable-enable-pair */

import React, { useState, useCallback, createContext } from 'react';
import PropTypes from 'prop-types';

export const MenuContext = createContext({
  isVisible: true,
  show: () => {},
  hide: () => {},
  isOpen: true,
  open: () => {},
  close: () => {}
});

const MenuProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  const show = useCallback(() => setIsVisible(true), []);

  const hide = useCallback(() => setIsVisible(false), []);

  const open = useCallback(() => setIsOpen(true), []);

  const close = useCallback(() => setIsOpen(false), []);

  return (
    <MenuContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ isVisible, show, hide, isOpen, open, close }}
    >
      {children}
    </MenuContext.Provider>
  );
};

MenuProvider.propTypes = {
  children: PropTypes.node
};

export default MenuProvider;
