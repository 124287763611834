import * as vikimap from '@accedo/vdkweb-vikimap';

import { getControlClientSingleton } from '#/providers/shared/control/control';

let accedoControlService = null;
let accedoOneClientInstance = null;
let configuration = null;
let initiated = null;
let segmentInitiated = null;

const CONFIG_KEYS_TOCHECK = ['application', 'featureFlags', 'providers']; // TODO: devs, review the needs of your app and update this based on your needs

const parseMetaData = metadata => {
  if (!metadata.application) {
    metadata.application = {
      i18n: {
        defaultLocale: metadata.defaultLocale
      },
      navigation: {
        defaultRoute: '/'
      },
      player: {
        bookmarkUpdateIntervalMs: 15000
      },
      search: {
        searchTriggerChars: 3,
        timeoutMilliseconds: metadata.searchInvokeTimeoutMillis
      },
      theme: {
        default: 'default'
      }
    };
  }

  if (!metadata.providers) {
    metadata.providers = {
      cms: {
        accedoControl: {
          menu: metadata.mainMenuEntryId,
          routesToPagesMappingList: metadata.routesMapping
        }
      },
      i18n: {
        accedoControl: {
          dictionary: metadata.dictionary
        }
      },
      ovp: {
        accedoOvp: {
          pageSize: metadata.ovpPageSize,
          url: metadata.ovpEndpointURL
        }
      },
      theme: {
        accedoControl: {
          default: metadata.theme.default
        }
      }
    };
  }
};

const checkEmptyValue = (configKey, metadata) => {
  if (metadata[configKey] === undefined || metadata[configKey] === '') {
    console.error(
      `[debug] Config Key ${configKey} is not set up, please review your local and remote config to add it`
    );
  }
};

const checkEmptyValues = metadata => {
  CONFIG_KEYS_TOCHECK.forEach(configKey => {
    checkEmptyValue(configKey, metadata);
  });

  return metadata;
};

export const init = async segmentationValue => {
  accedoOneClientInstance = await getControlClientSingleton(segmentationValue);

  if (!accedoControlService) {
    accedoControlService = vikimap.getAccedoOneService(accedoOneClientInstance);
  }
  initiated = true;
};

/**
 *
 * Fetch the Configuration CMS content
 * @param {string} [segmentationValue] string with the segmentation value for the gid
 * @returns {Configuration} The Configuration
 */
const getConfiguration = async segmentationValue => {
  if (!initiated || segmentInitiated !== segmentationValue) {
    await init(segmentationValue);
  }
  if (configuration && segmentInitiated === segmentationValue) {
    return configuration;
  }

  segmentInitiated = segmentationValue;

  const [
    { value: metadata },
    { value: assets },
    { value: status }
  ] = await Promise.allSettled([
    accedoOneClientInstance.getAllMetadata({ gid: segmentationValue }),
    accedoOneClientInstance.getAllAssets(),
    accedoOneClientInstance.getApplicationStatus()
  ]);

  // temporary workaround parsing the Control Config from 2.0->5.0
  parseMetaData(metadata);
  checkEmptyValues(metadata);
  configuration = {
    ...status,
    ...metadata,
    assets
  };

  return configuration;
};

export default () => {
  return {
    getConfiguration
  };
};
