import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import theme from './team.scss';

const PlayerCard = ({ data, nav }) => {
  const backgroundClassName = classNames(theme.background, theme.cardHeight);
  console.log('statuslogo: ', data);

  return (
    <>
      <div
        className={backgroundClassName}
        style={{
          background: data.primaryColor
        }}
      >
        <img src={data.logo} alt={data.name} />
      </div>
      {nav.isFocused && <p className={theme.teamName}>{data.name}</p>}
    </>
  );
};

PlayerCard.propTypes = {
  data: PropTypes.object.isRequired,
  nav: PropTypes.object
};

export default PlayerCard;
