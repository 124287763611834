import MenuItem from '#/interfaces/MenuItem';

interface PageView {
  template: string;
  hasSubroute: boolean;
}

interface ItemPageLayout extends PageView {
  suspense: boolean;
  analyticsScreenType: string;
  analyticsOnPageLayoutLoad: boolean;
  view: any;
}

const getRouteInfoFromPageLayout = ({
  itemPageLayout,
  routeItem,
  pageLayoutContainerWithSuspense,
  pageLayoutContainer
}: {
  itemPageLayout: ItemPageLayout;
  routeItem: MenuItem;
  pageLayoutContainerWithSuspense: any;
  pageLayoutContainer: any;
}) => {
  const {
    view,
    analyticsScreenType: screenType,
    suspense,
    hasSubroute,
    analyticsOnPageLayoutLoad = false
  } = itemPageLayout;

  const innerElement = pageLayoutContainer({
    view,
    screenType,
    analyticsOnPageLayoutLoad
  });
  const element = suspense
    ? pageLayoutContainerWithSuspense({
        innerElement
      })
    : innerElement;
  if (hasSubroute || routeItem.extendedRoute) {
    return {
      path: routeItem.route,
      children: [
        {
          path: ':subroute',
          element
        }
      ]
    };
  }
  return {
    path: routeItem.route,
    element
  };
};

export { getRouteInfoFromPageLayout };
