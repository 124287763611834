import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import metaData from '#/theme/metadata.scss';
import theme from './watch.scss';

const WatchCard = ({ data, isFocused }) => {
  const backgroundClassName = classNames(theme.cardHeight, theme.background);
  const imageWrapperClassName = classNames(theme.cardImageWrapper, {
    [theme.focused]: isFocused
  });

  return (
    <div>
      <div className={imageWrapperClassName}>
        <div
          className={backgroundClassName}
          style={{
            background: `url(${data?.image}) top no-repeat`
          }}
        />
      </div>
      <div className={theme.dateItem}>
        <p>{data.date}.</p>
      </div>
      <div className={metaData.container}>
        <p>{data.title}</p>
      </div>
    </div>
  );
};

WatchCard.propTypes = {
  data: PropTypes.object.isRequired,
  isFocused: PropTypes.bool
};

export default WatchCard;
