/**
 * @module services/sports/mlb
 * @description
 * MLB SportsLeague Service
 */
import { PROVIDER_TYPE, TYPE } from '#/config/constants';
import { formatDate } from '#/utils/dateUtils';

import ovp from '#/providers/ovp';

import Game from '#/models/mlb/game/game';
import Team from '#/models/mlb/team/team';
import BoxScore from '#/models/mlb/boxScore/boxScore';
import Player from '#/models/mlb/player/player';

import fetcher from '../../helpers/fetcher';

const getDateFromQueryParam = param => {
  const parameter = param.toString();
  const date = new Date();

  const tomorrow = new Date(new Date().setDate(date.getDate() + 1));
  const yesterday = new Date(new Date().setDate(date.getDate() - 1));

  if (parameter === 'final') {
    return yesterday;
  }
  if (parameter === 'upcoming') {
    return tomorrow;
  }
  return date;
};

/* MLB listing services */
const getGamesByDate = async date => {
  const rawGames = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-mlb-games-by-date-${formatDate(date)}`,
    fetchFn: () => ovp.getMlbGamesByDate(date)
  });

  return rawGames?.map(game => Game(game));
};

const getAllTeams = async () => {
  const rawTeams = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-mlb-all-teams`,
    fetchFn: () => ovp.getMlbAllTeams()
  });

  return rawTeams?.map(team => Team(team));
};

const getDemoPlayers = async () => {
  const rawPlayers = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-mlb-demo-players`,
    fetchFn: () => ovp.getMlbDemoPlayers()
  });

  return rawPlayers?.map(player => Player(player));
};

const getBoxScoreByGame = async id => {
  const rawPlayerGames = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-mlb-box-score-${id}`,
    fetchFn: () => ovp.getMlbBoxScoreByGame(id)
  });

  return rawPlayerGames?.map(playerGame => BoxScore(playerGame));
};

const getDailyBoxScore = async date => {
  //
  const rawPlayerGames = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-mlb-box-score-${formatDate(date)}`,
    fetchFn: () => ovp.getMlbDailyBoxScore(date)
  });

  return rawPlayerGames?.map(playerGame => BoxScore(playerGame));
};

/**
 * @param {String} query The query for the OVP.
 * @returns {Array} Array of Items
 */

const getItemsByQuery = async ({ query }) => {
  // Match with '{{something}}'
  const params = query?.match(/[^{}]+(?=})/g);
  let items;

  if (query.includes('games')) {
    const date = getDateFromQueryParam(params);
    items = await getDailyBoxScore(date);

    return { items, total: items?.length, type: TYPE.GAME };
  }
  if (query.includes('teams')) {
    items = await getAllTeams();

    return { items, total: items?.length, type: TYPE.TEAM };
  }
  if (query.includes('players')) {
    items = await getDemoPlayers();

    return { items, total: items?.length, type: TYPE.PLAYER };
  }

  return {
    items: [],
    total: 0
  };
};

export { getGamesByDate, getAllTeams, getBoxScoreByGame, getItemsByQuery };
