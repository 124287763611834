/**
 * @module services/episode
 * @description
 * Service to handle the fetch of episodes
 */
import { PROVIDER_TYPE } from '#/config/constants';
import Episode from '#/models/episode/episode';
import ovp from '#/providers/ovp/accedo';
// eslint-disable-next-line jest/no-mocks-import
import fallbackEpisode from '#/__mocks__/fallbackEpisode';
import { getSegmentationCachePart } from '#/utils/segmentationUtils';

import fetcher from '../../helpers/fetcher';

/**
 * Cache TV Shows
 * @type {Object}
 */
const tvShows = {};

/**
 * Get the TV Show from a ID
 * @param {Object} rawEpisode Raw Episode
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {Promise<Object>} TV Show
 */
const getTvShow = async (rawEpisode, segmentationValue) => {
  const segmentationPart = getSegmentationCachePart(segmentationValue);
  const { metadata } = rawEpisode;
  const showId = metadata.find(item => item.name === 'VOD$tvShowId');
  if (tvShows[showId.value]) {
    return tvShows[showId.value];
  }

  const tvShowPayload = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-show-${showId.value}${segmentationPart}`,
    fetchFn: () => ovp.getTvShowById(showId.value)
  });

  const {
    credits,
    categories,
    images,
    tvSeasonCount,
    description
  } = tvShowPayload;

  tvShows[showId.value] = {
    credits,
    categories,
    images,
    tvSeasonCount,
    tvShowDescription: description
  };

  return tvShows[showId.value];
};

/**
 * Get episodes from a season
 * @param {string|number} id Season Id
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {Promise<Array<Episode>>} Episodes
 */
const getEpisodes = async (id, segmentationValue) => {
  const segmentationCachePart = getSegmentationCachePart(segmentationValue);
  const rawEpisodes = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-season-episodes-${id}${segmentationCachePart}`,
    fetchFn: () => ovp.getTvSeasonEpisodesById(id, segmentationValue)
  });

  const episodes = await Promise.all(
    rawEpisodes.entries.map(async rawEpisode => {
      const rawTvShow = await getTvShow(rawEpisode, segmentationValue);

      const fullRawEpisode = {
        ...rawTvShow,
        ...rawEpisode
      };

      return Episode(fullRawEpisode, segmentationValue);
    })
  );
  episodes.sort(
    (episodeA, episodeB) => episodeA.episodeNumber - episodeB.episodeNumber
  );
  return episodes;
};

const getFallbackEpisodes = numberOfEpisodes => {
  const fallbackEpisodes = [];
  for (let i = 0; i < numberOfEpisodes; i += 1) {
    fallbackEpisode.id = `${Math.random()}-${fallbackEpisode.id}`;
    fallbackEpisodes.push(Episode(fallbackEpisode));
  }
  return fallbackEpisodes;
};

/**
 * Get an episode
 * @param {string|number} id Episode Id
 @param {string} [segmentationValue] optional segment to get a different config
 * @returns {Promise<Episode>} Episode
 */
const getEpisode = async (id, segmentationValue) => {
  const segmentationCachePart = getSegmentationCachePart(segmentationValue);
  const rawEpisode = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-episode-${id}${segmentationCachePart}`,
    fetchFn: () => ovp.getEpisodeById(id, segmentationValue)
  });
  if (!rawEpisode) {
    return null;
  }
  const rawTvShow = await getTvShow(rawEpisode, segmentationValue);
  const fullRawEpisode = {
    ...rawEpisode,
    ...rawTvShow
  };
  return Episode(fullRawEpisode, segmentationValue);
};

const getCurrentSeasonIndex = (episode, seasons) => {
  const currentEpisodeSeasonId = episode.seasonId;
  const seasonIds = seasons.map(seasonItem => seasonItem.id);
  return seasonIds.indexOf(currentEpisodeSeasonId);
};

const getCurrentEpisodeIndex = (episode, episodes) => {
  const currentEpisodeNumber = episode.episodeNumber;
  const episodeNumbers = episodes.map(episodeItem => episodeItem.episodeNumber);
  return episodeNumbers.indexOf(currentEpisodeNumber);
};

const getPrevEpisodeFromPrevSeason = async ({
  episode,
  seasons,
  segmentationValue
} = {}) => {
  const currentSeasonIndex = getCurrentSeasonIndex(episode, seasons);
  if (currentSeasonIndex > 0) {
    const previousSeason = seasons[currentSeasonIndex - 1];
    const previousSeasonEpisodes = await getEpisodes(
      previousSeason.id,
      segmentationValue
    );
    previousSeasonEpisodes.sort(
      (episodeA, episodeB) => episodeA.episodeNumber - episodeB.episodeNumber
    );
    return previousSeasonEpisodes[previousSeasonEpisodes.length - 1];
  }
  return null;
};

/**
 * Gets the previous episode from a provided episode based on the episodes and seasons
 * @param {object} params parameter object
 * @param {Episode} params.episode episode to look for
 * @param {Episode[]} params.episodes list of episodes associated with the episode
 * @param {Season[]} params.seasons list of seasons associated with the episode
 * @param {string} [params.segmentationValue] optional segment to get a different value based on config
 * @returns {Episode|undefined} previous episode or nothing
 */
const getPrevEpisode = async ({
  episode,
  episodes,
  seasons,
  segmentationValue
} = {}) => {
  const currentEpisodeIndex = getCurrentEpisodeIndex(episode, episodes);
  if (currentEpisodeIndex > 0) {
    return episodes[currentEpisodeIndex - 1];
  }
  return getPrevEpisodeFromPrevSeason({ episode, seasons, segmentationValue });
};

const getEpisodesFromPrevSeason = async ({
  episode,
  seasons,
  segmentationValue
}) => {
  const currentSeasonIndex = seasons.findIndex(
    season => season.tvSeasonNumber === episode.seasonNumber
  );
  const prevSeason = seasons[currentSeasonIndex - 1]
    ? seasons[currentSeasonIndex - 1]
    : seasons[0];
  const prevSeasonEpisodes = await getEpisodes(
    prevSeason.id,
    segmentationValue
  );
  return prevSeasonEpisodes;
};

/**
 * Gets the previous episodes from a provided episode based on the episodes and seasons
 * @param {object} params parameter object
 * @param {Episode} params.episode episode to look for
 * @param {Episode[]} params.episodes list of episodes associated with the episode
 * @param {Season[]} params.seasons list of seasons associated with the episode
 * @param {string} [params.segmentationValue] optional segment to get a different value based on config
 * @returns {Promise<Episode[]>} next episodes
 */
const getPrevEpisodes = async ({
  episode,
  episodes,
  seasons,
  segmentationValue
} = {}) => {
  const currentEpisodeIndex = getCurrentEpisodeIndex(episode, episodes);
  if (currentEpisodeIndex > 0) {
    return episodes;
  }
  return getEpisodesFromPrevSeason({ episode, seasons, segmentationValue });
};

/**
 *
 * @param {object} params parameter object
 * @param {Episode} params.episode parameter object
 * @param {Season[]} params.seasons parameter object
 * @param {object} [params.segmentationValue] parameter object
 *
 * @returns {Episode|null} next episode or null if there's none
 */
const getNextEpisodeFromNextSeason = async ({
  episode,
  seasons,
  segmentationValue
} = {}) => {
  const currentSeasonIndex = getCurrentSeasonIndex(episode, seasons);
  if (currentSeasonIndex < seasons.length - 1) {
    const nextSeason = seasons[currentSeasonIndex + 1];
    const nextSeasonEpisodes = await getEpisodes(
      nextSeason.id,
      segmentationValue
    );
    nextSeasonEpisodes.sort(
      (episodeA, episodeB) => episodeA.episodeNumber - episodeB.episodeNumber
    );
    return nextSeasonEpisodes[0];
  }
  return null;
};

/**
 * Gets the next episode from a provided episode based on the episodes and seasons
 * @param {object} params parameter object
 * @param {Episode} params.episode episode to look for
 * @param {Episode[]} params.episodes list of episodes associated with the episode
 * @param {Season[]} params.seasons list of seasons associated with the episode
 * @param {string} [params.segmentationValue] optional segment to get a different value based on config
 * @returns {Episode|undefined} next episode or nothing
 */
const getNextEpisode = async ({
  episode,
  episodes,
  seasons,
  segmentationValue
} = {}) => {
  const currentEpisodeIndex = getCurrentEpisodeIndex(episode, episodes);
  if (currentEpisodeIndex < episodes.length - 1) {
    return episodes[currentEpisodeIndex + 1];
  }
  return getNextEpisodeFromNextSeason({ episode, seasons, segmentationValue });
};

const getEpisodesFromNextSeason = async ({
  episode,
  seasons,
  segmentationValue
}) => {
  const currentSeasonIndex = seasons.findIndex(
    season => season.tvSeasonNumber === episode.seasonNumber
  );
  const nextSeason = seasons[currentSeasonIndex + 1]
    ? seasons[currentSeasonIndex + 1]
    : seasons[0];
  const nextSeasonEpisodes = await getEpisodes(
    nextSeason.id,
    segmentationValue
  );
  return nextSeasonEpisodes;
};

/**
 * Gets the next episodes from a provided episode based on the episodes and seasons
 * @param {object} params parameter object
 * @param {Episode} params.episode episode to look for
 * @param {Episode[]} params.episodes list of episodes associated with the episode
 * @param {Season[]} params.seasons list of seasons associated with the episode
 * @param {string} [params.segmentationValue] optional segment to get a different value based on config
 * @returns {Promise<Episode[]>} next episodes
 */
const getNextEpisodes = async ({
  episode,
  episodes,
  seasons,
  segmentationValue
} = {}) => {
  const currentEpisodeIndex = getCurrentEpisodeIndex(episode, episodes);
  if (currentEpisodeIndex < episodes.length - 1) {
    return episodes;
  }
  return getEpisodesFromNextSeason({ episode, seasons, segmentationValue });
};

export {
  getEpisode,
  getEpisodes,
  getNextEpisode,
  getPrevEpisode,
  getNextEpisodes,
  getPrevEpisodes,
  getFallbackEpisodes
};
