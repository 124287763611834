import allen from '#/static/images/merchandise/allen-iverson-mitchell-jersey.png';
import stephen from '#/static/images/merchandise/stephen-curry-jersey.png';
import jayson from '#/static/images/merchandise/jayson-tatum-jersey.png';
import jordan from '#/static/images/merchandise/michael-jordan-jersey.png';
import luka from '#/static/images/merchandise/luka-doncic-jersey.png';
import lebron from '#/static/images/merchandise/lebron-james-mitchell-jersey.png';
import joel from '#/static/images/merchandise/joel-embiid-jersey.png';
import giannis from '#/static/images/merchandise/giannis-antetokounmpo-jersey.png';
import damian from '#/static/images/merchandise/damian-lillard-jersey.png';

export const merchandiseMockData = [
  {
    id: 1,
    title: 'Allen Iverson Mitchell Jersey',
    image: allen,
    brand: 'Nike',
    price: '$209.99'
  },
  {
    id: 2,
    title: 'Stephen Curry Jersey',
    image: stephen,
    brand: 'Nike',
    price: '$89.99'
  },
  {
    id: 3,
    title: 'Jayson Tatum Jersey',
    image: jayson,
    brand: 'Adidas',
    price: '$109.99'
  },
  {
    id: 4,
    title: 'Michael Jordan Jersey',
    image: jordan,
    brand: 'Nike',
    price: '$79.99'
  },
  {
    id: 5,
    title: 'Luka Doncic Jersey',
    image: luka,
    brand: 'Adidas',
    price: '$99.99'
  },
  {
    id: 6,
    title: 'LeBron James Jersey',
    image: lebron,
    brand: 'Nike',
    price: '$149.99'
  },
  {
    id: 7,
    title: 'Joel Embiid Jersey',
    image: joel,
    brand: 'Nike',
    price: '$119.99'
  },
  {
    id: 8,
    title: 'Giannis Antetokounmpo Jersey',
    image: giannis,
    brand: 'Nike',
    price: '$119.99'
  },
  {
    id: 9,
    title: 'Damian Lillard Jersey',
    image: damian,
    brand: 'Adidas',
    price: '$119.99'
  }
];
