import { ITEM_TYPES } from '#/config/constants';
import {
  card16by9MediumWidth,
  card16by9MediumHeight,
  card16by9LargeHeight,
  card16by9LargeWidth,
  card9by16DefaultHeight,
  card9by16DefaultWidth
} from '#/theme/tokens/js/variables';
import AssetItem from './AssetItem/AssetItem';
import SeasonEpisode from './SeasonEpisode/SeasonEpisode';
import CategoryItem from './CategoryItem/CategoryItem';
import ContinueWatchingItem from './ContinueWatchingItem/ContinueWatchingItem';
import ElevateCategoryItem from './ElevateCategoryItem/ElevateCategoryItem';

const convertToNumber = val => parseInt(val, 10);

export const ITEM_TYPE_TO_COMPONENT_MAP = {
  [ITEM_TYPES.Wide]: AssetItem,
  [ITEM_TYPES.Portrait]: AssetItem,
  [ITEM_TYPES.FeaturedCarouselPortrait]: AssetItem,
  [ITEM_TYPES.GridPortrait]: AssetItem,
  [ITEM_TYPES.Landscape]: AssetItem,
  [ITEM_TYPES.Square]: CategoryItem,
  [ITEM_TYPES.Bookmark]: ContinueWatchingItem,
  [ITEM_TYPES.Categories]: AssetItem,
  [ITEM_TYPES.SeasonEpisode]: SeasonEpisode,
  [ITEM_TYPES.SearchMovie]: AssetItem,
  [ITEM_TYPES.SearchEpisode]: AssetItem,
  [ITEM_TYPES.WideBig]: AssetItem,
  [ITEM_TYPES.WideBigMlb]: AssetItem
};

/** Dimensions in px */
export const ITEM_SIZE = {
  [ITEM_TYPES.Wide]: {
    width: convertToNumber(card16by9MediumWidth),
    height: convertToNumber(card16by9MediumHeight)
  },
  [ITEM_TYPES.Portrait]: {
    width: convertToNumber(card9by16DefaultWidth), // merchanise and players
    height: convertToNumber(card9by16DefaultHeight)
  },
  [ITEM_TYPES.GridPortrait]: { width: 330, height: 496 },
  [ITEM_TYPES.GridLandscape]: { width: 590, height: 404 },
  [ITEM_TYPES.FeaturedCarouselPortrait]: { width: 330, height: 496 },
  [ITEM_TYPES.Landscape]: { width: 450, height: 260 },
  [ITEM_TYPES.Square]: { width: 200, height: 200 },
  [ITEM_TYPES.Bookmark]: {
    width: 574,
    height: 415
    // height: convertToNumber(card16by9LargeDefaultHeight)
  }, // for catch up section
  [ITEM_TYPES.Categories]: { width: 356, height: 356 }, // for teams
  [ITEM_TYPES.SeasonEpisode]: { width: 570, height: 400 },
  [ITEM_TYPES.SearchMovie]: { width: 250, height: 375 },
  [ITEM_TYPES.SearchEpisode]: { width: 410, height: 250 },
  [ITEM_TYPES.WideBig]: {
    width: convertToNumber(card16by9LargeWidth),
    height: convertToNumber(card16by9LargeHeight)
  },
  [ITEM_TYPES.WideBigMlb]: { width: 384, height: 576 }
};
