import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withFocus } from '@accedo/vdkweb-navigation';

import buttonTheme from './Button.scss';

// eslint-disable-next-line valid-jsdoc
/**
 * Component that represent a Focusable round-styled Button
 */
const Button = ({
  className,
  onClick,
  theme = buttonTheme,
  displayText,
  isFocused,
  isSelected,
  nav = {},
  style,
  Icon = null,
  iconClassName,
  isFocusedCallback,
  buttonType = 'primary',
  lowercase = false,
  reverse = false,
  disabled = false,
  children
}) => {
  const focused = isFocused || nav.isFocused;

  // useEffect(() => {
  //   isFocusedCallback?.(focused);
  // }, [focused]);

  const buttonClassName = classNames(theme.button, {
    [theme.selected]: isSelected,
    [theme.focused]: focused,
    [theme.primary]: buttonType === 'primary',
    [theme.secondary]: buttonType === 'secondary',
    [theme.lowercase]: lowercase,
    [theme.reverse]: reverse
  });

  const noText = !(displayText || displayText?.trim().length === 0);

  const iconClass = classNames(iconClassName, {
    [theme.reverse]: reverse,
    [theme.iconOnly]: noText
  });

  return (
    <div
      className={className}
      onMouseOver={nav.onMouseOver}
      onMouseOut={nav.onMouseOut}
    >
      <button
        onClick={onClick}
        className={buttonClassName}
        style={style}
        disabled={disabled}
      >
        {displayText} {Icon && <Icon className={iconClass} />}
        {children}
      </button>
    </div>
  );
};

Button.propTypes = {
  /** Module class to apply component */
  className: PropTypes.string,
  /** Text to display in the button */
  displayText: PropTypes.string,
  /** If focused style should apply */
  isFocused: PropTypes.bool,
  /** If seleted style should apply */
  isSelected: PropTypes.bool,
  /** nav object as defined in @accedo/vdkweb-navigation */
  nav: PropTypes.object,
  /** function to handle action on the item */
  onClick: PropTypes.func,
  /** style object */
  style: PropTypes.object,
  /** theme object */
  theme: PropTypes.object,
  /** Icon/Image to use along with the textin the Button */
  Icon: PropTypes.elementType,
  /** Icon/Image to use along with the textin the Button of details page */
  // DetailsButtonIcon: PropTypes.elementType,
  /** Module class to apply to the Icon if applicable */
  iconClassName: PropTypes.string,
  isFocusedCallback: PropTypes.func,
  buttonType: PropTypes.string,
  // default: false. Transform text style of button
  lowercase: PropTypes.bool,
  // Default: false => text on left and icon on right. Setting is to true will add icon before text
  reverse: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node
};

const FocusButton = withFocus(Button);

export { Button, FocusButton };
