import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import theme from './merchandise.scss';

const MerchandiseCard = ({ data }) => {
  const backgroundClassName = classNames(theme.background, theme.cardHeight);
  return (
    <>
      <div className={backgroundClassName}>
        <div className={theme.brand}>{data.brand}</div>
        <img src={data.image} alt={data.title} className={theme.image} />
      </div>
      <div className={theme.merchDetails}>
        <p>{data.title}</p>
        <p>{data.price}</p>
      </div>
    </>
  );
};

MerchandiseCard.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    brand: PropTypes.string
  }).isRequired
};

export default MerchandiseCard;
