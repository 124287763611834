import {
  getHeroDate,
  getTileDate,
  formatTime,
  formatTimeAndZone
} from '#/utils/dateUtils';
import {
  TYPE,
  DEFAULT_COLOR,
  DUMMY_VIDEO_URL,
  LEAGUES
} from '#/config/constants';
import { contrast } from '#/utils/contrast';

const Game = rawGame => {
  const {
    GameID,
    Season,
    SeasonType,
    Status,
    DateTime,
    AwayTeam,
    HomeTeam,
    AwayTeamID,
    HomeTeamID,
    StadiumID,
    Channel,
    AwayTeamScore,
    HomeTeamScore,
    homeTeam,
    awayTeam,
    demoStream
  } = rawGame;

  const getId = () => {
    return GameID?.toString();
  };

  const getSeason = () => {
    return Season;
  };

  const getSeasonType = () => {
    return SeasonType;
  };

  const getStatus = () => {
    return Status;
  };

  const getFullDate = () => {
    return getHeroDate(DateTime);
  };

  const getShortDate = () => {
    return getTileDate(DateTime);
  };

  const getAwayTeam = () => {
    return AwayTeam;
  };

  const getAwayTeamId = () => {
    return AwayTeamID;
  };

  const getHomeTeam = () => {
    return HomeTeam;
  };

  const getHomeTeamId = () => {
    return HomeTeamID;
  };

  const getStadiumId = () => {
    return StadiumID;
  };

  const getChannel = () => {
    return Channel;
  };

  const getAwayTeamScore = () => {
    return AwayTeamScore === null ? 0 : AwayTeamScore;
  };

  const getHomeTeamScore = () => {
    return HomeTeamScore === null ? 0 : HomeTeamScore;
  };

  const getHomeTeamLogo = () => {
    return homeTeam?.logo;
  };

  const getAwayTeamLogo = () => {
    return awayTeam?.logo;
  };

  const getHomeTeamName = () => {
    return homeTeam?.Name;
  };

  const getAwayTeamName = () => {
    return awayTeam?.Name;
  };

  const getTime = () => {
    return formatTime(new Date(DateTime));
  };

  const getTimeAndZone = () => {
    return formatTimeAndZone(new Date(DateTime));
  };

  const getHomeTeamCity = () => {
    return homeTeam?.City;
  };

  const getAwayTeamCity = () => {
    return awayTeam?.City;
  };

  const getHomeTeamColors = () => {
    return {
      primary: !homeTeam.PrimaryColor
        ? DEFAULT_COLOR.primary
        : `#${homeTeam.PrimaryColor}`,
      secondary: !homeTeam.SecondaryColor
        ? DEFAULT_COLOR.secondary
        : `#${homeTeam.SecondaryColor}`,
      tertiary: !homeTeam.TertiaryColor
        ? DEFAULT_COLOR.tertiary
        : `#${homeTeam.TertiaryColor}`,
      quaternary: !homeTeam.QuaternaryColor
        ? DEFAULT_COLOR.quaternary
        : `#${homeTeam.QuaternaryColor}`
    };
  };

  const getAwayTeamColors = () => {
    return {
      primary: !awayTeam.PrimaryColor
        ? DEFAULT_COLOR.primary
        : `#${awayTeam.PrimaryColor}`,
      secondary: !awayTeam.SecondaryColor
        ? DEFAULT_COLOR.secondary
        : `#${awayTeam.SecondaryColor}`,
      tertiary: !awayTeam.TertiaryColor
        ? DEFAULT_COLOR.tertiary
        : `#${awayTeam.TertiaryColor}`,
      quaternary: !awayTeam.QuaternaryColor
        ? DEFAULT_COLOR.quaternary
        : `#${awayTeam.QuaternaryColor}`
    };
  };

  const getVideoUrl = () => {
    return demoStream;
  };

  const getTitle = () => {
    return `${getHomeTeamName()} v ${getAwayTeamName()}`;
  };

  const getDescription = () => {
    return `${getHomeTeamName()} take on the ${getAwayTeamName()} in an epic battle between two of the leagues top teams. You won’t want to miss this one!`;
  };

  const getGameColors = () => {
    const colorVariants = ['primary', 'secondary', 'tertiary', 'quaternary'];
    const homeTeamColors = getHomeTeamColors();
    const awayTeamColors = getAwayTeamColors();
    let homeColor;
    let homeVariant;
    let awayColor;
    let awayVariant;
    let i;
    const len = colorVariants.length;
    let j;

    for (i = 0; i < len; i += 1) {
      homeVariant = colorVariants[i];
      homeColor = homeTeamColors[homeVariant];

      for (j = 0; j < len; j += 1) {
        awayVariant = colorVariants[j];
        awayColor = awayTeamColors[awayVariant];

        if (contrast(homeColor, awayColor)) {
          return { home: homeColor, away: awayColor };
        }
      }
    }
    // fallback colours
    return { home: '#000000', away: '#FFFFFF' };
  };

  return {
    id: getId(),
    season: getSeason(),
    seasonType: getSeasonType(),
    status: getStatus(),
    awayTeam: getAwayTeam(),
    awayTeamId: getAwayTeamId(),
    awayTeamScore: getAwayTeamScore(),
    homeTeam: getHomeTeam(),
    homeTeamId: getHomeTeamId(),
    homeTeamScore: getHomeTeamScore(),
    stadiumId: getStadiumId(),
    channel: getChannel(),
    homeTeamName: getHomeTeamName(),
    homeTeamLogo: getHomeTeamLogo(),
    awayTeamName: getAwayTeamName(),
    awayTeamLogo: getAwayTeamLogo(),
    shortDate: getShortDate(),
    fullDate: getFullDate(),
    time: getTime(),
    timeAndZone: getTimeAndZone(),
    homeTeamColors: getHomeTeamColors(),
    awayTeamColors: getAwayTeamColors(),
    gameTeamColors: getGameColors(),
    homeCity: getHomeTeamCity(),
    awayCity: getAwayTeamCity(),
    title: getTitle(),
    description: getDescription(),
    type: TYPE.GAME,
    videoUrl: getVideoUrl() || DUMMY_VIDEO_URL,
    league: LEAGUES.NBA
  };
};

export default Game;
