import fetch from '@accedo/vdkweb-fetch';
import dayjs from 'dayjs';
import { formatDate } from '#/utils/dateUtils';
import {
  DUMMY_VIDEO_URL,
  MLB_STREAM_URL,
  API_KEYS,
  BASE_URL,
  NBA_ENDPOINTS
} from '#/config/constants';
import shuffle from '#/utils/shuffleArr';
import {
  finalMockScores,
  dailyMockGames,
  upcomingMockGames
} from '#/providers/ovp/sports/nbaMockData';

import { playersData } from './nbaPlayersMockData';
import { merchandiseMockData } from './merchaniseMockData';
import { catchUpMockData } from './catchupMockData';
import { TeamsMockData } from './nbaTeamsMockData';
import { scheduleData } from './scheduleMockData';
import { liveMockData } from './liveMockData';

const { NODE_ENV } = process.env;

const isCurrentEnvDev = () => {
  return NODE_ENV === 'development';
};

const sortByDateReverse = data => {
  return data.sort((itemA, itemB) => {
    const itemADate = dayjs(itemA.Day);
    const itemBDate = dayjs(itemB.Day);

    return itemBDate - itemADate;
  });
};

const shuffleData = arr => {
  return arr.sort(() => 0.5 - Math.random());
};

const request = async endpoint => {
  const response = await fetch(endpoint);

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const result = await response.json();
  return result;
};

// NOTE: To compose NBA urls documented on:
// https://sportsdata.io/developers/api-documentation/nba
const composeNbaScoresUrl = (url, additionalParams) => {
  additionalParams = additionalParams ? `&${additionalParams}` : '';

  return `${BASE_URL.nbaScores + url}?key=${API_KEYS.NBA}${additionalParams}`;
};

const composeNbaStatsUrl = (url, additionalParams) => {
  additionalParams = additionalParams ? `&${additionalParams}` : '';

  return `${BASE_URL.nbaStats + url}?key=${API_KEYS.NBA}${additionalParams}`;
};

const getCurrentSeason = async () => {
  const url = NBA_ENDPOINTS.currentSeason;
  const endpoint = composeNbaScoresUrl(url);
  const res = await request(endpoint);

  return res;
};

// NOTE: To compose MLB urls documented on:
// https://developer.sportradar.com/io-docs

// IMPORTANT: To demo the player Accedo hosted
// a playlist with different streams and formats,
// for Fire TV we use the DASH one, this should be
// replaced by proper videos coming from an API or
// a more formal playlist in the future (in case of any)
const getDemoStream = async () => {
  // const url = URL.demoSportsStream;
  // const endpoint = url;
  // const res = await request(endpoint);

  // return res?.videos?.[0]?.content?.DASH;

  return DUMMY_VIDEO_URL;
};

// NOTE: NBA API request listing
const nbaAreGamesInProgress = async () => {
  const url = NBA_ENDPOINTS.gamesInProgress;
  const endpoint = composeNbaScoresUrl(url);
  const res = await request(endpoint);

  return res;
};

const getNbaAllTeams = async () => {
  if (isCurrentEnvDev()) {
    return TeamsMockData;
  }
  const url = NBA_ENDPOINTS.allTeams;
  const endpoint = composeNbaScoresUrl(url);
  const res = await request(endpoint);

  return TeamsMockData;
};

const getNbaActiveTeams = async () => {
  if (isCurrentEnvDev()) {
    return TeamsMockData;
  }
  const url = NBA_ENDPOINTS.activeTeams;
  const endpoint = composeNbaScoresUrl(url);
  const res = await request(endpoint);

  return res;
};

const getNbaTeamById = (teams, teamId) => {
  const team = teams.find(t => t.TeamID === teamId);

  return team;
};

const getNbaGamesBySeason = async (season, seasonType) => {
  season = seasonType ? `${season}${seasonType}` : season;

  const url = `${NBA_ENDPOINTS.schedules}/${season}`;
  const endpoint = composeNbaScoresUrl(url);
  const res = await request(endpoint);

  const allTeams = await getNbaAllTeams();

  const gamesWithTeams = res.map(game => {
    const homeTeam = getNbaTeamById(allTeams, game.HomeTeamID);
    const awayTeam = getNbaTeamById(allTeams, game.AwayTeamID);

    return {
      ...game,
      homeTeam,
      awayTeam
    };
  });

  return sortByDateReverse(gamesWithTeams).filter(
    game => game.Status !== 'NotNecessary'
  );
};

const getNbaGamesSchedule = async (params, date) => {
  let matches = [];
  let games = [];
  if (!isCurrentEnvDev()) {
    switch (params) {
      case '/recommended':
        matches = shuffleData(scheduleData).slice(0, 4);
        break;
      case '/all-games':
        matches = shuffleData(scheduleData).slice(0, 4);
        break;
      case '/completed':
        matches = shuffleData(scheduleData).slice(0, 3);
        break;
      default:
        break;
    }
  } else {
    if (date) {
      const url = `${NBA_ENDPOINTS.gamesByDate}/${date}`;
      const endpoint = composeNbaScoresUrl(url);
      games = await request(endpoint).catch(() => {
        console.warn('REQUEST CATCH - NO DATA FOR:', params);
      });
    }

    if (!games || games.length === 0) {
      const allGames = await getNbaGamesBySeason(
        new Date().getFullYear(),
        'POST'
      );

      switch (params) {
        case '/recommended':
          matches = allGames.slice(0, 4);
          break;
        case '/all-games':
          matches = allGames.slice(8, 15);
          break;
        case '/completed':
          matches = allGames
            .filter(game => game.Status === 'Final')
            .slice(0, 10);
          break;
        default:
          break;
      }
    } else if (params === '/recommended') {
      matches = games;
    }
  }

  return matches;
};

const getNbaGamesByDate = async (date, params) => {
  let games;
  let finalGames;
  let upcomingGames;
  let todayGames;

  if (isCurrentEnvDev()) {
    const param = params.toString();
    if (param === 'final') {
      games = finalMockScores;
    }
    if (param === 'upcoming') {
      games = upcomingMockGames;
    }

    if (param === 'today') {
      games = dailyMockGames;
    }
  } else {
    const url = `${NBA_ENDPOINTS.gamesByDate}/${date}`;
    const endpoint = composeNbaScoresUrl(url);
    // Added catch so failed attempts don't fail silently
    games = await request(endpoint).catch(() => {
      console.warn('REQUEST CATCH - NO DATA FOR:', params);
    });

    // IMPORTANT: Due to API capabilities we need to ensure that
    // the swimlanes that display games always have content for demo purposes,
    // because of some days with an empty game schedule
    if ((!games || games.length === 0) && params) {
      const { ApiSeason } = await getCurrentSeason();

      let response = await getNbaGamesBySeason(ApiSeason);
      if (!response || response.length === 0) {
        response = await getNbaGamesBySeason(new Date().getFullYear(), 'POST');

        finalGames = response
          .filter(game => game.Status === 'Final')
          .slice(0, 10);
        upcomingGames = response.slice(0, 10);
        todayGames = response.slice(15, 25);
      }

      const param = params.toString();

      if (param === 'final') {
        games = finalGames;
      }
      if (param === 'upcoming') {
        games = upcomingGames;
      }

      if (param === 'today') {
        games = todayGames;
      }
    }
  }

  return games;
};

const getNbaPlayersByTeam = async team => {
  const url = `${NBA_ENDPOINTS.playerByTeam}/${team}`;
  const endpoint = composeNbaScoresUrl(url);
  const res = await request(endpoint);

  return res;
};

// IMPORTANT: To demo a player spotlight
// swimlane the API doesn't provide the data
// so for demo purposes we are picking two
// teams randomly
const getNbaDemoPlayers = async () => {
  const players = playersData;

  return shuffle(players);
};

const getMerchandise = async () => {
  return shuffle(merchandiseMockData);
};

const getCatchupData = async () => {
  return shuffle(catchUpMockData);
};

const getVideoData = async () => {
  const allTeams = await getNbaAllTeams();

  const gamesWithTeams = liveMockData.map(game => {
    const homeTeam = getNbaTeamById(allTeams, game.homeTeamID);
    const awayTeam = getNbaTeamById(allTeams, game.awayTeamID);

    return {
      ...game,
      homeTeam,
      awayTeam
    };
  });

  return shuffle(gamesWithTeams);
};

const getNbaBoxScoreByGame = async gameId => {
  const url = `${NBA_ENDPOINTS.boxScore}/${gameId}`;
  const endpoint = composeNbaStatsUrl(url);
  const boxScore = await request(endpoint);

  const homeTeamPlayers = await getNbaPlayersByTeam(
    boxScore?.TeamGames?.[0]?.Team
  );
  const awayTeamPlayers = await getNbaPlayersByTeam(
    boxScore?.TeamGames?.[1]?.Team
  );

  const boxScoreWithPlayerData = boxScore?.PlayerGames?.map(stat => {
    const playerData =
      homeTeamPlayers?.find(p => p.PlayerID === stat.PlayerID) ||
      awayTeamPlayers?.find(p => p.PlayerID === stat.PlayerID);

    return {
      ...stat,
      photoUrl: playerData?.PhotoUrl
    };
  });

  return boxScoreWithPlayerData;
};

const getNbaReferees = async () => {
  const url = NBA_ENDPOINTS.referees;
  const endpoint = composeNbaScoresUrl(url);
  const res = await request(endpoint);

  return res;
};

const getMlbDailyBoxScore = async date => {
  const endpoint = `${BASE_URL.mlbApi}/games/${formatDate(
    date
  )}/boxscore.json?api_key=${API_KEYS.MLB}`;
  const res = await request(endpoint);

  const demoStream = MLB_STREAM_URL;

  const games = res?.league?.games?.map(game => {
    return {
      ...game,
      demoStream
    };
  });

  return games;
};

const getMlbGamesByDate = async date => {
  const endpoint = `${BASE_URL.mlbApi}/games/${formatDate(
    date
  )}/schedule.json?api_key=${API_KEYS.MLB}`;
  const res = await request(endpoint);

  const demoStream = MLB_STREAM_URL;

  const games = res?.games?.map(game => {
    return {
      ...game,
      demoStream
    };
  });

  return games;
};

const getMlbAllTeams = async () => {
  const endpoint = `${BASE_URL.mlbApi}/league/depth_charts.json?api_key=${API_KEYS.MLB}`;
  const res = await request(endpoint);

  return res?.teams;
};

const getMlbDemoPlayers = async () => {
  const top = 15;
  const players = [];
  // TODO: move this logic to the #/services/sports/mlb if there is further MLB development
  const allTeams = await getMlbAllTeams();

  // eslint-disable-next-line array-callback-return
  allTeams?.map(team => {
    if (team?.positions.length > 0) {
      // eslint-disable-next-line array-callback-return
      team?.positions.map(position => {
        // eslint-disable-next-line array-callback-return
        position?.players?.map(player => {
          players.push(player);
        });
      });
    }
  });

  return shuffle(players).slice(0, top);
};

const getMlbBoxScoreByGame = async gameId => {
  const endpoint = `${BASE_URL.mlbApi}/games/${gameId}/extended_summary.json?api_key=${API_KEYS.MLB}`;
  const res = await request(endpoint);

  const homePlayers = res?.game?.home?.players?.map(player => {
    return {
      ...player,
      teamId: res?.game?.home?.id
    };
  });

  const awayPlayers = res?.game?.away?.players?.map(player => {
    return {
      ...player,
      teamId: res?.game?.away?.id
    };
  });

  return [...homePlayers, ...awayPlayers];
};

export default {
  nbaAreGamesInProgress,
  getNbaGamesByDate,
  getNbaGamesSchedule,
  getNbaBoxScoreByGame,
  getNbaGamesBySeason,
  getNbaPlayersByTeam,
  getNbaReferees,
  getNbaActiveTeams,
  getNbaAllTeams,
  getNbaDemoPlayers,
  getMlbGamesByDate,
  getMlbDailyBoxScore,
  getMlbAllTeams,
  getMlbDemoPlayers,
  getMlbBoxScoreByGame,
  getDemoStream,
  getMerchandise,
  getVideoData,
  getCatchupData
};
