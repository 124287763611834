import { TYPE, LEAGUES } from '#/config/constants';

const Merchandise = merchData => {
  const { id, title, price, image, brand } = merchData;

  const getId = () => {
    return id?.toString();
  };

  const getTitle = () => {
    return title;
  };

  const getPrice = () => {
    return price;
  };

  const getBrand = () => {
    return brand;
  };

  const getImage = () => {
    return image;
  };

  return {
    id: getId(),
    title: getTitle(),
    price: getPrice(),
    brand: getBrand(),
    image: getImage(),
    type: TYPE.MERCHANDISE,
    league: LEAGUES.NBA
  };
};

export default Merchandise;
