import xdk from '@accedo/xdk-core';
import { ProfileType } from '#/interfaces/Profile';
import { GID_STORE_KEY } from '#/config/constants';
import isProfileKids from './isProfileKids';

/**
 * Returns the segmentation string value associated with the current profile
 * @param {Object} [currentProfile] current profile
 * @returns {string} the segmentation value ('kids' or undefined)
 */
const getSegmentationValue = currentProfile => {
  const storedGid = xdk?.storage?.get?.(GID_STORE_KEY);
  if (storedGid) {
    return storedGid;
  }
  return isProfileKids(currentProfile)
    ? ProfileType.KIDS.toLowerCase()
    : undefined;
};

/**
 * Returns the segmentation string associated with the passed Segmentation value, with an empty string if there's no segmentationValue
 * @param {string|undefined} segmentationValue current segment
 * @returns {string} the segmentation cache value
 */
const getSegmentationCachePart = segmentationValue =>
  segmentationValue ? `-${segmentationValue}` : '';

export { getSegmentationValue, getSegmentationCachePart };
