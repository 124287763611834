import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { throttle as _throttle } from 'lodash';
import { focusManager } from '@accedo/vdkweb-navigation';

import { navIdMap } from '#/utils/navigationHelper';
import { FocusNavigationArrow } from '#/components/NavigationArrow/NavigationArrow';
import vw from '#/utils/vw';
import { usePointer } from '#/context/PointerContext';

let animationRef = null;

export const DEFAULT_THROTTLE_MS = 250;
export const DEFAULT_TRANSITION_S = 0.3;
export const DEFAULT_TRANSITION = `ease-out all ${DEFAULT_TRANSITION_S}s`;

const useRunThrottled = throttleMs => {
  const { current: compRef } = useRef({});

  if (!compRef.runThrottled || compRef.runThrottledMs !== throttleMs) {
    compRef.runThrottled = _throttle(fn => fn(), throttleMs);
    compRef.runThrottledMs = throttleMs;
  }

  return compRef.runThrottled;
};

const DEFAULT_ARROW_HEIGHT = 120;

const VerticalScroll = ({
  children,
  navIds,
  onScroll = () => {},
  pageTopFunction,
  style,
  width = 1920,
  height = 1080,
  initial = 0,
  margin = 0,
  parent,
  returnParent,
  manualScrollUp,
  manualScrollDown,
  enableUpArrow,
  enableDownArrow,
  throttleMs = DEFAULT_THROTTLE_MS,
  transition = DEFAULT_TRANSITION,
  hideMenu,
  showMenu,
  showNavigationArrows = true,
  menuVisible = false
}) => {
  const ref = useRef({});
  const runThrottled = useRunThrottled(throttleMs);
  const { pointerEnabled } = usePointer();
  const [state, setState] = useState({
    position: initial,
    offset: 0,
    height: -1,
    index: 0,
    navId: navIds[0]
  });

  // ref to allow accessing value of latest prop inside effects without adding prop as dependency for effect
  const pageTopFunctionRef = useRef();
  pageTopFunctionRef.current = pageTopFunction;
  const pointerEnabledRef = useRef();
  pointerEnabledRef.current = pointerEnabled;

  const scrollToIndex = useCallback(
    index => {
      const componentId = navIds[index];
      const { position, offset, height: areaHeight, bottom } =
        pageTopFunctionRef.current(componentId) || {};
      if (!Number.isNaN(position) && typeof position === 'number') {
        setState({
          position,
          height: Number(areaHeight) || -1,
          bottom,
          offset: Number(offset) || 0,
          index,
          navId: componentId
        });
        // if (position >= 0 && menuVisible && showMenu) {
        //   showMenu();
        // } else if (position < 0 && hideMenu) {
        //   hideMenu();
        // }
      }
    },
    [navIds, showMenu, hideMenu, menuVisible]
  );

  const handleTrailBuilt = useCallback(() => {
    // scrolling is not automatically triggered if pointer is enabled to avoid unintended scroll when moving pointer
    if (!pointerEnabledRef.current) {
      const trail = focusManager.getTrail();

      if (trail?.length > 0 && navIds?.length > 2) {
        let itemIndex = -1;

        for (let i = 0; i < trail.length; i += 1) {
          const trailItem = trail[i];
          itemIndex = navIds.indexOf(trailItem);

          if (itemIndex !== -1) {
            scrollToIndex(itemIndex);
            break;
          }
        }
      }
    }
  }, [scrollToIndex, navIds]);

  const handleTrailBuiltRef = useRef();

  const recalculatePosition = useCallback(handleTrailBuilt, [handleTrailBuilt]);

  useEffect(() => {
    recalculatePosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navIds]);

  handleTrailBuiltRef.current = handleTrailBuilt;
  useEffect(() => {
    runThrottled(() => {
      cancelAnimationFrame(animationRef);
      animationRef = global.requestAnimationFrame(() => {
        if (!ref.current) {
          return;
        }
        const transform = `translateY(${state.position +
          state.offset +
          (state.position === 0 ? 0 : margin)}px)`;
        ref.current.style.transform = transform;
        ref.current.style.webkitTransform = transform;

        onScroll({ itemIndex: state.index, navId: state.navId });
      });
    });
  }, [state, margin, onScroll, runThrottled]);

  useEffect(() => {
    const listener = (...args) => handleTrailBuiltRef?.current?.(...args);

    const unlisten = focusManager.listenToTrailBuilt(listener);

    return unlisten;
  }, []);

  let upHeight = DEFAULT_ARROW_HEIGHT;
  let downHeight = DEFAULT_ARROW_HEIGHT;
  {
    const offset = state.offset + (state.position === 0 ? 0 : margin) || 0;
    upHeight = Math.max(upHeight, offset);
    if (state.height > 0) {
      downHeight = Math.max(downHeight, height - offset - state.height);
    }
  }

  // Some navId might have same position. This will scroll to next position
  const scrollToNext = useCallback(
    delta => {
      const { index: stateIndex } = state;
      let index = stateIndex;
      let position;
      let offset;
      do {
        if (navIds[index + delta]) {
          index += delta;
          const change = pageTopFunctionRef.current(navIds[index]);
          ({ position, offset } = change);
        } else {
          break;
        }
      } while (
        position + (offset || 0) ===
        state.position + (state.offset || 0)
      );
      scrollToIndex(index);
    },
    [navIds, scrollToIndex, state]
  );

  const scrollUp = useCallback(() => {
    if (!manualScrollUp || manualScrollUp(navIds[state.index]) !== true) {
      scrollToNext(-1);
    }
  }, [scrollToNext, manualScrollUp, navIds, state.index]);

  const scrollDown = useCallback(() => {
    if (!manualScrollDown || manualScrollDown(navIds[state.index]) !== true) {
      scrollToNext(1);
    }
  }, [scrollToNext, manualScrollDown, navIds, state.index]);

  const showDownArrow = () => {
    // Extra check to show/hide down arrow. Avoiding to show
    // down arrow when there is no capacity of scrolling down
    if (navIds[0] === navIdMap.MENU.HEADER.CONTAINER) {
      if (navIds.length > 2 && state.index < navIds.length - 1) {
        return true;
      }
      return false;
    }
    if (navIds.length > 1 && state.index < navIds.length - 1) {
      return true;
    }
  };

  return (
    <div
      style={{
        width: vw(width - 213),
        height: vw(height),
        position: 'relative'
      }}
    >
      <div
        style={{
          position: 'relative',
          transition,
          ...style
        }}
        ref={ref}
      >
        {children}
      </div>
      {showNavigationArrows && (
        <FocusNavigationArrow
          up
          enabled={
            enableUpArrow !== undefined ? enableUpArrow : state.position < 0
          }
          width={width}
          height={upHeight}
          onClick={scrollUp}
          returnParent={returnParent || parent}
          nav={{
            parent,
            id: `${parent}-up-arrow`
          }}
        />
      )}
      {showNavigationArrows && (
        <FocusNavigationArrow
          down
          enabled={
            enableDownArrow !== undefined
              ? enableDownArrow
              : state.bottom === false || (!!navIds && showDownArrow())
          }
          width={width}
          height={downHeight}
          onClick={scrollDown}
          returnParent={returnParent || parent}
          nav={{
            parent,
            id: `${parent}-down-arrow`
          }}
        />
      )}
    </div>
  );
};

VerticalScroll.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  initial: PropTypes.number,
  margin: PropTypes.number,
  parent: PropTypes.string.isRequired,
  returnParent: PropTypes.string,
  manualScrollUp: PropTypes.func,
  manualScrollDown: PropTypes.func,
  enableUpArrow: PropTypes.bool,
  enableDownArrow: PropTypes.bool,
  children: PropTypes.node,
  navIds: PropTypes.any,
  onScroll: PropTypes.func,
  pageTopFunction: PropTypes.func.isRequired,
  style: PropTypes.object,
  throttleMs: PropTypes.number,
  transition: PropTypes.any,
  hideMenu: PropTypes.func,
  showMenu: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  showNavigationArrows: PropTypes.bool,
  menuVisible: PropTypes.bool
};

export default VerticalScroll;
