// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
const MlbBoxScore = rawPlayerGame => {
  const { id, first_name, last_name, teamId, statistics } = rawPlayerGame;

  const getId = () => {
    return id;
  };

  const getName = () => {
    return `${first_name} ${last_name}`;
  };

  const getAtBat = () => {
    if (Number.isInteger(statistics?.hitting?.overall?.ab)) {
      return statistics?.hitting?.overall?.ab || 0;
    }

    return statistics?.hitting?.overall?.ab.toFixed(2) || 0;
  };

  const getRuns = () => {
    if (Number.isInteger(statistics?.hitting?.overall?.runs?.total)) {
      return statistics?.hitting?.overall?.runs?.total || 0;
    }

    return statistics?.hitting?.overall?.runs?.total.toFixed(2) || 0;
  };

  const getHits = () => {
    if (Number.isInteger(statistics?.hitting?.overall?.hit_risp)) {
      return statistics?.hitting?.overall?.hit_risp || 0;
    }

    return statistics?.hitting?.overall?.hit_risp.toFixed(2) || 0;
  };

  const getRunsBattedIn = () => {
    if (Number.isInteger(statistics?.hitting?.overall?.rbi)) {
      return statistics?.hitting?.overall?.rbi || 0;
    }

    return statistics?.hitting?.overall?.rbi.toFixed(2) || 0;
  };

  const getWalk = () => {
    if (Number.isInteger(statistics?.hitting?.overall?.bbpa)) {
      return statistics?.hitting?.overall?.bbpa || 0;
    }

    return statistics?.hitting?.overall?.bbpa.toFixed(2) || 0;
  };

  const getStrikes = () => {
    if (Number.isInteger(statistics?.hitting?.overall?.pitches?.ktotal)) {
      return statistics?.hitting?.overall?.pitches?.ktotal || 0;
    }

    return statistics?.hitting?.overall?.pitches?.ktotal.toFixed(2) || 0;
  };

  const getTeamId = () => {
    return teamId;
  };

  return {
    player: getName(),
    id: getId(),
    teamId: getTeamId(),
    stats: [
      {
        name: 'AB',
        value: getAtBat()
      },
      {
        name: 'R',
        value: getRuns()
      },
      {
        name: 'H',
        value: getHits()
      },
      {
        name: 'RBI',
        value: getRunsBattedIn()
      },
      {
        name: 'BB',
        value: getWalk()
      },
      {
        name: 'K',
        value: getStrikes()
      }
    ]
  };
};

export default MlbBoxScore;
