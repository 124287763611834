import liveImg1 from '#/static/images/live/live-1.png';
import liveImg2 from '#/static/images/live/live-2.png';
import liveImg3 from '#/static/images/live/live-3.png';
import liveImg4 from '#/static/images/live/live-4.png';
import liveImg5 from '#/static/images/live/live-5.png';

export const liveMockData = [
  {
    id: 1,
    title: 'Blazers vs Heat',
    description:
      'Blazers take on the Heat in an epic battle between two of the leagues top teams. You won’t want to miss this one!',
    date: 'Sep 22',
    time: '6:00 - 8:00 PM',
    image: liveImg1,
    url: '//d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4',
    homeTeamID: 19,
    awayTeamID: 4,
    homeScore: 54,
    awayScore: 33
  },
  {
    id: 2,
    title: 'Wizards vs Celtics',
    description:
      'Wizards take on the Celtics in an epic battle between two of the leagues top teams. You won’t want to miss this one!',
    date: 'Jun 15',
    time: '3:00 - 5:00 PM',
    image: liveImg2,
    url: '//d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4',
    homeTeamID: 1,
    awayTeamID: 9,
    homeScore: 49,
    awayScore: 51
  },
  {
    id: 3,
    title: 'Lakers vs Kings',
    description:
      'Lakers take on the Kings in an epic battle between two of the leagues top teams. You won’t want to miss this one!',
    date: 'Oct 17',
    time: '5:00 - 7:00 PM',
    image: liveImg3,
    url: '//d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4',
    homeTeamID: 27,
    awayTeamID: 30,
    homeScore: 84,
    awayScore: 73
  },
  {
    id: 4,
    title: 'Bulls vs Raptors',
    description:
      'Bulls take on the Raptors in an epic battle between two of the leagues top teams. You won’t want to miss this one!',
    date: 'Dec 01',
    time: '8:00 - 10:00 PM',
    image: liveImg4,
    url: '//d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4',
    homeTeamID: 11,
    awayTeamID: 10,
    homeScore: 54,
    awayScore: 56
  },
  {
    id: 5,
    title: 'Clippers vs Knicks',
    description:
      'Clippers Bulls take on the Knicks in an epic battle between two of the leagues top teams. You won’t want to miss this one!',
    date: 'Jan 07',
    time: '2:00 - 4:00 PM',
    image: liveImg5,
    url: '//d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4',
    homeTeamID: 28,
    awayTeamID: 6,
    homeScore: 66,
    awayScore: 65
  },
  {
    id: 1,
    title: 'Hornets vs Magic',
    description:
      'Hornets take on the Magic in an epic battle between two of the leagues top teams. You won’t want to miss this one!',
    date: 'Sep 22',
    time: '6:00 - 8:00 PM',
    image: liveImg1,
    url: '//d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4',
    homeTeamID: 2,
    awayTeamID: 5,
    homeScore: 44,
    awayScore: 30
  }
];
