import React, { useState, memo } from 'react';
import Ellipsis from 'react-ellipsis-pjs';
import { withFocus } from '@accedo/vdkweb-navigation';
import Nav from '#/interfaces/Nav';
import style from './seasonEpisode.scss';
import ImageWithPreload from '../../ImageWithPreload/ImageWithPreload';

const WIDTH_TO_SUBTRACT = 37;
const HEIGHT_TO_SUBTRACT = 76;

interface SeasonEpisodeItem {
  data: any; // "any" until we review the data structure.
  nav: Nav;
  onClick: () => any | void;
  width: number | string;
  height: number | string;
}

const SeasonEpisode = ({
  onClick,
  data,
  nav,
  width: widthProp,
  height: heightProp
}: SeasonEpisodeItem) => {
  const [width] = useState(widthProp);
  const [height] = useState(heightProp);

  const { progress, title, coverUrl } = data;

  const imgContainerSize = {
    ...(width ? { width: +width - WIDTH_TO_SUBTRACT } : {}),
    ...(height ? { height: +height - HEIGHT_TO_SUBTRACT } : {})
  };

  const containerImageClass = nav.isFocused
    ? `${style.containerImage} ${style.containerImageFocused}`
    : style.containerImage;

  return (
    <div style={{ width, height }}>
      <div
        className={style.episode}
        onClick={onClick}
        onMouseEnter={nav.onMouseOver}
        onMouseLeave={nav.onMouseOut}
        role="button"
      >
        <div className={style.title}>
          <Ellipsis lines={1} text={title} />
        </div>
        <div className={containerImageClass} style={imgContainerSize}>
          <ImageWithPreload
            className={style.coverImage}
            src={coverUrl}
            alt={title}
          />

          {progress > 0 && (
            <div className={style.progress}>
              <span style={{ width: `${progress}%` }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const propsAreEqual = (
  prevProps: SeasonEpisodeItem,
  nextProps: SeasonEpisodeItem
) => {
  const { data: prevData, nav: prevNav } = prevProps || {};
  const { data: nextData, nav: nextNav } = nextProps || {};

  return (
    prevData.displayText === nextData.displayText &&
    prevData.viewAll === nextData.viewAll &&
    prevNav.isFocused === nextNav.isFocused &&
    prevNav.nextleft === nextNav.nextleft &&
    prevNav.nextright === nextNav.nextright
  );
};

export default memo(withFocus(SeasonEpisode), propsAreEqual);
