export const finalMockScores = [
  {
    GameID: 19397,
    Season: 2023,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-04-09T00:00:00',
    DateTime: '2023-04-09T15:30:00',
    AwayTeam: 'LAC',
    HomeTeam: 'PHO',
    AwayTeamID: 28,
    HomeTeamID: 29,
    StadiumID: 28,
    Channel: 'BSAZ',
    Attendance: 17071,
    AwayTeamScore: 119,
    HomeTeamScore: 114,
    Updated: '2023-04-09T18:18:26',
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: 9.5,
    OverUnder: 224.5,
    AwayTeamMoneyLine: -485,
    HomeTeamMoneyLine: 368,
    GlobalGameID: 20019397,
    GlobalAwayTeamID: 20000028,
    GlobalHomeTeamID: 20000029,
    PointSpreadAwayTeamMoneyLine: -113,
    PointSpreadHomeTeamMoneyLine: -109,
    LastPlay: null,
    IsClosed: true,
    GameEndDateTime: '2023-04-09T17:57:18',
    HomeRotationNumber: 550,
    AwayRotationNumber: 549,
    NeutralVenue: false,
    OverPayout: -113,
    UnderPayout: -109,
    CrewChiefID: 20000053,
    UmpireID: 20000025,
    RefereeID: 20000041,
    AlternateID: null,
    DateTimeUTC: '2023-04-09T19:30:00',
    SeriesInfo: null,
    Quarters: [
      {
        QuarterID: 162632,
        GameID: 19397,
        Number: 1,
        Name: '1',
        AwayScore: 19,
        HomeScore: 28
      },
      {
        QuarterID: 162634,
        GameID: 19397,
        Number: 2,
        Name: '2',
        AwayScore: 29,
        HomeScore: 25
      },
      {
        QuarterID: 162635,
        GameID: 19397,
        Number: 3,
        Name: '3',
        AwayScore: 34,
        HomeScore: 33
      },
      {
        QuarterID: 162636,
        GameID: 19397,
        Number: 4,
        Name: '4',
        AwayScore: 37,
        HomeScore: 28
      }
    ]
  },
  {
    GameID: 19398,
    Season: 2023,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-04-09T00:00:00',
    DateTime: '2023-04-09T15:30:00',
    AwayTeam: 'GS',
    HomeTeam: 'POR',
    AwayTeamID: 26,
    HomeTeamID: 19,
    StadiumID: 19,
    Channel: 'NBCS',
    Attendance: 19731,
    AwayTeamScore: 157,
    HomeTeamScore: 101,
    Updated: '2023-04-09T21:15:46',
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: 17,
    OverUnder: 233.5,
    AwayTeamMoneyLine: -1878,
    HomeTeamMoneyLine: 957,
    GlobalGameID: 20019398,
    GlobalAwayTeamID: 20000026,
    GlobalHomeTeamID: 20000019,
    PointSpreadAwayTeamMoneyLine: -109,
    PointSpreadHomeTeamMoneyLine: -112,
    LastPlay: null,
    IsClosed: true,
    GameEndDateTime: '2023-04-09T17:39:21',
    HomeRotationNumber: 554,
    AwayRotationNumber: 553,
    NeutralVenue: false,
    OverPayout: -113,
    UnderPayout: -108,
    CrewChiefID: 20000054,
    UmpireID: 20000049,
    RefereeID: 20000058,
    AlternateID: null,
    DateTimeUTC: '2023-04-09T19:30:00',
    SeriesInfo: null,
    Quarters: [
      {
        QuarterID: 162631,
        GameID: 19398,
        Number: 1,
        Name: '1',
        AwayScore: 55,
        HomeScore: 27
      },
      {
        QuarterID: 162640,
        GameID: 19398,
        Number: 2,
        Name: '2',
        AwayScore: 29,
        HomeScore: 26
      },
      {
        QuarterID: 162641,
        GameID: 19398,
        Number: 3,
        Name: '3',
        AwayScore: 34,
        HomeScore: 20
      },
      {
        QuarterID: 162642,
        GameID: 19398,
        Number: 4,
        Name: '4',
        AwayScore: 39,
        HomeScore: 28
      }
    ]
  },
  {
    GameID: 19387,
    Season: 2023,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-04-09T00:00:00',
    DateTime: '2023-04-09T13:00:00',
    AwayTeam: 'CHA',
    HomeTeam: 'CLE',
    AwayTeamID: 2,
    HomeTeamID: 12,
    StadiumID: 12,
    Channel: 'BSOH',
    Attendance: 19432,
    AwayTeamScore: 106,
    HomeTeamScore: 95,
    Updated: '2023-04-09T21:39:56',
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -6.5,
    OverUnder: 215,
    AwayTeamMoneyLine: 210,
    HomeTeamMoneyLine: -259,
    GlobalGameID: 20019387,
    GlobalAwayTeamID: 20000002,
    GlobalHomeTeamID: 20000012,
    PointSpreadAwayTeamMoneyLine: -110,
    PointSpreadHomeTeamMoneyLine: -110,
    LastPlay: null,
    IsClosed: true,
    GameEndDateTime: '2023-04-09T15:13:29',
    HomeRotationNumber: 532,
    AwayRotationNumber: 531,
    NeutralVenue: false,
    OverPayout: -109,
    UnderPayout: -112,
    CrewChiefID: 20000030,
    UmpireID: 20000040,
    RefereeID: 20000016,
    AlternateID: null,
    DateTimeUTC: '2023-04-09T17:00:00',
    SeriesInfo: null,
    Quarters: [
      {
        QuarterID: 162599,
        GameID: 19387,
        Number: 1,
        Name: '1',
        AwayScore: 17,
        HomeScore: 25
      },
      {
        QuarterID: 162600,
        GameID: 19387,
        Number: 2,
        Name: '2',
        AwayScore: 32,
        HomeScore: 29
      },
      {
        QuarterID: 162601,
        GameID: 19387,
        Number: 3,
        Name: '3',
        AwayScore: 21,
        HomeScore: 21
      },
      {
        QuarterID: 162602,
        GameID: 19387,
        Number: 4,
        Name: '4',
        AwayScore: 36,
        HomeScore: 20
      }
    ]
  },
  {
    GameID: 19390,
    Season: 2023,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-04-09T00:00:00',
    DateTime: '2023-04-09T13:00:00',
    AwayTeam: 'IND',
    HomeTeam: 'NY',
    AwayTeamID: 13,
    HomeTeamID: 6,
    StadiumID: 6,
    Channel: 'MSG',
    Attendance: 19812,
    AwayTeamScore: 141,
    HomeTeamScore: 136,
    Updated: '2023-04-09T16:09:56',
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -10,
    OverUnder: 231,
    AwayTeamMoneyLine: 357,
    HomeTeamMoneyLine: -465,
    GlobalGameID: 20019390,
    GlobalAwayTeamID: 20000013,
    GlobalHomeTeamID: 20000006,
    PointSpreadAwayTeamMoneyLine: -110,
    PointSpreadHomeTeamMoneyLine: -110,
    LastPlay: null,
    IsClosed: true,
    GameEndDateTime: '2023-04-09T15:42:20',
    HomeRotationNumber: 544,
    AwayRotationNumber: 543,
    NeutralVenue: false,
    OverPayout: -109,
    UnderPayout: -112,
    CrewChiefID: 20000055,
    UmpireID: 20000078,
    RefereeID: 20000056,
    AlternateID: null,
    DateTimeUTC: '2023-04-09T17:00:00',
    SeriesInfo: null,
    Quarters: [
      {
        QuarterID: 162611,
        GameID: 19390,
        Number: 1,
        Name: '1',
        AwayScore: 41,
        HomeScore: 45
      },
      {
        QuarterID: 162613,
        GameID: 19390,
        Number: 2,
        Name: '2',
        AwayScore: 32,
        HomeScore: 26
      },
      {
        QuarterID: 162614,
        GameID: 19390,
        Number: 3,
        Name: '3',
        AwayScore: 27,
        HomeScore: 36
      },
      {
        QuarterID: 162615,
        GameID: 19390,
        Number: 4,
        Name: '4',
        AwayScore: 41,
        HomeScore: 29
      }
    ]
  },
  {
    GameID: 19391,
    Season: 2023,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-04-09T00:00:00',
    DateTime: '2023-04-09T13:00:00',
    AwayTeam: 'HOU',
    HomeTeam: 'WAS',
    AwayTeamID: 22,
    HomeTeamID: 1,
    StadiumID: 1,
    Channel: 'NBC',
    Attendance: 15233,
    AwayTeamScore: 114,
    HomeTeamScore: 109,
    Updated: '2023-04-09T22:03:35',
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: 4,
    OverUnder: 230.5,
    AwayTeamMoneyLine: -176,
    HomeTeamMoneyLine: 146,
    GlobalGameID: 20019391,
    GlobalAwayTeamID: 20000022,
    GlobalHomeTeamID: 20000001,
    PointSpreadAwayTeamMoneyLine: -110,
    PointSpreadHomeTeamMoneyLine: -110,
    LastPlay: null,
    IsClosed: true,
    GameEndDateTime: '2023-04-09T15:19:34',
    HomeRotationNumber: 534,
    AwayRotationNumber: 533,
    NeutralVenue: false,
    OverPayout: -110,
    UnderPayout: -112,
    CrewChiefID: 20000038,
    UmpireID: 20000039,
    RefereeID: 20000011,
    AlternateID: null,
    DateTimeUTC: '2023-04-09T17:00:00',
    SeriesInfo: null,
    Quarters: [
      {
        QuarterID: 162619,
        GameID: 19391,
        Number: 1,
        Name: '1',
        AwayScore: 31,
        HomeScore: 39
      },
      {
        QuarterID: 162620,
        GameID: 19391,
        Number: 2,
        Name: '2',
        AwayScore: 24,
        HomeScore: 28
      },
      {
        QuarterID: 162621,
        GameID: 19391,
        Number: 3,
        Name: '3',
        AwayScore: 32,
        HomeScore: 18
      },
      {
        QuarterID: 162622,
        GameID: 19391,
        Number: 4,
        Name: '4',
        AwayScore: 27,
        HomeScore: 24
      }
    ]
  },
  {
    GameID: 19392,
    Season: 2023,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-04-09T00:00:00',
    DateTime: '2023-04-09T13:00:00',
    AwayTeam: 'MIL',
    HomeTeam: 'TOR',
    AwayTeamID: 15,
    HomeTeamID: 10,
    StadiumID: 10,
    Channel: 'BSW',
    Attendance: 19800,
    AwayTeamScore: 105,
    HomeTeamScore: 121,
    Updated: '2023-04-09T22:08:47',
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -3.5,
    OverUnder: 221,
    AwayTeamMoneyLine: 135,
    HomeTeamMoneyLine: -162,
    GlobalGameID: 20019392,
    GlobalAwayTeamID: 20000015,
    GlobalHomeTeamID: 20000010,
    PointSpreadAwayTeamMoneyLine: -110,
    PointSpreadHomeTeamMoneyLine: -112,
    LastPlay: null,
    IsClosed: true,
    GameEndDateTime: '2023-04-09T15:09:44',
    HomeRotationNumber: 530,
    AwayRotationNumber: 529,
    NeutralVenue: false,
    OverPayout: -110,
    UnderPayout: -112,
    CrewChiefID: 20000008,
    UmpireID: 20000047,
    RefereeID: 20000064,
    AlternateID: null,
    DateTimeUTC: '2023-04-09T17:00:00',
    SeriesInfo: null,
    Quarters: [
      {
        QuarterID: 162603,
        GameID: 19392,
        Number: 1,
        Name: '1',
        AwayScore: 26,
        HomeScore: 35
      },
      {
        QuarterID: 162604,
        GameID: 19392,
        Number: 2,
        Name: '2',
        AwayScore: 29,
        HomeScore: 32
      },
      {
        QuarterID: 162605,
        GameID: 19392,
        Number: 3,
        Name: '3',
        AwayScore: 24,
        HomeScore: 28
      },
      {
        QuarterID: 162606,
        GameID: 19392,
        Number: 4,
        Name: '4',
        AwayScore: 26,
        HomeScore: 26
      }
    ]
  },
  {
    GameID: 19393,
    Season: 2023,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-04-09T00:00:00',
    DateTime: '2023-04-09T15:30:00',
    AwayTeam: 'SA',
    HomeTeam: 'DAL',
    AwayTeamID: 24,
    HomeTeamID: 25,
    StadiumID: 25,
    Channel: 'BSSW',
    Attendance: 20141,
    AwayTeamScore: 138,
    HomeTeamScore: 117,
    Updated: '2023-04-09T21:06:37',
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -4,
    OverUnder: 232,
    AwayTeamMoneyLine: 142,
    HomeTeamMoneyLine: -173,
    GlobalGameID: 20019393,
    GlobalAwayTeamID: 20000024,
    GlobalHomeTeamID: 20000025,
    PointSpreadAwayTeamMoneyLine: -114,
    PointSpreadHomeTeamMoneyLine: -107,
    LastPlay: null,
    IsClosed: true,
    GameEndDateTime: '2023-04-09T17:36:46',
    HomeRotationNumber: 548,
    AwayRotationNumber: 547,
    NeutralVenue: false,
    OverPayout: -109,
    UnderPayout: -112,
    CrewChiefID: 20000012,
    UmpireID: 20000010,
    RefereeID: 20000028,
    AlternateID: null,
    DateTimeUTC: '2023-04-09T19:30:00',
    SeriesInfo: null,
    Quarters: [
      {
        QuarterID: 162638,
        GameID: 19393,
        Number: 1,
        Name: '1',
        AwayScore: 42,
        HomeScore: 14
      },
      {
        QuarterID: 162644,
        GameID: 19393,
        Number: 2,
        Name: '2',
        AwayScore: 29,
        HomeScore: 45
      },
      {
        QuarterID: 162645,
        GameID: 19393,
        Number: 3,
        Name: '3',
        AwayScore: 37,
        HomeScore: 32
      },
      {
        QuarterID: 162646,
        GameID: 19393,
        Number: 4,
        Name: '4',
        AwayScore: 30,
        HomeScore: 26
      }
    ]
  },
  {
    GameID: 19394,
    Season: 2023,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-04-09T00:00:00',
    DateTime: '2023-04-09T15:30:00',
    AwayTeam: 'SAC',
    HomeTeam: 'DEN',
    AwayTeamID: 30,
    HomeTeamID: 20,
    StadiumID: 20,
    Channel: 'NBCS',
    Attendance: 19652,
    AwayTeamScore: 95,
    HomeTeamScore: 109,
    Updated: '2023-04-09T21:09:14',
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: 5,
    OverUnder: 230,
    AwayTeamMoneyLine: -206,
    HomeTeamMoneyLine: 172,
    GlobalGameID: 20019394,
    GlobalAwayTeamID: 20000030,
    GlobalHomeTeamID: 20000020,
    PointSpreadAwayTeamMoneyLine: -114,
    PointSpreadHomeTeamMoneyLine: -108,
    LastPlay: null,
    IsClosed: true,
    GameEndDateTime: '2023-04-09T17:44:46',
    HomeRotationNumber: 546,
    AwayRotationNumber: 545,
    NeutralVenue: false,
    OverPayout: -110,
    UnderPayout: -112,
    CrewChiefID: 20000044,
    UmpireID: 20000077,
    RefereeID: 20000019,
    AlternateID: null,
    DateTimeUTC: '2023-04-09T19:30:00',
    SeriesInfo: null,
    Quarters: [
      {
        QuarterID: 162643,
        GameID: 19394,
        Number: 1,
        Name: '1',
        AwayScore: 43,
        HomeScore: 26
      },
      {
        QuarterID: 162653,
        GameID: 19394,
        Number: 2,
        Name: '2',
        AwayScore: 24,
        HomeScore: 33
      },
      {
        QuarterID: 162654,
        GameID: 19394,
        Number: 3,
        Name: '3',
        AwayScore: 14,
        HomeScore: 24
      },
      {
        QuarterID: 162655,
        GameID: 19394,
        Number: 4,
        Name: '4',
        AwayScore: 14,
        HomeScore: 26
      }
    ]
  },
  {
    GameID: 19388,
    Season: 2023,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-04-09T00:00:00',
    DateTime: '2023-04-09T13:00:00',
    AwayTeam: 'ORL',
    HomeTeam: 'MIA',
    AwayTeamID: 5,
    HomeTeamID: 4,
    StadiumID: 4,
    Channel: 'BSSU',
    Attendance: 19894,
    AwayTeamScore: 110,
    HomeTeamScore: 123,
    Updated: '2023-04-09T15:51:33',
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -5,
    OverUnder: 217.5,
    AwayTeamMoneyLine: 173,
    HomeTeamMoneyLine: -208,
    GlobalGameID: 20019388,
    GlobalAwayTeamID: 20000005,
    GlobalHomeTeamID: 20000004,
    PointSpreadAwayTeamMoneyLine: -109,
    PointSpreadHomeTeamMoneyLine: -113,
    LastPlay: null,
    IsClosed: true,
    GameEndDateTime: '2023-04-09T15:29:15',
    HomeRotationNumber: 538,
    AwayRotationNumber: 537,
    NeutralVenue: false,
    OverPayout: -110,
    UnderPayout: -110,
    CrewChiefID: 20000042,
    UmpireID: 20000027,
    RefereeID: 20000046,
    AlternateID: null,
    DateTimeUTC: '2023-04-09T17:00:00',
    SeriesInfo: null,
    Quarters: [
      {
        QuarterID: 162627,
        GameID: 19388,
        Number: 1,
        Name: '1',
        AwayScore: 24,
        HomeScore: 23
      },
      {
        QuarterID: 162628,
        GameID: 19388,
        Number: 2,
        Name: '2',
        AwayScore: 26,
        HomeScore: 29
      },
      {
        QuarterID: 162629,
        GameID: 19388,
        Number: 3,
        Name: '3',
        AwayScore: 35,
        HomeScore: 32
      },
      {
        QuarterID: 162630,
        GameID: 19388,
        Number: 4,
        Name: '4',
        AwayScore: 25,
        HomeScore: 39
      }
    ]
  },
  {
    GameID: 19385,
    Season: 2023,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-04-09T00:00:00',
    DateTime: '2023-04-09T13:00:00',
    AwayTeam: 'ATL',
    HomeTeam: 'BOS',
    AwayTeamID: 3,
    HomeTeamID: 9,
    StadiumID: 9,
    Channel: 'ESPN',
    Attendance: 19156,
    AwayTeamScore: 114,
    HomeTeamScore: 120,
    Updated: '2023-04-09T21:33:09',
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -4.5,
    OverUnder: 224.5,
    AwayTeamMoneyLine: 160,
    HomeTeamMoneyLine: -193,
    GlobalGameID: 20019385,
    GlobalAwayTeamID: 20000003,
    GlobalHomeTeamID: 20000009,
    PointSpreadAwayTeamMoneyLine: -110,
    PointSpreadHomeTeamMoneyLine: -112,
    LastPlay: null,
    IsClosed: true,
    GameEndDateTime: '2023-04-09T15:16:31',
    HomeRotationNumber: 536,
    AwayRotationNumber: 535,
    NeutralVenue: false,
    OverPayout: -112,
    UnderPayout: -109,
    CrewChiefID: 20000013,
    UmpireID: 20000033,
    RefereeID: 20000020,
    AlternateID: null,
    DateTimeUTC: '2023-04-09T17:00:00',
    SeriesInfo: null,
    Quarters: [
      {
        QuarterID: 162623,
        GameID: 19385,
        Number: 1,
        Name: '1',
        AwayScore: 31,
        HomeScore: 29
      },
      {
        QuarterID: 162624,
        GameID: 19385,
        Number: 2,
        Name: '2',
        AwayScore: 33,
        HomeScore: 32
      },
      {
        QuarterID: 162625,
        GameID: 19385,
        Number: 3,
        Name: '3',
        AwayScore: 26,
        HomeScore: 39
      },
      {
        QuarterID: 162626,
        GameID: 19385,
        Number: 4,
        Name: '4',
        AwayScore: 24,
        HomeScore: 20
      }
    ]
  },
  {
    GameID: 19386,
    Season: 2023,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-04-09T00:00:00',
    DateTime: '2023-04-09T13:00:00',
    AwayTeam: 'DET',
    HomeTeam: 'CHI',
    AwayTeamID: 14,
    HomeTeamID: 11,
    StadiumID: 11,
    Channel: 'NBCS',
    Attendance: 21530,
    AwayTeamScore: 81,
    HomeTeamScore: 103,
    Updated: '2023-04-09T21:35:46',
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -8.5,
    OverUnder: 220,
    AwayTeamMoneyLine: 289,
    HomeTeamMoneyLine: -366,
    GlobalGameID: 20019386,
    GlobalAwayTeamID: 20000014,
    GlobalHomeTeamID: 20000011,
    PointSpreadAwayTeamMoneyLine: -110,
    PointSpreadHomeTeamMoneyLine: -110,
    LastPlay: null,
    IsClosed: true,
    GameEndDateTime: '2023-04-09T15:17:35',
    HomeRotationNumber: 540,
    AwayRotationNumber: 539,
    NeutralVenue: false,
    OverPayout: -110,
    UnderPayout: -110,
    CrewChiefID: 20000006,
    UmpireID: 20000036,
    RefereeID: 20000034,
    AlternateID: null,
    DateTimeUTC: '2023-04-09T17:00:00',
    SeriesInfo: null,
    Quarters: [
      {
        QuarterID: 162612,
        GameID: 19386,
        Number: 1,
        Name: '1',
        AwayScore: 25,
        HomeScore: 26
      },
      {
        QuarterID: 162616,
        GameID: 19386,
        Number: 2,
        Name: '2',
        AwayScore: 21,
        HomeScore: 23
      },
      {
        QuarterID: 162617,
        GameID: 19386,
        Number: 3,
        Name: '3',
        AwayScore: 18,
        HomeScore: 18
      },
      {
        QuarterID: 162618,
        GameID: 19386,
        Number: 4,
        Name: '4',
        AwayScore: 17,
        HomeScore: 36
      }
    ]
  },
  {
    GameID: 19395,
    Season: 2023,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-04-09T00:00:00',
    DateTime: '2023-04-09T15:30:00',
    AwayTeam: 'UTA',
    HomeTeam: 'LAL',
    AwayTeamID: 17,
    HomeTeamID: 27,
    StadiumID: 27,
    Channel: 'ESPN',
    Attendance: 18997,
    AwayTeamScore: 117,
    HomeTeamScore: 128,
    Updated: '2023-04-09T17:59:20',
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -16.5,
    OverUnder: 235,
    AwayTeamMoneyLine: 947,
    HomeTeamMoneyLine: -1833,
    GlobalGameID: 20019395,
    GlobalAwayTeamID: 20000017,
    GlobalHomeTeamID: 20000027,
    PointSpreadAwayTeamMoneyLine: -111,
    PointSpreadHomeTeamMoneyLine: -110,
    LastPlay: null,
    IsClosed: true,
    GameEndDateTime: '2023-04-09T17:43:57',
    HomeRotationNumber: 556,
    AwayRotationNumber: 555,
    NeutralVenue: false,
    OverPayout: -109,
    UnderPayout: -112,
    CrewChiefID: 20000051,
    UmpireID: 20000057,
    RefereeID: 20000048,
    AlternateID: null,
    DateTimeUTC: '2023-04-09T19:30:00',
    SeriesInfo: null,
    Quarters: [
      {
        QuarterID: 162637,
        GameID: 19395,
        Number: 1,
        Name: '1',
        AwayScore: 25,
        HomeScore: 31
      },
      {
        QuarterID: 162647,
        GameID: 19395,
        Number: 2,
        Name: '2',
        AwayScore: 31,
        HomeScore: 34
      },
      {
        QuarterID: 162648,
        GameID: 19395,
        Number: 3,
        Name: '3',
        AwayScore: 39,
        HomeScore: 34
      },
      {
        QuarterID: 162649,
        GameID: 19395,
        Number: 4,
        Name: '4',
        AwayScore: 22,
        HomeScore: 29
      }
    ]
  },
  {
    GameID: 19396,
    Season: 2023,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-04-09T00:00:00',
    DateTime: '2023-04-09T15:30:00',
    AwayTeam: 'NO',
    HomeTeam: 'MIN',
    AwayTeamID: 23,
    HomeTeamID: 16,
    StadiumID: 16,
    Channel: 'BSN',
    Attendance: 18978,
    AwayTeamScore: 108,
    HomeTeamScore: 113,
    Updated: '2023-04-09T18:38:04',
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -2.5,
    OverUnder: 226,
    AwayTeamMoneyLine: 127,
    HomeTeamMoneyLine: -153,
    GlobalGameID: 20019396,
    GlobalAwayTeamID: 20000023,
    GlobalHomeTeamID: 20000016,
    PointSpreadAwayTeamMoneyLine: -107,
    PointSpreadHomeTeamMoneyLine: -114,
    LastPlay: null,
    IsClosed: true,
    GameEndDateTime: '2023-04-09T18:09:42',
    HomeRotationNumber: 558,
    AwayRotationNumber: 557,
    NeutralVenue: false,
    OverPayout: -110,
    UnderPayout: -110,
    CrewChiefID: 20000022,
    UmpireID: 20000031,
    RefereeID: 20000067,
    AlternateID: null,
    DateTimeUTC: '2023-04-09T19:30:00',
    SeriesInfo: null,
    Quarters: [
      {
        QuarterID: 162639,
        GameID: 19396,
        Number: 1,
        Name: '1',
        AwayScore: 30,
        HomeScore: 18
      },
      {
        QuarterID: 162650,
        GameID: 19396,
        Number: 2,
        Name: '2',
        AwayScore: 25,
        HomeScore: 29
      },
      {
        QuarterID: 162651,
        GameID: 19396,
        Number: 3,
        Name: '3',
        AwayScore: 30,
        HomeScore: 36
      },
      {
        QuarterID: 162652,
        GameID: 19396,
        Number: 4,
        Name: '4',
        AwayScore: 23,
        HomeScore: 30
      }
    ]
  },
  {
    GameID: 19399,
    Season: 2023,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-04-09T00:00:00',
    DateTime: '2023-04-09T15:30:00',
    AwayTeam: 'MEM',
    HomeTeam: 'OKC',
    AwayTeamID: 21,
    HomeTeamID: 18,
    StadiumID: 18,
    Channel: 'BSO',
    Attendance: 16601,
    AwayTeamScore: 100,
    HomeTeamScore: 115,
    Updated: '2023-04-09T21:32:54',
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -2,
    OverUnder: 230,
    AwayTeamMoneyLine: 108,
    HomeTeamMoneyLine: -129,
    GlobalGameID: 20019399,
    GlobalAwayTeamID: 20000021,
    GlobalHomeTeamID: 20000018,
    PointSpreadAwayTeamMoneyLine: -111,
    PointSpreadHomeTeamMoneyLine: -110,
    LastPlay: null,
    IsClosed: true,
    GameEndDateTime: '2023-04-09T17:38:41',
    HomeRotationNumber: 552,
    AwayRotationNumber: 551,
    NeutralVenue: false,
    OverPayout: -109,
    UnderPayout: -112,
    CrewChiefID: 20000002,
    UmpireID: 20000009,
    RefereeID: 20000001,
    AlternateID: null,
    DateTimeUTC: '2023-04-09T19:30:00',
    SeriesInfo: null,
    Quarters: [
      {
        QuarterID: 162633,
        GameID: 19399,
        Number: 1,
        Name: '1',
        AwayScore: 24,
        HomeScore: 20
      },
      {
        QuarterID: 162656,
        GameID: 19399,
        Number: 2,
        Name: '2',
        AwayScore: 25,
        HomeScore: 27
      },
      {
        QuarterID: 162657,
        GameID: 19399,
        Number: 3,
        Name: '3',
        AwayScore: 24,
        HomeScore: 38
      },
      {
        QuarterID: 162658,
        GameID: 19399,
        Number: 4,
        Name: '4',
        AwayScore: 27,
        HomeScore: 30
      }
    ]
  },
  {
    GameID: 19389,
    Season: 2023,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-04-09T00:00:00',
    DateTime: '2023-04-09T13:00:00',
    AwayTeam: 'PHI',
    HomeTeam: 'BKN',
    AwayTeamID: 7,
    HomeTeamID: 8,
    StadiumID: 8,
    Channel: 'NBCS',
    Attendance: 17732,
    AwayTeamScore: 134,
    HomeTeamScore: 105,
    Updated: '2023-04-09T21:59:10',
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -1.5,
    OverUnder: 219.5,
    AwayTeamMoneyLine: 101,
    HomeTeamMoneyLine: -122,
    GlobalGameID: 20019389,
    GlobalAwayTeamID: 20000007,
    GlobalHomeTeamID: 20000008,
    PointSpreadAwayTeamMoneyLine: -112,
    PointSpreadHomeTeamMoneyLine: -110,
    LastPlay: null,
    IsClosed: true,
    GameEndDateTime: '2023-04-09T15:20:02',
    HomeRotationNumber: 542,
    AwayRotationNumber: 541,
    NeutralVenue: false,
    OverPayout: -109,
    UnderPayout: -112,
    CrewChiefID: 20000014,
    UmpireID: 20000007,
    RefereeID: 20000032,
    AlternateID: null,
    DateTimeUTC: '2023-04-09T17:00:00',
    SeriesInfo: null,
    Quarters: [
      {
        QuarterID: 162607,
        GameID: 19389,
        Number: 1,
        Name: '1',
        AwayScore: 36,
        HomeScore: 21
      },
      {
        QuarterID: 162608,
        GameID: 19389,
        Number: 2,
        Name: '2',
        AwayScore: 25,
        HomeScore: 32
      },
      {
        QuarterID: 162609,
        GameID: 19389,
        Number: 3,
        Name: '3',
        AwayScore: 39,
        HomeScore: 39
      },
      {
        QuarterID: 162610,
        GameID: 19389,
        Number: 4,
        Name: '4',
        AwayScore: 34,
        HomeScore: 13
      }
    ]
  }
];

export const dailyMockGames = [
  {
    GameID: 19426,
    Season: 2023,
    SeasonType: 3,
    Status: 'Scheduled',
    DateTime: new Date('4/20/23 5:00 PM').toISOString(),
    AwayTeam: 'BKN',
    HomeTeam: 'PHI',
    AwayTeamID: 8,
    HomeTeamID: 7,
    StadiumID: 7,
    Channel: 'ESPN',
    Attendance: null,
    AwayTeamScore: null,
    HomeTeamScore: null,
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -8,
    OverUnder: 216.5,
    AwayTeamMoneyLine: 280,
    HomeTeamMoneyLine: -358,
    GlobalGameID: 20019426,
    GlobalAwayTeamID: 20000008,
    GlobalHomeTeamID: 20000007,
    PointSpreadAwayTeamMoneyLine: -108,
    PointSpreadHomeTeamMoneyLine: -113,
    LastPlay: null,
    IsClosed: false,
    GameEndDateTime: null,
    HomeRotationNumber: 502,
    AwayRotationNumber: 501,
    NeutralVenue: false,
    OverPayout: -112,
    UnderPayout: -110,
    CrewChiefID: null,
    UmpireID: null,
    RefereeID: null,
    AlternateID: null,
    SeriesInfo: {
      HomeTeamWins: 0,
      AwayTeamWins: 0,
      GameNumber: 1,
      MaxLength: 1
    },
    Quarters: []
  },
  {
    GameID: 19427,
    Season: 2023,
    SeasonType: 3,
    Status: 'Scheduled',
    DateTime: new Date('4/20/23 6:00 PM').toISOString(),
    AwayTeam: 'NY',
    HomeTeam: 'CLE',
    AwayTeamID: 6,
    HomeTeamID: 12,
    StadiumID: 12,
    Channel: 'ESPN',
    Attendance: null,
    AwayTeamScore: null,
    HomeTeamScore: null,
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -6,
    OverUnder: 214.5,
    AwayTeamMoneyLine: 200,
    HomeTeamMoneyLine: -244,
    GlobalGameID: 20019427,
    GlobalAwayTeamID: 20000006,
    GlobalHomeTeamID: 20000012,
    PointSpreadAwayTeamMoneyLine: -110,
    PointSpreadHomeTeamMoneyLine: -110,
    LastPlay: null,
    IsClosed: false,
    GameEndDateTime: null,
    HomeRotationNumber: 506,
    AwayRotationNumber: 505,
    NeutralVenue: false,
    OverPayout: -110,
    UnderPayout: -111,
    CrewChiefID: null,
    UmpireID: null,
    RefereeID: null,
    AlternateID: null,
    SeriesInfo: {
      HomeTeamWins: 0,
      AwayTeamWins: 0,
      GameNumber: 1,
      MaxLength: 1
    },
    Quarters: []
  },
  {
    GameID: 19428,
    Season: 2023,
    SeasonType: 3,
    Status: 'Scheduled',
    DateTime: new Date('4/20/23 7:00 PM').toISOString(),
    AwayTeam: 'GS',
    HomeTeam: 'SAC',
    AwayTeamID: 26,
    HomeTeamID: 30,
    StadiumID: 29,
    Channel: 'ABC',
    Attendance: null,
    AwayTeamScore: null,
    HomeTeamScore: null,
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -1,
    OverUnder: 238,
    AwayTeamMoneyLine: -106,
    HomeTeamMoneyLine: -114,
    GlobalGameID: 20019428,
    GlobalAwayTeamID: 20000026,
    GlobalHomeTeamID: 20000030,
    PointSpreadAwayTeamMoneyLine: -112,
    PointSpreadHomeTeamMoneyLine: -110,
    LastPlay: null,
    IsClosed: false,
    GameEndDateTime: null,
    HomeRotationNumber: 508,
    AwayRotationNumber: 507,
    NeutralVenue: false,
    OverPayout: -109,
    UnderPayout: -112,
    CrewChiefID: null,
    UmpireID: null,
    RefereeID: null,
    AlternateID: null,
    SeriesInfo: {
      HomeTeamWins: 0,
      AwayTeamWins: 0,
      GameNumber: 1,
      MaxLength: 1
    },
    Quarters: []
  }
];

export const upcomingMockGames = [
  {
    GameID: 19385,
    Season: 2023,
    SeasonType: 1,
    Status: 'Scheduled',
    DateTime: new Date('4/30/23 5:30 PM').toISOString(),
    AwayTeam: 'ATL',
    HomeTeam: 'BOS',
    AwayTeamID: 3,
    HomeTeamID: 9,
    StadiumID: 9,
    Channel: 'ESPN',
    Attendance: 19156,
    AwayTeamScore: 114,
    HomeTeamScore: 120,
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -4.5,
    OverUnder: 224.5,
    AwayTeamMoneyLine: 160,
    HomeTeamMoneyLine: -193,
    GlobalGameID: 20019385,
    GlobalAwayTeamID: 20000003,
    GlobalHomeTeamID: 20000009,
    PointSpreadAwayTeamMoneyLine: -110,
    PointSpreadHomeTeamMoneyLine: -112,
    LastPlay: null,
    IsClosed: true,
    HomeRotationNumber: 536,
    AwayRotationNumber: 535,
    NeutralVenue: false,
    OverPayout: -112,
    UnderPayout: -109,
    CrewChiefID: 20000013,
    UmpireID: 20000033,
    RefereeID: 20000020,
    AlternateID: null,
    SeriesInfo: null,
    Quarters: []
  },
  {
    GameID: 19386,
    Season: 2023,
    SeasonType: 1,
    Status: 'Scheduled',
    DateTime: new Date('4/30/23 5:30 PM').toISOString(),
    AwayTeam: 'DET',
    HomeTeam: 'CHI',
    AwayTeamID: 14,
    HomeTeamID: 11,
    StadiumID: 11,
    Channel: 'NBCS',
    Attendance: 21530,
    AwayTeamScore: 81,
    HomeTeamScore: 103,
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -8.5,
    OverUnder: 220,
    AwayTeamMoneyLine: 289,
    HomeTeamMoneyLine: -366,
    GlobalGameID: 20019386,
    GlobalAwayTeamID: 20000014,
    GlobalHomeTeamID: 20000011,
    PointSpreadAwayTeamMoneyLine: -110,
    PointSpreadHomeTeamMoneyLine: -110,
    LastPlay: null,
    IsClosed: true,
    HomeRotationNumber: 540,
    AwayRotationNumber: 539,
    NeutralVenue: false,
    OverPayout: -110,
    UnderPayout: -110,
    CrewChiefID: 20000006,
    UmpireID: 20000036,
    RefereeID: 20000034,
    AlternateID: null,
    SeriesInfo: null,
    Quarters: []
  },
  {
    GameID: 19387,
    Season: 2023,
    SeasonType: 1,
    Status: 'Scheduled',
    DateTime: new Date('4/30/23 5:30 PM').toISOString(),
    AwayTeam: 'CHA',
    HomeTeam: 'CLE',
    AwayTeamID: 2,
    HomeTeamID: 12,
    StadiumID: 12,
    Channel: 'BSOH',
    Attendance: 19432,
    AwayTeamScore: 106,
    HomeTeamScore: 95,
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -6.5,
    OverUnder: 215,
    AwayTeamMoneyLine: 210,
    HomeTeamMoneyLine: -259,
    GlobalGameID: 20019387,
    GlobalAwayTeamID: 20000002,
    GlobalHomeTeamID: 20000012,
    PointSpreadAwayTeamMoneyLine: -110,
    PointSpreadHomeTeamMoneyLine: -110,
    LastPlay: null,
    IsClosed: true,
    HomeRotationNumber: 532,
    AwayRotationNumber: 531,
    NeutralVenue: false,
    OverPayout: -109,
    UnderPayout: -112,
    CrewChiefID: 20000030,
    UmpireID: 20000040,
    RefereeID: 20000016,
    AlternateID: null,
    SeriesInfo: null,
    Quarters: []
  },
  {
    GameID: 19388,
    Season: 2023,
    SeasonType: 1,
    Status: 'Scheduled',
    DateTime: new Date('4/30/23 6:00 PM').toISOString(),
    AwayTeam: 'ORL',
    HomeTeam: 'MIA',
    AwayTeamID: 5,
    HomeTeamID: 4,
    StadiumID: 4,
    Channel: 'BSSU',
    Attendance: 19894,
    AwayTeamScore: 110,
    HomeTeamScore: 123,
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -5,
    OverUnder: 217.5,
    AwayTeamMoneyLine: 173,
    HomeTeamMoneyLine: -208,
    GlobalGameID: 20019388,
    GlobalAwayTeamID: 20000005,
    GlobalHomeTeamID: 20000004,
    PointSpreadAwayTeamMoneyLine: -109,
    PointSpreadHomeTeamMoneyLine: -113,
    LastPlay: null,
    IsClosed: true,
    HomeRotationNumber: 538,
    AwayRotationNumber: 537,
    NeutralVenue: false,
    OverPayout: -110,
    UnderPayout: -110,
    CrewChiefID: 20000042,
    UmpireID: 20000027,
    RefereeID: 20000046,
    AlternateID: null,
    SeriesInfo: null,
    Quarters: []
  },
  {
    GameID: 19389,
    Season: 2023,
    SeasonType: 1,
    Status: 'Scheduled',
    DateTime: new Date('4/30/23 6:00 PM').toISOString(),
    AwayTeam: 'PHI',
    HomeTeam: 'BKN',
    AwayTeamID: 7,
    HomeTeamID: 8,
    StadiumID: 8,
    Channel: 'NBCS',
    Attendance: 17732,
    AwayTeamScore: 134,
    HomeTeamScore: 105,
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -1.5,
    OverUnder: 219.5,
    AwayTeamMoneyLine: 101,
    HomeTeamMoneyLine: -122,
    GlobalGameID: 20019389,
    GlobalAwayTeamID: 20000007,
    GlobalHomeTeamID: 20000008,
    PointSpreadAwayTeamMoneyLine: -112,
    PointSpreadHomeTeamMoneyLine: -110,
    LastPlay: null,
    IsClosed: true,
    HomeRotationNumber: 542,
    AwayRotationNumber: 541,
    NeutralVenue: false,
    OverPayout: -109,
    UnderPayout: -112,
    CrewChiefID: 20000014,
    UmpireID: 20000007,
    RefereeID: 20000032,
    AlternateID: null,
    SeriesInfo: null,
    Quarters: []
  },
  {
    GameID: 19390,
    Season: 2023,
    SeasonType: 1,
    Status: 'Scheduled',
    DateTime: new Date('4/30/23 6:30 PM').toISOString(),
    AwayTeam: 'IND',
    HomeTeam: 'NY',
    AwayTeamID: 13,
    HomeTeamID: 6,
    StadiumID: 6,
    Channel: 'MSG',
    Attendance: 19812,
    AwayTeamScore: 141,
    HomeTeamScore: 136,
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -10,
    OverUnder: 231,
    AwayTeamMoneyLine: 357,
    HomeTeamMoneyLine: -465,
    GlobalGameID: 20019390,
    GlobalAwayTeamID: 20000013,
    GlobalHomeTeamID: 20000006,
    PointSpreadAwayTeamMoneyLine: -110,
    PointSpreadHomeTeamMoneyLine: -110,
    LastPlay: null,
    IsClosed: true,
    HomeRotationNumber: 544,
    AwayRotationNumber: 543,
    NeutralVenue: false,
    OverPayout: -109,
    UnderPayout: -112,
    CrewChiefID: 20000055,
    UmpireID: 20000078,
    RefereeID: 20000056,
    AlternateID: null,
    SeriesInfo: null,
    Quarters: []
  },
  {
    GameID: 19391,
    Season: 2023,
    SeasonType: 1,
    Status: 'Scheduled',
    DateTime: new Date('4/30/23 6:30 PM').toISOString(),
    AwayTeam: 'HOU',
    HomeTeam: 'WAS',
    AwayTeamID: 22,
    HomeTeamID: 1,
    StadiumID: 1,
    Channel: 'NBC',
    Attendance: 15233,
    AwayTeamScore: 114,
    HomeTeamScore: 109,
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: 4,
    OverUnder: 230.5,
    AwayTeamMoneyLine: -176,
    HomeTeamMoneyLine: 146,
    GlobalGameID: 20019391,
    GlobalAwayTeamID: 20000022,
    GlobalHomeTeamID: 20000001,
    PointSpreadAwayTeamMoneyLine: -110,
    PointSpreadHomeTeamMoneyLine: -110,
    LastPlay: null,
    IsClosed: true,
    HomeRotationNumber: 534,
    AwayRotationNumber: 533,
    NeutralVenue: false,
    OverPayout: -110,
    UnderPayout: -112,
    CrewChiefID: 20000038,
    UmpireID: 20000039,
    RefereeID: 20000011,
    AlternateID: null,
    SeriesInfo: null,
    Quarters: []
  },
  {
    GameID: 19392,
    Season: 2023,
    SeasonType: 1,
    Status: 'Scheduled',
    DateTime: new Date('4/30/23 7:00 PM').toISOString(),
    AwayTeam: 'MIL',
    HomeTeam: 'TOR',
    AwayTeamID: 15,
    HomeTeamID: 10,
    StadiumID: 10,
    Channel: 'BSW',
    Attendance: 19800,
    AwayTeamScore: 105,
    HomeTeamScore: 121,
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -3.5,
    OverUnder: 221,
    AwayTeamMoneyLine: 135,
    HomeTeamMoneyLine: -162,
    GlobalGameID: 20019392,
    GlobalAwayTeamID: 20000015,
    GlobalHomeTeamID: 20000010,
    PointSpreadAwayTeamMoneyLine: -110,
    PointSpreadHomeTeamMoneyLine: -112,
    LastPlay: null,
    IsClosed: true,
    HomeRotationNumber: 530,
    AwayRotationNumber: 529,
    NeutralVenue: false,
    OverPayout: -110,
    UnderPayout: -112,
    CrewChiefID: 20000008,
    UmpireID: 20000047,
    RefereeID: 20000064,
    AlternateID: null,
    SeriesInfo: null,
    Quarters: []
  },
  {
    GameID: 19393,
    Season: 2023,
    SeasonType: 1,
    Status: 'Scheduled',
    DateTime: new Date('4/30/23 7:00 PM').toISOString(),
    AwayTeam: 'SA',
    HomeTeam: 'DAL',
    AwayTeamID: 24,
    HomeTeamID: 25,
    StadiumID: 25,
    Channel: 'BSSW',
    Attendance: 20141,
    AwayTeamScore: 138,
    HomeTeamScore: 117,
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: -4,
    OverUnder: 232,
    AwayTeamMoneyLine: 142,
    HomeTeamMoneyLine: -173,
    GlobalGameID: 20019393,
    GlobalAwayTeamID: 20000024,
    GlobalHomeTeamID: 20000025,
    PointSpreadAwayTeamMoneyLine: -114,
    PointSpreadHomeTeamMoneyLine: -107,
    LastPlay: null,
    IsClosed: true,
    HomeRotationNumber: 548,
    AwayRotationNumber: 547,
    NeutralVenue: false,
    OverPayout: -109,
    UnderPayout: -112,
    CrewChiefID: 20000012,
    UmpireID: 20000010,
    RefereeID: 20000028,
    AlternateID: null,
    SeriesInfo: null,
    Quarters: []
  },
  {
    GameID: 19394,
    Season: 2023,
    SeasonType: 1,
    Status: 'Scheduled',
    DateTime: new Date('4/30/23 7:30 PM').toISOString(),
    AwayTeam: 'SAC',
    HomeTeam: 'DEN',
    AwayTeamID: 30,
    HomeTeamID: 20,
    StadiumID: 20,
    Channel: 'NBCS',
    Attendance: 19652,
    AwayTeamScore: 95,
    HomeTeamScore: 109,
    Quarter: null,
    TimeRemainingMinutes: null,
    TimeRemainingSeconds: null,
    PointSpread: 5,
    OverUnder: 230,
    AwayTeamMoneyLine: -206,
    HomeTeamMoneyLine: 172,
    GlobalGameID: 20019394,
    GlobalAwayTeamID: 20000030,
    GlobalHomeTeamID: 20000020,
    PointSpreadAwayTeamMoneyLine: -114,
    PointSpreadHomeTeamMoneyLine: -108,
    LastPlay: null,
    IsClosed: true,
    HomeRotationNumber: 546,
    AwayRotationNumber: 545,
    NeutralVenue: false,
    OverPayout: -110,
    UnderPayout: -112,
    CrewChiefID: 20000044,
    UmpireID: 20000077,
    RefereeID: 20000019,
    AlternateID: null,
    SeriesInfo: null,
    Quarters: []
  }
];
