import catchup1 from '#/static/images/catch-up/catch-up-1.png';
import catchup2 from '#/static/images/catch-up/catch-up-2.png';
import catchup3 from '#/static/images/catch-up/catch-up-3.png';
import catchup4 from '#/static/images/catch-up/catch-up-4.png';
import catchup5 from '#/static/images/catch-up/catch-up-5.png';
import catchup6 from '#/static/images/catch-up/catch-up-6.png';

export const catchUpMockData = [
  {
    id: 1,
    title: 'Best of Jamal Murray',
    description:
      'Jamal Murray is a Canadian professional basketball player for the Denver Nuggets of the National Basketball Association (NBA). He also represents the Canadian national team.',
    date: 'Sep 22',
    time: '6:00 - 8:00 PM',
    image: catchup1,
    url: '//d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4'
  },
  {
    id: 2,
    title: 'Suns vs Heat',
    description:
      'Suns take on the Heat in an epic battle between two of the leagues top teams. You won’t want to miss this one!',
    date: 'Jun 15',
    time: '3:00 - 5:00 PM',
    image: catchup2,
    url: '//d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4'
  },
  {
    id: 3,
    title: 'Pelicans vs Rockets',
    description:
      'Pelicans take on the Rockets in an epic battle between two of the leagues top teams. You won’t want to miss this one!',
    date: 'Oct 17',
    time: '5:00 - 7:00 PM',
    image: catchup3,
    url: '//d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4'
  },
  {
    id: 4,
    title: 'Warriors vs Raptors',
    description:
      'Warriors take on the Raptors in an epic battle between two of the leagues top teams. You won’t want to miss this one!',
    date: 'Dec 01',
    time: '8:00 - 10:00 PM',
    image: catchup4,
    url: '//d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4'
  },
  {
    id: 5,
    title: 'Grizzlies vs Mavs',
    description:
      'Grizzlies take on the Mavs in an epic battle between two of the leagues top teams. You won’t want to miss this one!',
    date: 'Jan 07',
    time: '2:00 - 4:00 PM',
    image: catchup5,
    url: '//d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4'
  },
  {
    id: 6,
    title: 'Dreams vs Storm',
    description:
      'Dreams take on the Storm in an epic battle between two of the leagues top teams. You won’t want to miss this one!',
    date: 'Nov 15',
    time: '8:00 - 10:00 PM',
    image: catchup6,
    url: '//d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4'
  }
];
