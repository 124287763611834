import React from 'react';
import PropTypes from 'prop-types';
import { focusManager } from '@accedo/vdkweb-navigation';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';
import classnames from 'classnames';
import vw, { unScaledPixel } from '#/utils/vw';
import isRTL from '#/utils/isRTL';
import { FocusNavigationArrow } from '#/components/NavigationArrow/NavigationArrow';
import componentTheme from './ContentGrid.scss';
import {
  calculateSpanCount,
  getItemNavId,
  getStructuredItems,
  increaseInstance,
  calculateItems,
  getTotalSlides,
  getPageToRequest,
  calculateLimit
} from './utils';

const BUFFER = 1;

const DEFAULT_PAGE_SIZE = 20;

const DEFAULT_LIMIT = 10;

const renderItems = ({
  rtl,
  dir,
  itemWidth,
  itemHeight,
  DisplayComponent,
  onClick,
  onFocus,
  items,
  displayComponentProps
}) => {
  return items.map(item => {
    // extracting props. props should only contain what needs to be passed to DisplayComponent
    const { x, y, head, tail, style, ...props } = item;

    if (onClick) {
      props.onClick = () => onClick(item);
    }

    const itemStyle = {
      ...style,
      top: vw(item.y),
      position: 'absolute',
      width: vw(itemWidth),
      height: vw(itemHeight)
    };

    if (rtl) {
      itemStyle.right = vw(item.x);
    } else {
      itemStyle.left = vw(item.x);
    }

    return (
      <DisplayComponent
        key={item.nav.id}
        containerStyle={itemStyle}
        {...displayComponentProps}
        {...props}
        dir={dir}
        onFocus={() => onFocus(item)}
      />
    );
  });
};

/**
 * Grid to display data items.
 * Can be horizontal or vertical, with
 * arbitrary number of rows and columns.
 * The size of the grid is used to
 * calculate how many items are
 * rendered.
 * items are expected to all have the
 * same fixed size. variable item size
 * in the same grid is not supported.
 * See property comments below for more
 * details.
 */

/**
 * Used as a multiplier for tailPadding.
 * This is use to shift cards and keep
 * focus constant on any nth element
 */
const tailPaddingConfig = {
  bookmark: 2,
  wide: 2,
  portrait: 3,
  categories: 4
};
const ContentGrid = (
  {
    className,
    ds,
    data,
    buffer = BUFFER,
    headPadding = 0,
    headPeek: headPeekProp = 0,
    tailPeek: tailPeekProp = 0,
    alignGrid = true,
    vertical: verticalProp = false,
    horizontal = false,
    loop: loopProp = false,
    repeat = false,
    spacing = 0,
    crossOffset = 0,
    width,
    height: carouselHeight,
    nav: parentNav,
    itemWidth,
    itemHeight,
    initialState = {},
    keyProperty,
    animated: animatedProp = false,
    DisplayComponent,
    displayComponentProps,
    tailPadding: tailPaddingProp = itemWidth *
      tailPaddingConfig[displayComponentProps.type],
    theme,
    onClick,
    onFocus,
    onChange,
    useInternalArrows = true,
    style,
    dir = 'ltr',
    showDots = false,
    ...props
  },
  ref
) => {
  const pageSize = React.useMemo(() => {
    return ds?.pageSize || DEFAULT_PAGE_SIZE;
  }, [ds]);
  const fetchItemLimit = React.useMemo(() => {
    return ds?.fetchItemLimit || DEFAULT_LIMIT;
  }, [ds]);
  /**
   * vertical orientation is default
   */
  const vertical = horizontal === verticalProp || horizontal === false;

  // can only loop if not repeating
  const loop = loopProp && !repeat;

  const rtl = isRTL(dir);

  const height = carouselHeight || itemHeight;

  /**
   * calculate padding and peek when alignGrid flag is active.
   * headPadding prop is always used because it will define
   * where items are placed when grid is at head position.
   * headPeek will be same as headPadding so items align in
   * same place when grid is shifted.
   * tailPadding and tailPeek will use same value, calculated
   * to be at least as large as tailPadding prop, but adjusted
   * so items are always aligned when items are shifted into
   * view.
   */
  const headPeek = React.useMemo(() => {
    if (alignGrid) {
      return headPadding;
    }
    return headPeekProp;
  }, [alignGrid, headPeekProp, headPadding]);
  const tailPadding = React.useMemo(() => {
    if (alignGrid) {
      const itemSize = vertical ? itemHeight : itemWidth;
      const lengthSize = vertical ? height : width;
      let calculated =
        lengthSize -
        headPadding -
        Math.floor((lengthSize - headPadding) / (itemSize + spacing)) *
          (itemSize + spacing) +
        spacing;
      while (calculated < tailPaddingProp) {
        calculated += itemSize + spacing;
      }
      // use original value if calculated is large enough that a single item will not fit in view
      if (lengthSize - headPadding - calculated < itemSize) {
        return tailPaddingProp;
      }
      return calculated;
    }
    return tailPaddingProp;
  }, [
    tailPaddingProp,
    height,
    alignGrid,
    headPadding,
    itemHeight,
    spacing,
    vertical,
    width,
    itemWidth
  ]);
  const tailPeek = React.useMemo(() => {
    if (alignGrid) {
      return tailPadding;
    }
    return tailPeekProp;
  }, [tailPadding, alignGrid, tailPeekProp]);

  /**
   * Main state
   * values are combined into one object to avoid excessive rerender when changing multiple state values
   */
  const initialPosition =
    initialState?.position === undefined ? headPadding : initialState.position;

  const initialIndex = initialState?.position || 0; // TODO: We may need to calculate index from position and size

  const [state, setState] = React.useState({
    // Whether grid has been focused since last time data/ds props changed
    focused: false,
    // position of shift panel, used to render shift panel in correct position and to calculate what items should render
    position: initialPosition,
    // Whether grid is in head or tail edge position
    edge: {
      head: initialPosition === headPadding,
      tail: false
    },
    // spread is used to increase the area to render items in while shifting, so there are always visible items when shift panel moves
    spread: [initialPosition, initialPosition],
    // items to render at the current position
    items: undefined,
    id: initialIndex,
    forwardFocus: initialIndex
      ? getItemNavId(initialIndex, parentNav.id)
      : undefined,
    dots: []
  });

  /**
   * references to state values are used to allow
   * accessing correct value inside hooks without
   * adding more dependency to the hook which will
   * trigger the hook more often than intended.
   */
  const stateRef = React.useRef();
  stateRef.current = state;

  const lastState = React.useRef({});

  // Reference to current position so value can be accessed inside hooks without using position as a dependency
  const positionRef = React.useRef();
  positionRef.current = state.position;

  // reference to shift panel element
  const shiftPanel = React.useRef();

  /**
   * used to align update of position and spread so
   * they are synched.
   * returns object to be spread when updating state.
   */
  const updatePosition = React.useCallback(
    (stateValue, newPosition, reset) => {
      let spreadValue = stateValue.spread;
      // smallest spread is always used when not animated
      let resetSpread = reset === true || animatedProp !== true;

      if (!resetSpread && shiftPanel.current && newPosition !== undefined) {
        const MAX_SPREAD_MULTIPLIER = 3;
        const MAX_SPREAD = (vertical ? height : width) * MAX_SPREAD_MULTIPLIER;

        let newSpreadMin = Math.min(
          newPosition,
          spreadValue[0],
          spreadValue[1]
        );
        let newSpreadMax = Math.max(
          newPosition,
          spreadValue[0],
          spreadValue[1]
        );

        // limit how much can be rendered at one time. especially important when looping, else all items will be rendered.
        if (Math.abs(newSpreadMax - newSpreadMin) > MAX_SPREAD) {
          /**
           * read from dom to get current position of shift panel.
           * accessing dom value will trigger reflow and repaint
           * and this can affect performance, so access is limited
           * here only when needed, and value is used to improve
           * performance by avoiding excessive render.
           * rendered panel position is defined in vw values, and
           * getBoundingClientRect will get the pixel value, so it
           * needs to be unscaled to get a 1080 based pixel value
           * that is later used to calculate vw value again.
           */
          const panelPosition = unScaledPixel(
            shiftPanel.current.getBoundingClientRect()[
              vertical ? 'top' : 'left'
            ]
          );

          // grid will move forward by shifting panel backward
          if (panelPosition > newPosition) {
            newSpreadMax = panelPosition;
          }
          // grid will move backward by shifting panel forward
          else if (panelPosition < newPosition) {
            newSpreadMin = panelPosition;
          }

          // default to reset if spread is still too large
          resetSpread = Math.abs(newSpreadMax - newSpreadMin) > MAX_SPREAD;
        }

        if (!resetSpread) {
          spreadValue = [newSpreadMin, newSpreadMax];
        }
      }

      if (resetSpread) {
        spreadValue = [
          newPosition === undefined ? stateValue.position : newPosition,
          newPosition === undefined ? stateValue.position : newPosition
        ];
      }

      return {
        position: newPosition === undefined ? stateValue.position : newPosition,
        // spread needs to be updated together with position
        spread: spreadValue
      };
    },
    [animatedProp, vertical, width, height]
  );

  const onItemClick = React.useCallback(
    item => {
      onClick?.({
        data: item.data,
        state: {
          position: positionRef.current,
          id: item.data[keyProperty]
        }
      });
    },
    [onClick, keyProperty]
  );

  /**
   * track what to focus after looping
   * 0 is no pending refocus.
   * 1 will focus first item after looping at tail
   * -1 will focus last item after looping at head
   */
  const reFocus = React.useRef(0);

  /**
   * setup internal nav object used to trigger looping at edges
   */
  const internalNav = React.useMemo(() => {
    let internal;
    if (loop) {
      internal = {};

      const spanCount = calculateSpanCount(
        vertical ? width : height,
        vertical ? itemWidth : itemHeight,
        spacing
      );
      const lengthSize = vertical ? height : width;
      const itemSizeWithSpacing = (vertical ? itemHeight : itemWidth) + spacing;

      const triggerShift = count => {
        const pos =
          lengthSize +
          spacing -
          Math.floor(count / spanCount) * itemSizeWithSpacing -
          tailPadding;
        // only loop when shift is needed
        if (
          reFocus.current === 0 &&
          pos < positionRef.current &&
          Math.floor(count / spanCount) * itemSizeWithSpacing >
            lengthSize - tailPadding - headPadding + spacing
        ) {
          /**
           * calling changeFocus twice to reset both currentFocus
           * and lastFocus and prevent any further internal focus
           * until the refocus is triggered.
           */
          focusManager.changeFocus('');
          focusManager.changeFocus('');

          // direct refocus when item is already rendered
          if (
            stateRef.current.items?.[stateRef.current.items.length - 1]?.tail
          ) {
            focusManager.changeFocus(
              stateRef.current.items[stateRef.current.items.length - 1].nav.id
            );
          }
          // need to render item to refocus
          else {
            reFocus.current = -1;
          }
          setState(value => ({
            ...value,
            ...updatePosition(value, pos),
            edge: { head: false, tail: true }
          }));
        }
      };

      const nextHead = () => {
        const triggerPosition = positionRef.current;
        if (ds) {
          ds.getTotalNumber().then(count => {
            // position has not changed during asynchronous operation
            if (triggerPosition === positionRef.current) {
              triggerShift(count);
            }
          });
        } else if (data) {
          triggerShift(data.length);
        }
        return true;
      };

      const nextTail = () => {
        // only loop when shift is needed
        if (positionRef.current < headPadding && reFocus.current === 0) {
          /**
           * calling changeFocus twice to reset both currentFocus
           * and lastFocus and prevent any further internal focus
           * until the refocus is triggered.
           */
          focusManager.changeFocus('');
          focusManager.changeFocus('');
          // direct refocus when item is already rendered
          if (stateRef.current.items?.[0]?.head) {
            focusManager.changeFocus(stateRef.current.items[0].nav.id);
          }
          // need to render item to refocus
          else {
            reFocus.current = 1;
            // will trigger shift and data load if needed
          }
          setState(value => ({
            ...value,
            ...updatePosition(value, headPadding),
            edge: { head: true, tail: false }
          }));
        }
        return true;
      };

      if (vertical) {
        internal.nextup = nextHead;
        internal.nextdown = nextTail;
      } else {
        internal[rtl ? 'nextright' : 'nextleft'] = nextHead;
        internal[rtl ? 'nextleft' : 'nextright'] = nextTail;
      }
    }
    return internal;
  }, [
    ds,
    rtl,
    data,
    updatePosition,
    loop,
    vertical,
    width,
    height,
    itemWidth,
    itemHeight,
    spacing,
    tailPadding,
    headPadding
  ]);

  const internalDS = React.useMemo(() => {
    const items = [];
    let totalItems = 0;
    let requestedPage = 0;
    let maxReturnedPage = 0;
    let latestInitialIndex = 0;
    let latestLastIndex = 0;
    const addItems = newItems => {
      const itemsToRemove = 0;
      const itemsToAdd = newItems.data || [];
      items.splice(items.length, itemsToRemove, ...itemsToAdd);
    };

    const getTotalItems = async () => {
      if (totalItems) {
        return Promise.resolve(totalItems);
      }
      // Just get one to calculate total
      return ds.getData(0, 1).then(async result => {
        totalItems = result.total;
        return totalItems;
      });
    };

    const getData = (initialIndexParam, lastIndex) => {
      latestInitialIndex = initialIndexParam;
      latestLastIndex = lastIndex;

      if (totalItems <= items.length && totalItems > 0) {
        // We have fetched all the data, just return the proper one
        return Promise.resolve({
          data: items.slice(initialIndexParam, lastIndex),
          total: totalItems,
          from: initialIndexParam,
          to: lastIndex
        });
      }
      const limitToRequest = calculateLimit({ totalItems, fetchItemLimit });
      const pageToRequest = getPageToRequest({
        maxRequestedPage: requestedPage,
        lastIndex,
        pageSize,
        limitToRequest
      });
      if (totalItems === 0 || items.length - limitToRequest < lastIndex) {
        // We should check if request/fetch is needed based on previous requests
        if (
          pageToRequest === requestedPage &&
          requestedPage > maxReturnedPage
        ) {
          // A previous request to the same page was done before, return mock
          const extraItems = lastIndex - items.length;
          if (extraItems > 0) {
            // we need $extraItems extra items
            const slicedItems = items.slice(
              initialIndexParam,
              items.length - 1
            );
            for (let index = items.length - 1; index < lastIndex; index += 1) {
              slicedItems.push({ id: `test${index}`, url: '' });
            }
            return Promise.resolve({
              data: slicedItems,
              total: totalItems,
              from: initialIndexParam,
              to: items.length - 1
            });
          }
          // no need for extra item required
          return Promise.resolve({
            data: items.slice(initialIndexParam, items.length - 1),
            total: totalItems,
            from: initialIndexParam,
            to: items.length - 1
          });
        }
        requestedPage += 1;
        // Internal getData get from fetcher from page $pageToRequest
        return ds
          .getData(pageToRequest, pageSize, lastIndex)
          .then(async result => {
            maxReturnedPage += 1;
            addItems(result);
            totalItems = await ds.getTotalNumber();
            return {
              data: items.slice(latestInitialIndex, latestLastIndex),
              total: totalItems,
              from: latestInitialIndex,
              to: latestLastIndex,
              emptyDSResponse: result.data?.length === 0
            };
          });
      }
      // Data from previously stored
      return Promise.resolve({
        data: items.slice(initialIndexParam, lastIndex),
        total: totalItems,
        from: initialIndexParam,
        to: lastIndex
      });
    };

    return {
      items,
      getData,
      getTotalItems
    };
  }, [ds, fetchItemLimit, pageSize]);

  const calculateDots = React.useCallback(async () => {
    const dots = [];
    let totalItems;
    let items;
    if (ds) {
      totalItems = await internalDS.getTotalItems();
      const dsData = await internalDS.getData(0, totalItems);
      items = dsData.data;
    } else {
      totalItems = data.length;
      items = data;
    }
    const slides = getTotalSlides(
      vertical ? width : height,
      vertical ? itemWidth : itemHeight,
      spacing,
      totalItems
    );
    for (let i = 0; i < slides; i += 1) {
      dots.push(
        <div
          key={items[i]?.id}
          className={state.id === items[i]?.id ? componentTheme.active : ''}
        />
      );
    }
    setState(value => ({ ...value, dots }));
  }, [
    data,
    ds,
    internalDS,
    height,
    itemHeight,
    itemWidth,
    spacing,
    vertical,
    width,
    state.id
  ]);

  React.useEffect(() => {
    if (showDots) {
      calculateDots();
    }
  }, [calculateDots, showDots]);

  /**
   * react to data source changing
   */
  // ref is used to avoid callback changing each time value changes
  const initialPositionRef = React.useRef();
  initialPositionRef.current = initialPosition;
  const itemsRef = React.useRef();
  itemsRef.current = state.items;
  const recoverFocus = React.useRef('');
  React.useEffect(() => {
    const currentFocus = focusManager.getCurrentFocus();
    // grid currently has focus while data is changing.
    if (itemsRef.current?.some(item => item.nav.id === currentFocus)) {
      // track the current focused item to try to recover focus to same item after items have rendered again
      recoverFocus.current = currentFocus;
    }
    let ff = {
      // if there is no recoverFocus then forwardFocus is reset so it will default to the first visible item after items have rendered
      forwardFocus: recoverFocus.current || ''
    };
    // if itemsRef has no value then grid has not yet been rendered.
    if (!itemsRef.current && !recoverFocus.current) {
      // don't reset forward focus if grid has not been rendered before
      ff = undefined;
    }
    setState(value => ({
      ...value,
      ...ff,
      id: recoverFocus.current?.[keyProperty],
      // new instance to represent the data change
      instance: increaseInstance(),
      // reset focused flag until item gains focus. this will prioritize forwardFocus instead of last focus
      focused: false,
      // reset to initial position before items are loaded for new source, so correct items are loaded
      ...updatePosition(value, initialPositionRef.current, true)
    }));
  }, [
    // this effect should only trigger when data source changes
    data,
    ds,
    keyProperty,
    updatePosition
  ]);

  const automaticFocus = React.useRef(false);

  const edgeRef = React.useRef();
  edgeRef.current = state.edge;
  const forwardFocusRef = React.useRef();
  forwardFocusRef.current = state.forwardFocus;
  const focusedRef = React.useRef();
  focusedRef.current = state.focused;
  /**
   * update what to focus when items have changed
   */
  React.useEffect(() => {
    let focusItem;
    let adjustPosition = false;

    // recover focus when grid item was focused during data change
    if (recoverFocus.current && state.items?.length) {
      focusItem = state.items?.find?.(
        item => item.nav.id === recoverFocus.current
      );
      // if recoverFocus was already focused,
      // then a new focus event is not triggered,
      // so need to adjust position here
      adjustPosition = true;
    } else {
      focusItem = state.items?.find?.(
        item => item.nav.id === forwardFocusRef.current
      );
    }

    let newPosition;
    // ensure that item is visible
    if (focusItem && adjustPosition) {
      const dim = vertical ? 'y' : 'x';
      const lengthSize = vertical ? height : width;
      const itemSize = vertical ? itemHeight : itemWidth;
      let offset = edgeRef.current.head ? headPadding : headPeek;
      if (focusItem[dim] < offset - positionRef.current) {
        newPosition = offset - focusItem[dim];
      }
      offset = edgeRef.current.tail ? tailPadding : tailPeek;
      if (
        newPosition === undefined &&
        focusItem[dim] + itemSize > lengthSize - offset - positionRef.current
      ) {
        newPosition = lengthSize - offset - (focusItem[dim] + itemSize);
      }
    }

    // default to first item that is visible when no other item is found
    if (!focusItem) {
      const dim = vertical ? 'y' : 'x';
      const offset = edgeRef.current.head ? headPadding : headPeek;
      focusItem = state.items?.find?.(
        item => item[dim] >= offset - positionRef.current
      );
    }

    if (focusItem) {
      /**
       * there is a recoverFocus set, meaning that the grid had focus
       * while data changed, and grid should maintain focus.
       * if the last focused item is no longer included in the new
       * items, then focus must be placed on a new item so focus is
       * maintained.
       */
      if (recoverFocus.current && recoverFocus.current !== focusItem.nav.id) {
        automaticFocus.current = true;
        // new focus will trigger position adjustment and state update if needed.
        focusManager.changeFocus(focusItem.nav.id);
      } else {
        /**
         * focus will not change, manually adjust state and position as needed
         */
        setState(value => ({
          ...value,
          ...updatePosition(value, newPosition),
          id: focusItem.data[keyProperty],
          forwardFocus: focusItem.nav.id,
          edge: {
            head: focusItem.head === true || value.edge.head,
            tail: focusItem.tail === true || value.edge.tail
          }
        }));
      }
      recoverFocus.current = '';
    }
  }, [
    // important that only items change trigger this effect, so the focus item is only changed when the items change.
    state.items,

    // rest of dependencies are static values that should not change after grid create and not trigger this effect.
    parentNav.id,
    vertical,
    headPadding,
    headPeek,
    tailPadding,
    tailPeek,
    updatePosition,
    itemWidth,
    itemHeight,
    height,
    width,
    keyProperty
  ]);

  const parentId = React.useRef();
  parentId.current = parentNav.id;
  const initialStateRef = React.useRef();
  initialStateRef.current = initialState;
  const initialInstance = initialState?.instance;
  /**
   * allow controlling grid by updating initialState.instance
   */
  React.useEffect(() => {
    let newPosition;
    let newId;

    if (
      !Number.isNaN(Number(initialStateRef.current?.position)) &&
      positionRef.current !== initialStateRef.current.position
    ) {
      newPosition = initialStateRef.current.position;
    }

    if (initialStateRef.current?.id !== undefined) {
      const id = getItemNavId(
        initialStateRef.current?.position,
        parentId.current
      );
      if (forwardFocusRef.current !== id) {
        newId = id;
      }
    }
    if (newPosition !== undefined || newId !== undefined) {
      setState(value => {
        let newState = {};
        if (newPosition !== undefined) {
          newState = updatePosition(value, newPosition);
        }

        if (newId !== undefined) {
          newState.forwardFocus = newId;
        }

        return {
          ...value,
          ...newState,
          // new instance to trigger item refresh
          instance: increaseInstance()
        };
      });
    }
  }, [
    // this effect should only be triggered by initialState.instance
    initialInstance,
    updatePosition
  ]);

  /**
   * refocus opposite edge item after looping
   */
  React.useEffect(() => {
    if (reFocus.current !== 0 && shiftPanel.current) {
      // refocus first item and items have been updated to contain first item
      if (reFocus.current === 1 && state.items?.[0].head === true) {
        focusManager.changeFocus(state.items[0].nav.id);
        reFocus.current = 0;
      }
      // refocus last item and items have been updated to contain last item
      else if (
        reFocus.current === -1 &&
        state.items?.[state.items.length - 1].tail === true
      ) {
        focusManager.changeFocus(state.items[state.items.length - 1].nav.id);
        reFocus.current = 0;
      }
    } else if (
      !forwardFocusRef.current &&
      initialStateRef?.current.position !== undefined
    ) {
      // it must happen when component is using dataSource only. When the items are got for
      // the first time, the focus is changed automatically
      forwardFocusRef.current = initialStateRef.current.position;
    }
  }, [state.items]);

  /**
   * React to focus change and update position to display the focused item in view
   */
  // ref is used to avoid callback changing each time value changes
  const onItemFocus = React.useCallback(
    item => {
      const { x, y, head, tail, nav } = item;
      recoverFocus.current = '';
      let newPosition;
      let newEdge;
      let newState = stateRef.current;
      const itemPosition = vertical ? y : x;
      const itemSize = vertical ? itemHeight : itemWidth;
      const lengthSize = vertical ? height : width;
      const headOffset = head === true ? headPadding : headPeek;
      const tailOffset = tail === true ? tailPadding : tailPeek;
      if (
        itemPosition + itemSize + tailOffset >
        -positionRef.current + lengthSize
      ) {
        newPosition = -(itemPosition + itemSize + tailOffset - lengthSize);

        if (tail === true && edgeRef.current.tail !== true) {
          newEdge = {
            tail: true,
            head: false
          };
        }
        // shifting away from head, so no longer at head position
        else if (edgeRef.current.head === true) {
          newEdge = {
            tail: false,
            head: false
          };
        }
      } else if (itemPosition - headOffset < -positionRef.current) {
        newPosition = -itemPosition + headOffset;

        if (head === true && edgeRef.current.head !== true) {
          newEdge = {
            tail: false,
            head: true
          };
        }
        // shifting away from tail, so no longer at tail position
        else if (edgeRef.current.tail === true) {
          newEdge = {
            tail: false,
            head: false
          };
        }
      }
      if (
        newPosition !== undefined ||
        !focusedRef.current ||
        forwardFocusRef.current !== nav.id
      ) {
        // setState callback is not instant, so a local value is used so the flag can be reset
        const autoFocus = automaticFocus.current;
        automaticFocus.current = false;
        const now = new Date().getTime();
        // throttle when focus changes too often to avoid going out of sync with animation and to improve performance and responsiveness
        const throttle = now - newState.lastUpdate < 100;
        newState = {
          lastUpdate: now,
          throttle,
          // don't consider focused if focus was triggered by automatic focus
          focused: autoFocus !== true,
          id: item.data[keyProperty],
          forwardFocus: nav.id,
          edge: { ...newState.edge, ...newEdge },
          ...updatePosition(newState, newPosition, throttle)
        };
        setState(value => ({ ...value, ...newState }));
      }
      onFocus?.({
        data: item.data,
        state: {
          position: newState.position,
          id: item.data[keyProperty]
        }
      });
    },
    [
      onFocus,
      updatePosition,
      itemHeight,
      itemWidth,
      vertical,
      height,
      width,
      tailPadding,
      headPadding,
      headPeek,
      tailPeek,
      keyProperty
    ]
  );

  const sourceRef = React.useRef();
  sourceRef.current = { ds, data, internalDS };
  const dataInstance = React.useRef(0);
  const spreadMin = state.spread[0];
  const spreadMax = state.spread[1];
  /**
   * Loading data for the current position
   */
  React.useEffect(() => {
    dataInstance.current += 1;
    const instance = dataInstance.current;

    const { firstIndex, lastIndex } = calculateItems({
      spread: [spreadMin, spreadMax],
      width,
      buffer,
      vertical,
      height,
      itemWidth,
      spacing,
      itemHeight
    });

    if (sourceRef.current.data?.length) {
      // invalid data index at position
      if (
        firstIndex >= sourceRef.current.data.length &&
        positionRef.current !== headPadding
      ) {
        // reset position to trigger new render
        setState(value => ({
          ...value,
          ...updatePosition(value, headPadding, true)
        }));
      } else {
        const items = getStructuredItems({
          rtl,
          parent: parentId.current,
          firstIndex,
          total: sourceRef.current.data.length,
          items: sourceRef.current.data.slice(firstIndex, lastIndex + 1),
          keyProperty,
          vertical,
          itemHeight,
          itemWidth,
          spacing,
          width,
          height
        });
        setState(value => ({
          ...value,
          items
        }));
      }
    } else if (sourceRef.current.ds) {
      if (instance === dataInstance.current) {
        sourceRef.current.ds.hasData().then(initialData => {
          sourceRef.current.ds
            .isPaginationAllowed()
            .then(isPaginationAllowed => {
              if (!initialData || isPaginationAllowed) {
                sourceRef.current.internalDS
                  .getData(firstIndex, lastIndex)
                  .then(result => {
                    // data change effect has not been triggered since data load started, this data is correct
                    // if (instance === dataInstance.current) {
                    // no data at position
                    if (
                      !result?.data?.length &&
                      positionRef.current !== headPadding
                    ) {
                      // reset position to trigger new render
                      setState(value => ({
                        ...value,
                        ...updatePosition(value, headPadding, true)
                      }));
                    } else {
                      const items = getStructuredItems({
                        rtl,
                        parent: parentId.current,
                        firstIndex: result.from,
                        total: result.total,
                        items: result.data,
                        keyProperty,
                        vertical,
                        itemHeight,
                        itemWidth,
                        spacing,
                        width,
                        height
                      });

                      if (
                        !forwardFocusRef.current &&
                        initialStateRef.current?.id === undefined
                      ) {
                        // we need to set the initial state
                        // only for the firstime because forwardFocusRef.current is undefined
                        // for other iterations, focus must follow the items nav strategy
                        initialStateRef.current.id =
                          items && items[0] && items[0].nav.id;
                      }
                      if (result.emptyDSResponse) {
                        // If response from DS is empty we have reached end of data and need to avoid scroll issues
                        // by navigating back to the latest item
                        focusManager.changeFocus(
                          items[items.length - 1]?.nav?.id
                        );
                      } else {
                        setState(value => ({
                          ...value,
                          items
                        }));
                      }
                    }
                  });
              }
            });
        });
      }
    }
  }, [
    /**
     * spread array is not of interest,
     * the values inside the array are.
     * using separate variables to
     * avoid this effect potentially
     * triggering because the array
     * changed but the values are same.
     */
    spreadMin,
    spreadMax,

    /**
     * instance is updated when data or ds changes.
     * state and state position needs to update when
     * data or ds changes, so data and ds are not
     * used as dependencies here so they don't
     * trigger this effect. instead instance will
     * trigger this effect after data or ds has
     * updated and instance value has changed.
     */
    // state.instance,

    /**
     * static/stable values that are not expected
     * to change after grid has been created,
     * and should not trigger this effect after
     * grid is created, but the values are needed
     * inside the effect, so they are dependencies
     * to appease eslint and static analysis
     * during compile.
     */
    updatePosition,
    rtl,
    keyProperty,
    vertical,
    headPadding,
    itemHeight,
    itemWidth,
    spacing,
    width,
    height,
    buffer,
    data,
    ds
  ]);

  /**
   * Place shift panel to reflect position
   */
  const shiftStyle = {
    position: 'absolute'
  };
  {
    let x;
    let y;
    if (vertical) {
      x = crossOffset;
      if (rtl) {
        x = width - crossOffset;
      }
      y = state.position;
    } else {
      x = state.position;
      if (rtl && !vertical) {
        x = width - state.position;
      }
      y = crossOffset;
    }
    if (animatedProp) {
      shiftStyle.transform = `translate3d(${vw(x)}, ${vw(y)}, 0)`;
      shiftStyle.left = 0;
      shiftStyle.top = 0;
    } else {
      shiftStyle.left = vw(x);
      shiftStyle.top = vw(y);
      shiftStyle.transition = 'all 300ms ease-in-out';
    }
  }

  /**
   * peek blocks are used to cover the peek area.
   * peek blocks can be styled to display fading of content if needed.
   * peek blocks will also avoid triggering grid shift with pointer focus in browser.
   */
  const headPeekBlocker = React.useMemo(() => {
    if (vertical) {
      return {
        width: vw(width),
        height: vw(headPeek - spacing),
        left: vw(crossOffset)
      };
    }
    return {
      width: vw(headPeek - spacing),
      height: vw(height),
      top: vw(crossOffset)
    };
  }, [width, headPeek, crossOffset, vertical, height, spacing]);
  const tailPeekBlocker = React.useMemo(() => {
    if (vertical) {
      return {
        width: vw(width),
        height: vw(tailPeek - spacing),
        left: vw(crossOffset)
      };
    }
    return {
      width: vw(tailPeek - spacing),
      height: vw(height),
      top: vw(crossOffset)
    };
  }, [width, tailPeek, crossOffset, vertical, height, spacing]);

  /**
   * Cull items when animation ends
   */
  React.useEffect(
    () => {
      let cleanup;
      const panel = shiftPanel.current;
      if (animatedProp && panel) {
        const end = event => {
          if (event.target === panel && event.propertyName === 'transform') {
            // Animation has stopped, reset spread, items outside grid will be culled
            setState(value => ({
              ...value,
              ...updatePosition(value, value.position, true)
            }));
          }
        };
        panel.addEventListener('transitionend', end);
        cleanup = () => {
          panel.removeEventListener('transitionend', end);
        };
      }
      return cleanup;
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    // ref is used as a dependency. changing ref alone will not trigger a rerender, but once this is available the listener should be added
    [animatedProp, shiftPanel.current]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  const gridTheme = React.useMemo(() => ({ ...componentTheme, ...theme }), [
    theme
  ]);

  /**
   * Method to calculate the internal navigation of the grid pages
   */
  const page = React.useCallback(
    direction => {
      const triggerPosition = positionRef.current;

      const spanCount = calculateSpanCount(
        vertical ? width : height,
        vertical ? itemWidth : itemHeight,
        spacing
      );
      const lengthSize = vertical ? height : width;
      const itemSizeWithSpacing = (vertical ? itemHeight : itemWidth) + spacing;

      const triggerShift = count => {
        const tailPos =
          lengthSize +
          spacing -
          Math.ceil(count / spanCount) * itemSizeWithSpacing -
          tailPadding;

        const pos = Math.max(
          tailPos,
          positionRef.current -
            itemSizeWithSpacing *
              Math.max(1, Math.floor(lengthSize / itemSizeWithSpacing) - 1)
        );

        setState(value => ({
          ...value,
          ...updatePosition(value, pos),
          edge: { head: false, tail: pos === tailPos }
        }));
      };

      if (direction === -1) {
        if (!stateRef.current.edge.head) {
          const pos = Math.min(
            headPadding,
            positionRef.current +
              itemSizeWithSpacing *
                Math.max(1, Math.floor(lengthSize / itemSizeWithSpacing) - 1)
          );
          setState(value => ({
            ...value,
            ...updatePosition(value, pos),
            edge: { tail: false, head: pos === headPadding }
          }));
        } else if (loop) {
          const loopTail = count => {
            const tailPos =
              lengthSize +
              spacing -
              Math.floor(count / spanCount) * itemSizeWithSpacing -
              tailPadding;
            setState(value => ({
              ...value,
              ...updatePosition(value, tailPos),
              edge: { tail: true, head: false }
            }));
          };

          if (ds) {
            ds.getTotalNumber().then(count => {
              // position has not changed during asynchronous operation
              if (triggerPosition === positionRef.current) {
                loopTail(count);
              }
            });
          } else if (data) {
            loopTail(data.length);
          }
        }
      } else if (direction === 1) {
        if (!stateRef.current.edge.tail) {
          if (ds) {
            ds.getTotalNumber().then(count => {
              // position has not changed during asynchronous operation
              if (triggerPosition === positionRef.current) {
                triggerShift(count);
              }
            });
          } else if (data) {
            triggerShift(data.length);
          }
        } else if (loop) {
          setState(value => ({
            ...value,
            ...updatePosition(value, headPadding),
            edge: { tail: false, head: true }
          }));
        }
      }
    },
    [
      vertical,
      width,
      height,
      itemWidth,
      itemHeight,
      spacing,
      tailPadding,
      updatePosition,
      loop,
      headPadding,
      ds,
      data
    ]
  );

  React.useImperativeHandle(ref, () => ({ page }), [page]);

  const last = lastState.current;
  lastState.current = state;
  if (last.id && state.id) {
    if (
      onChange &&
      (last.id !== state.id || last.position !== state.position)
    ) {
      onChange(lastState.current);
    }
  }

  /**
   * animate grid shift when
   * not throttling and
   * focused once or when looping
   */
  const animated =
    animatedProp && !state.throttle && (state.focused || reFocus.current !== 0);

  const gridNav = React.useMemo(() => {
    const value = {
      ...parentNav,
      /**
       * last focus can cause issues when grid items
       * have been removed and last focus is referring
       * to an item that is no longer rendered.
       * instead forwardFocus is updated to always
       * reflect what should gain focus.
       */
      useLastFocus: false,
      forwardFocus: state.forwardFocus,
      ...(internalNav ? { internal: internalNav } : undefined)
    };

    /*
     * only allow navigating away from grid at edge
     */
    if (!state.edge.head) {
      if (rtl) {
        value[vertical ? 'nextup' : 'nextleft'] = undefined;
      } else {
        value[vertical ? 'nextup' : 'nextright'] = undefined;
      }
    }
    if (!state.edge.tail) {
      if (rtl) {
        value[vertical ? 'nextdown' : 'nextleft'] = undefined;
      } else {
        value[vertical ? 'nextdown' : 'nextright'] = undefined;
      }
    }
    return value;
  }, [
    parentNav,
    state.forwardFocus,
    state.edge.head,
    state.edge.tail,
    internalNav,
    rtl,
    vertical
  ]);

  const isArrowEnabled = position => {
    const slides = getTotalSlides(
      vertical ? height - headPadding : width,
      vertical ? itemHeight : itemWidth,
      spacing,
      state.items?.length
    );

    return useInternalArrows === false
      ? false
      : loop || ((slides || 0) > 0 && !state.edge[position]);
  };

  return (
    <FocusDiv
      dir={dir}
      style={{
        width: vw(width),
        height: vw(height),
        ...style
      }}
      nav={gridNav}
      className={classnames(
        componentTheme.grid,
        theme?.grid,
        className,
        gridTheme[vertical ? 'vertical' : 'horizontal'],
        {
          [gridTheme.animatedGrid]: animated,
          [componentTheme.tail]: state.edge.tail,
          [componentTheme.head]: state.edge.head,
          [theme?.tail]: state.edge.tail,
          [theme?.head]: state.edge.head
        }
      )}
      {...props}
    >
      <div ref={shiftPanel} className={gridTheme.shiftPanel} style={shiftStyle}>
        {state.items?.length
          ? renderItems({
              rtl,
              dir,
              itemWidth,
              itemHeight,
              DisplayComponent,
              displayComponentProps,
              onFocus: onItemFocus,
              items: state.items,
              onClick: onClick && onItemClick
            })
          : null}
      </div>
      {showDots && !!state.items?.length && (
        <div dir={dir} className={componentTheme.heroDots}>
          {state.dots}
        </div>
      )}
      <div
        className={classnames(componentTheme.headBlock, theme?.headBlock)}
        style={headPeekBlocker}
      />
      <div
        className={classnames(componentTheme.tailBlock, theme?.tailBlock)}
        style={tailPeekBlocker}
      />
      <FocusNavigationArrow
        right={horizontal && rtl}
        left={horizontal && !rtl}
        up={vertical}
        theme={componentTheme}
        enabled={isArrowEnabled('head')}
        onClick={() => page(-1)}
        nav={{
          parent: parentNav.id,
          id: `${parentNav.id}-head-arrow`
        }}
        width={vertical ? width : Math.max(120, headPeek - spacing)}
        height={vertical ? Math.max(120, headPeek - spacing) : height}
      />
      <FocusNavigationArrow
        left={horizontal && rtl}
        right={horizontal && !rtl}
        down={vertical}
        theme={componentTheme}
        enabled={isArrowEnabled('tail')}
        onClick={() => page(1)}
        nav={{
          parent: parentNav.id,
          id: `${parentNav.id}-tail-arrow`
        }}
        width={vertical ? width : Math.max(120, tailPeek - spacing)}
        height={vertical ? Math.max(120, tailPeek - spacing) : height}
      />
    </FocusDiv>
  );
};

const ContentGridForward = React.forwardRef(ContentGrid);

ContentGridForward.propTypes = {
  /**
   * When alignGrid is used, values for padding and peek
   * will be calculated so grid items are always placed
   * in the same positions when the grid is shifted.
   * set this to false to use the peek and padding values
   * provided.
   */
  alignGrid: PropTypes.bool,
  /**
   * when this flag is true it is expected that the grid is using css
   * transitions to animate shifting.
   * theme.animatedGrid class is added when animations should be
   * applied, and the grid will expect transitionend event to be
   * triggered when transition has completed.
   */
  animated: PropTypes.bool,
  /**
   * Buffer is the number of extra item spans to render outside the grid.
   * If there is no padding then this is needed so there is always
   * at least one item available that can gain focus
   */
  buffer: PropTypes.number,
  className: PropTypes.string,
  /** offset along the direction of the grid where to start render items */
  crossOffset: PropTypes.number,
  /** Static data array */
  data: PropTypes.array,
  /** directionallity from locale */
  dir: PropTypes.oneOf(['rtl', 'ltr']),
  /** Component used to render each item */
  DisplayComponent: PropTypes.func.isRequired,
  /** arbitrary props object to include when rendering each item defined in DisplayComponent */
  displayComponentProps: PropTypes.object,
  /** Datasource fetcher object. Should include getData, getTotalItems, isPaginationAllowed and hasData */
  ds: PropTypes.shape({
    /** Number of items to fetch. Default is 20 */
    pageSize: PropTypes.number,
    /**
     * Number of items defined as limit before doing another request to prefetch data
     * (ie: we fetch 20 items, define fetchItemLimit to 10, so when the finalIndex
     * is greater than the lenght - limit we do another request)
     */
    fetchItemLimit: PropTypes.number,
    hasData: PropTypes.func.isRequired,
    isPaginationAllowed: PropTypes.func.isRequired,
    getTotalNumber: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired
  }),
  /**
   * Padding values are used to position items away from grid edge.
   * Additional items will still render inside the padding area, to provide context when navigating.
   */
  headPadding: PropTypes.number,
  /**
   * Peek values represent the size of the area where items are rendered when grid has been shifted.
   * If headPadding is 32 and headPeek is 64, this means that the first item will be 32
   */
  headPeek: PropTypes.number,
  /** Used for internal calculations but may not reflect the actual size of the element. */
  height: PropTypes.number,
  /**
   * whether orientation should be horizontal (default is vertical) or not
   */
  horizontal: PropTypes.bool,
  /** initialState.position will decide what items are rendered */
  initialState: PropTypes.shape({
    id: PropTypes.string,
    position: PropTypes.number
  }),
  /**
   * Height of single items
   */
  itemHeight: PropTypes.number.isRequired,
  /**
   * Width of single items
   */
  itemWidth: PropTypes.number.isRequired,
  /**
   * property name of data object that represents a unique data item. often "id" for data.id.
   * used for key in react loop to indicate to react what the item in the loop is.
   */
  keyProperty: PropTypes.string.isRequired,
  /**
   * jump to opposing edge when trying to navigate beyond edge
   */
  loop: PropTypes.bool,
  /** nav object from @accedo/vdkweb-navigation */
  nav: PropTypes.object,
  /** will trigger when grid position or focus changes */
  onChange: PropTypes.func,
  /** onClick is added to each grid item. Will be executed when the user interact with the item. */
  onClick: PropTypes.func,
  /** onClick is added to each grid item, Will be executed when the user place the focus in the item. */
  onFocus: PropTypes.func,
  /**
   * repeat items, endless grid
   * reserved for future use
   */
  repeat: PropTypes.bool,
  /**
   * whether dots needs to be shown or not
   */
  showDots: PropTypes.bool,
  /** Distance between each rendered item (in pixels) */
  spacing: PropTypes.number,
  /** Arbitrary css style object to apply to dom element */
  style: PropTypes.object,
  /**
   * Padding values are used to position items away from grid edge.
   * Additional items will still render inside the padding area, to provide context when navigating.
   *
   * Use this prop if you want to change from which item to scroll. This is defined in Pixels for both carousels and Grids
   */
  tailPadding: PropTypes.number,
  /**
   * Peek values represent the size of the area where items are rendered when grid has been shifted.
   * If headPadding is 32 and headPeek is 64, this means that the first item will be 32.
   */
  tailPeek: PropTypes.number,
  /** theme object from css module */
  theme: PropTypes.object,
  /**  whether to handle pointer arrows internally */
  useInternalArrows: PropTypes.bool,
  /**
   * whether orientation should be vertical (default) or not
   */
  vertical: PropTypes.bool,
  /**
   * width is not the width of the container element, but the width inside the container where items can render.
   * width and height are used for internal calculations but may not reflect the actual size of the element.
   * width and height also needs to be defined in theme/style to set the correct size of the grid.
   */
  width: PropTypes.number.isRequired
};

ContentGrid.propTypes = ContentGridForward.propTypes;

export default ContentGridForward;
