import { TYPE, LEAGUES } from '#/config/constants';

const LiveData = rawData => {
  const {
    id,
    title,
    description,
    time,
    image,
    url,
    homeTeam,
    homeScore,
    awayTeam,
    awayScore
  } = rawData;

  const getId = () => {
    return id?.toString();
  };

  const getTitle = () => {
    return title;
  };

  const getDescription = () => {
    return description;
  };

  const getTime = () => {
    return time;
  };

  const getImage = () => {
    return image;
  };

  const getProgress = () => {
    return Math.floor(Math.random() * (100 - 50 + 1)) + 50;
  };

  const getVideoUrl = () => {
    return url;
  };

  const getHomeTeamInfo = () => {
    return {
      name: homeTeam.Key,
      primaryColor: homeTeam.PrimaryColor,
      score: homeScore,
      logo: homeTeam.logo
    };
  };

  const getAwayTeamInfo = () => {
    return {
      name: awayTeam.Key,
      primaryColor: awayTeam.PrimaryColor,
      score: awayScore,
      logo: awayTeam.logo
    };
  };

  return {
    id: getId(),
    title: getTitle(),
    description: getDescription(),
    time: getTime(),
    image: getImage(),
    videoUrl: getVideoUrl(),
    progress: getProgress(),
    type: TYPE.LIVE,
    league: LEAGUES.NBA,
    homeTeam: getHomeTeamInfo(),
    awayTeam: getAwayTeamInfo()
  };
};

export default LiveData;
