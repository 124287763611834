import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withFocus } from '@accedo/vdkweb-navigation';

import MerchandiseCard from './AssetCard/Merchandise/Merchandise';
import GameCard from './AssetCard/Game/Game';
import PlayerCard from './AssetCard/Player/Player';
import TeamsCard from './AssetCard/Team/Team';
import WatchCard from './AssetCard/Watch/Watch';
import LiveCard from './AssetCard/Live/Live';

import assetItemTheme from './assetItem.scss';

const OrigAssetItem = ({
  containerStyle,
  data,
  displayType,
  height,
  nav,
  theme,
  width
}) => {
  // const backgroundFocused =
  //   data?.league === LEAGUES.NBA
  //     ? theme.backgroundFocused
  //     : theme.mlbBackgroundFocused;

  const wrapperStyle = {
    ...containerStyle,
    ...(width ? { width } : {}),
    ...(height ? 'auto' : {}),
    ...(displayType ? { display: displayType } : { display: 'inline-block' })
  };

  const wrapperClasses = classNames(theme.wrapper, {
    [theme.wrapperFocused]: data.type !== 'catchup' && nav.isFocused,
    [theme.roundedFocus]: data.type === 'team' && nav.isFocused
  });

  return (
    <div style={wrapperStyle} className={wrapperClasses}>
      <div
        onMouseOver={nav.onMouseOver}
        onMouseOut={nav.onMouseOut}
        style={{ height: '100%' }}
      >
        {data.type === 'catchup' && (
          <WatchCard data={data} isFocused={nav.isFocused} />
        )}
        {data.type === 'live' && <LiveCard data={data} />}
        {data.type === 'game' && <GameCard data={data} theme={theme} />}
        {data.type === 'player' && <PlayerCard data={data} />}
        {data.type === 'merchandise' && <MerchandiseCard data={data} />}
        {data.type === 'team' && <TeamsCard data={data} nav={nav} />}
      </div>
    </div>
  );
};

OrigAssetItem.propTypes = {
  containerStyle: PropTypes.object,
  data: PropTypes.object.isRequired,
  displayType: PropTypes.string,
  height: PropTypes.any,
  nav: PropTypes.object,
  theme: PropTypes.object,
  type: PropTypes.string,
  width: PropTypes.any
};

OrigAssetItem.defaultProps = {
  theme: assetItemTheme,
  nav: {}
};

const AssetItem = withFocus(OrigAssetItem);

// istanbul ignore else
if (__TEST__) {
  AssetItem._test = {
    OrigAssetItem
  };
}

export default AssetItem;
