import React, {
  useState,
  createContext,
  useMemo,
  useCallback,
  ReactNode
} from 'react';
import PropTypes from 'prop-types';
import { Profile } from '#/interfaces/Profile';
import { DeviceStorageKey } from '#/interfaces/DeviceStorage';
import {
  get as getFromDeviceStorage,
  set as setIntoDeviceStorage,
  clear as clearFromDeviceStorage
} from '../services/deviceStorage';

export interface ProfileContextValues {
  currentProfile: Profile | null;
  currentProfileLoading: boolean;
  loadCurrentProfile: () => Promise<void>;
  setCurrentProfile: (profile: Profile) => void;
  clearCurrentProfile: () => void;
}

export const ProfileContext = createContext<ProfileContextValues>({
  currentProfile: null,
  currentProfileLoading: false,
  loadCurrentProfile: () => {
    throw new Error('Function not implemented.');
  },
  setCurrentProfile: () => {
    throw new Error('Function not implemented.');
  },
  clearCurrentProfile: () => {
    throw new Error('Function not implemented.');
  }
});

interface ProfilesProviderProps {
  children?: ReactNode;
}

const ProfilesProvider = ({ children }: ProfilesProviderProps) => {
  const [currentProfile, setCurrentProfile] = useState<Profile | null>(null);
  const [currentProfileLoading, setCurrentProfileLoading] = useState(true);

  const getProfileFromDeviceStorage = useCallback(
    () => getFromDeviceStorage<Profile>(DeviceStorageKey.CURRENT_PROFILE),
    []
  );

  const setProfileIntoDeviceStorage = useCallback(
    (profile: Profile) =>
      setIntoDeviceStorage<Profile>(DeviceStorageKey.CURRENT_PROFILE, profile),
    []
  );

  const clearProfileFromDeviceStorage = useCallback(
    () => clearFromDeviceStorage(DeviceStorageKey.CURRENT_PROFILE),
    []
  );

  const clearCurrentProfile = useCallback((): void => {
    clearProfileFromDeviceStorage();
    setCurrentProfile(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCurrentProfile = useCallback(async () => {
    const response = await getProfileFromDeviceStorage();

    if (response) {
      setCurrentProfile(response);
    }

    setCurrentProfileLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetCurrentProfile = useCallback(profile => {
    setCurrentProfile(profile);
    setProfileIntoDeviceStorage(profile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = useMemo<ProfileContextValues>(
    () => ({
      currentProfile,
      currentProfileLoading,
      loadCurrentProfile,
      setCurrentProfile: handleSetCurrentProfile,
      clearCurrentProfile
    }),
    [
      currentProfile,
      currentProfileLoading,
      loadCurrentProfile,
      handleSetCurrentProfile,
      clearCurrentProfile
    ]
  );

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ProfileContext.Provider value={contextValue}>
      {children}
    </ProfileContext.Provider>
  );
};

ProfilesProvider.propTypes = {
  children: PropTypes.node
};

export default ProfilesProvider;
