const Referee = rawReferee => {
  const { RefereeID, Name, Number, College, Experience } = rawReferee;

  const getId = () => {
    return RefereeID.toString();
  };

  const getName = () => {
    return Name;
  };

  const getNumber = () => {
    return Number;
  };

  const getCollege = () => {
    return College;
  };

  const getExperience = () => {
    return Experience;
  };

  return {
    id: getId(),
    name: getName(),
    number: getNumber(),
    college: getCollege(),
    experience: getExperience()
  };
};

export default Referee;
