import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FocusHeroBanner } from '@accedo/vdkweb-tv-ui';
import { focusManager } from '@accedo/vdkweb-navigation';
import useKeyHandler from '#/hooks/useKeyHandler';
import { navIdMap } from '#/utils/navigationHelper';

import HeroItem from '#/components/Item/HeroItem/HeroItem';
import heroBg1 from '#/static/images/hero/hero-1.png';
import heroBg2 from '#/static/images/hero/hero-2.png';
import heroBg3 from '#/static/images/hero/hero-3.png';
import useI18n from '#/hooks/useI18n';

import useOnScreen from '#/hooks/useOnScreen';

import './heroCarousel.css';

const carouselConfiguration = {
  autoplaySpeed: 3000,
  fade: true,
  infinite: true,
  pauseOnFocus: false
};

const heroBg = [heroBg1, heroBg2, heroBg3];
const NAV_TOP_MENU_ID = navIdMap.MENU.HEADER.CONTAINER;

const HeroShelf = ({ onClick, items, config = {}, nav = {}, ds }) => {
  const heroBannerRef = useRef(null);
  const isOnScreen = useOnScreen(heroBannerRef);
  const [carouselConfig, setCarouselConfig] = useState(carouselConfiguration);

  const [data, setData] = useState([]);
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;

  const keyHandler = ({ id }, event) => {
    const childFocus = focusManager.isChildFocused(nav.id);
    if (id === 'device:vkey:left' && childFocus) {
      event.stopImmediatePropagation();
      focusManager.changeFocus(NAV_TOP_MENU_ID);
    }
  };

  useEffect(() => {
    ds.getData(0, 1).then(res => {
      if (res.data) {
        res.data.forEach((item, index) => {
          item.image = heroBg[index % 3];
        });
        setData(res.data);
      }
    });
  }, [ds]);

  useKeyHandler(keyHandler);

  const setAutoScroll = () => {
    setCarouselConfig({
      ...carouselConfig,
      autoplay: isOnScreen
    });
  };

  useEffect(() => {
    if (isOnScreen) {
      focusManager.changeFocus(nav.id);
    }
    setAutoScroll();
  }, [isOnScreen]);

  return (
    <div ref={heroBannerRef}>
      <FocusHeroBanner
        items={data}
        keyProperty="id"
        nav={nav}
        options={{ ...carouselConfig }}
        displayObject={<HeroItem onClick={onClick} />}
      />
    </div>
  );
};

HeroShelf.propTypes = {
  onClick: PropTypes.func,
  ds: PropTypes.object.isRequired,
  items: PropTypes.array,
  config: PropTypes.object,
  nav: PropTypes.object
};

export default HeroShelf;
