import { getHeroDate, getTileDate, formatTime } from '#/utils/dateUtils';
import { TYPE, DUMMY_VIDEO_URL, LEAGUES } from '#/config/constants';

const MlbGame = rawGame => {
  const {
    game: { id, status, scheduled, away, home, venue, attendance }
  } = rawGame;

  const getId = () => {
    return id;
  };

  const getStatus = () => {
    return status;
  };

  const getFullDate = () => {
    return getHeroDate(scheduled);
  };

  const getShortDate = () => {
    return getTileDate(scheduled);
  };

  const getAwayTeam = () => {
    return away?.abbr;
  };

  const getAwayTeamId = () => {
    return away?.id;
  };

  const getHomeTeam = () => {
    return home?.abbr;
  };

  const getHomeTeamId = () => {
    return home?.id;
  };

  const getStadiumName = () => {
    return venue?.name;
  };

  const getStadiumCity = () => {
    return venue?.city;
  };

  // const getStadiumId = () => {
  //   return StadiumID;
  // };

  // const getChannel = () => {
  //   return Channel;
  // };

  const getAwayTeamScore = () => {
    return away?.hits;
  };

  const getHomeTeamScore = () => {
    return home?.hits;
  };

  const getHomeTeamName = () => {
    return home?.name;
  };

  const getAwayTeamName = () => {
    return away?.name;
  };

  const getHomeTeamCity = () => {
    return home?.market;
  };

  const getAwayTeamCity = () => {
    return away?.market;
  };

  const getVideoUrl = () => {
    return rawGame?.demoStream;
  };

  const getAttendance = () => {
    return attendance || 0;
  };

  const getCapacity = () => {
    return venue?.capacity;
  };

  const getAwayTeamWins = () => {
    return away?.win;
  };

  const getAwayTeamLoss = () => {
    return away?.loss;
  };

  const getHomeTeamWins = () => {
    return home?.win;
  };

  const getHomeTeamLoss = () => {
    return home?.loss;
  };

  const getTime = () => {
    return formatTime(new Date(scheduled), true);
  };

  return {
    id: getId(),
    status: getStatus(),
    awayTeam: getAwayTeam(),
    awayTeamId: getAwayTeamId(),
    awayTeamScore: getAwayTeamScore(),
    homeTeam: getHomeTeam(),
    homeTeamId: getHomeTeamId(),
    homeTeamScore: getHomeTeamScore(),
    homeTeamName: getHomeTeamName(),
    awayTeamName: getAwayTeamName(),
    shortDate: getShortDate(),
    fullDate: getFullDate(),
    homeCity: getHomeTeamCity(),
    awayCity: getAwayTeamCity(),
    stadiumName: getStadiumName(),
    stadiumCity: getStadiumCity(),
    type: TYPE.GAME,
    videoUrl: getVideoUrl() || DUMMY_VIDEO_URL,
    league: LEAGUES.MLB,
    attendance: getAttendance(),
    capacity: getCapacity(),
    awayTeamWins: getAwayTeamWins(),
    awayTeamLoss: getAwayTeamLoss(),
    homeTeamWins: getHomeTeamWins(),
    homeTeamLoss: getHomeTeamLoss(),
    time: getTime()
  };
};

export default MlbGame;
