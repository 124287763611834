/**
 * @module services/collection
 * @description
 * Service to handle the fetch of collections
 */
import { PROVIDER_TYPE } from '#/config/constants';
import ovp from '#/providers/ovp/accedo';
import { getSegmentationCachePart } from '#/utils/segmentationUtils';
import { Collection } from '#/interfaces/Collection';
import { CollectionModelBuilder } from '#/models/collection/Collection';

import fetcher from '#/services/helpers/fetcher';

/**
 * Returns a collection
 * @param {string} collectionId the identifier of the Collection
 * @param {string} [segmentationValue] optional segment to get a different config
 * @returns {Collection} collection
 */
export const getCollection = async (
  collectionId: string,
  segmentationValue?: string
): Promise<Collection> => {
  const segmentationPart = getSegmentationCachePart(segmentationValue);

  const collection = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-collectionId-${collectionId}${segmentationPart}`,
    fetchFn: () => ovp.getCollection(collectionId, segmentationValue)
  });

  const collectionModelBuilder = new CollectionModelBuilder(collection);

  return collectionModelBuilder.create();
};
