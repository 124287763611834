import { TYPE, LEAGUES } from '#/config/constants';
import { TeamsMockData as data } from '#/providers/ovp/sports/nbaTeamsMockData';

const Player = rawPlayer => {
  const {
    PlayerID,
    TeamID,
    Jersey,
    PositionCategory,
    Position,
    FirstName,
    LastName,
    GlobalTeamID,
    image
  } = rawPlayer;

  const getId = () => {
    return PlayerID?.toString();
  };

  const getTeamId = () => {
    return TeamID;
  };

  const getTeam = () => {
    return {
      teamLogo: data.find(
        team => team.GlobalTeamID === GlobalTeamID || team.TeamID === TeamID
      ).logo
    };
  };

  const getJersey = () => {
    return Jersey;
  };

  const getPositionCategory = () => {
    return PositionCategory;
  };

  const getPosition = () => {
    return Position;
  };

  const getFirstName = () => {
    return FirstName;
  };

  const getLastName = () => {
    return LastName;
  };

  const getPhotoUrl = () => {
    return image;
  };

  return {
    id: getId(),
    teamId: getTeamId(),
    team: getTeam(),
    jersey: getJersey(),
    positionCategory: getPositionCategory(),
    position: getPosition(),
    firstName: getFirstName(),
    lastName: getLastName(),
    photoUrl: getPhotoUrl(),
    type: TYPE.PLAYER,
    league: LEAGUES.NBA
  };
};

export default Player;
