// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import { TYPE, LEAGUES } from '#/config/constants';

const MlbPlayer = rawPlayer => {
  const {
    id,
    first_name,
    last_name,
    jersey_number,
    status,
    position
  } = rawPlayer;

  const getId = () => {
    return id;
  };

  const getStatus = () => {
    return status;
  };

  const getJersey = () => {
    return jersey_number;
  };

  const getPosition = () => {
    return position;
  };

  const getName = () => {
    return `${first_name} ${last_name}`;
  };

  const getFirstname = () => {
    return first_name;
  };

  const getLastname = () => {
    return last_name;
  };

  return {
    id: getId(),
    status: getStatus(),
    jersey: getJersey(),
    position: getPosition(),
    name: getName(),
    firstname: getFirstname(),
    lastname: getLastname(),
    type: TYPE.PLAYER,
    league: LEAGUES.MLB
  };
};

export default MlbPlayer;
