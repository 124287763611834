export const playersData = [
  {
    PlayerID: 20000485,
    TeamID: 26,
    Team: 'GS',
    Jersey: 30,
    PositionCategory: 'G',
    Position: 'PG',
    FirstName: 'Stephen',
    LastName: 'Curry',
    BirthDate: '1988-03-14T00:00:00',
    BirthCity: 'Akron',
    BirthState: 'OH',
    BirthCountry: 'USA',
    GlobalTeamID: 20000026,
    Weight: 75,
    Height: 200,
    image:
      'https://upload.wikimedia.org/wikipedia/commons/3/36/Stephen_Curry_dribbling_2016_%28cropped%29.jpg'
  },
  {
    PlayerID: 20000571,
    TeamID: 27,
    Team: 'LAL',
    Jersey: 23,
    PositionCategory: 'F',
    Position: 'SF',
    FirstName: 'LeBron',
    LastName: 'James',
    BirthDate: '1984-12-30T00:00:00',
    BirthCity: 'Akron',
    BirthState: 'OH',
    BirthCountry: 'USA',
    GlobalTeamID: 20000027,
    Weight: 81,
    Height: 250,
    image:
      'https://upload.wikimedia.org/wikipedia/commons/7/7a/LeBron_James_%2851959977144%29_%28cropped2%29.jpg'
  },
  {
    PlayerID: 20000574,
    TeamID: 25,
    Team: 'DAL',
    Jersey: 11,
    PositionCategory: 'G',
    Position: 'PG',
    FirstName: 'Kyrie',
    LastName: 'Irving',
    BirthDate: '1992-03-23T00:00:00',
    BirthCity: 'Melbourne',
    BirthState: null,
    BirthCountry: 'Australia',
    GlobalTeamID: 20000025,
    Height: 74,
    Weight: 195,
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Kyrie_Irving_-_51831772061_01.jpg/640px-Kyrie_Irving_-_51831772061_01.jpg'
  },
  {
    PlayerID: 20000726,
    TeamID: 7,
    Team: 'PHI',
    Jersey: 21,
    PositionCategory: 'C',
    Position: 'C',
    FirstName: 'Joel',
    LastName: 'Embiid',
    BirthDate: '1994-03-16T00:00:00',
    BirthCity: 'Yaounde',
    BirthState: null,
    BirthCountry: 'Cameroon',
    GlobalTeamID: 20000007,
    Height: 84,
    Weight: 280,
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Joel_Embiid_2019.jpg/640px-Joel_Embiid_2019.jpg'
  },
  {
    PlayerID: 20001984,
    TeamID: 25,
    Team: 'DAL',
    Jersey: 77,
    PositionCategory: 'G',
    Position: 'PG',
    FirstName: 'Luka',
    LastName: 'Doncic',
    BirthDate: '1999-02-28T00:00:00',
    BirthCity: 'Ljubljana',
    BirthState: null,
    BirthCountry: 'Slovenia',
    GlobalTeamID: 20000025,
    Height: 79,
    Weight: 230,
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Luka_Don%C4%8Di%C4%87_2021.jpg/640px-Luka_Don%C4%8Di%C4%87_2021.jpg'
  },
  {
    PlayerID: 20000497,
    TeamID: 15,
    Team: 'MIL',
    Jersey: 34,
    PositionCategory: 'F',
    Position: 'PF',
    FirstName: 'Giannis',
    LastName: 'Antetokounmpo',
    BirthDate: '1994-12-06T00:00:00',
    BirthCity: 'Athens',
    BirthState: null,
    BirthCountry: 'Greece',
    GlobalTeamID: 20000015,
    Height: 84,
    Weight: 254,
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/Giannis_Antetokounmpo_%2851664127127%29_%28cropped%29.jpg/640px-Giannis_Antetokounmpo_%2851664127127%29_%28cropped%29.jpg'
  },
  {
    PlayerID: 20001441,
    TeamID: 20,
    Team: 'DEN',
    Jersey: 15,
    PositionCategory: 'C',
    Position: 'C',
    FirstName: 'Nikola',
    LastName: 'Jokic',
    BirthDate: '1995-02-19T00:00:00',
    BirthCity: 'Sombor',
    BirthState: null,
    BirthCountry: 'Serbia',
    GlobalTeamID: 20000020,
    Height: 84,
    Weight: 284,
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Nikola_Jokic_free_throw_%28cropped%29.jpg/640px-Nikola_Jokic_free_throw_%28cropped%29.jpg'
  },
  {
    PlayerID: 20001829,
    TeamID: 9,
    Team: 'BOS',
    Jersey: 0,
    PositionCategory: 'F',
    Position: 'SF',
    FirstName: 'Jayson',
    LastName: 'Tatum',
    BirthDate: '1998-03-03T00:00:00',
    BirthCity: 'St. Louis',
    BirthState: 'MO',
    BirthCountry: 'USA',
    GlobalTeamID: 20000009,
    Height: 80,
    Weight: 210,
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/Jayson_Tatum_%2851688503300%29_%28cropped%29.jpg/640px-Jayson_Tatum_%2851688503300%29_%28cropped%29.jpg'
  },
  {
    PlayerID: 20000884,
    TeamID: 29,
    Team: 'PHO',
    Jersey: 35,
    PositionCategory: 'F',
    Position: 'PF',
    FirstName: 'Kevin',
    LastName: 'Durant',
    BirthDate: '1988-09-29T00:00:00',
    BirthCity: 'Washington',
    BirthState: 'D.C.',
    BirthCountry: 'USA',
    GlobalTeamID: 20000029,
    Height: 82,
    Weight: 240,
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Kevin_Durant_%28Wizards_v._Warriors%2C_1-24-2019%29_%28cropped%29.jpg/640px-Kevin_Durant_%28Wizards_v._Warriors%2C_1-24-2019%29_%28cropped%29.jpg'
  },
  {
    PlayerID: 20001999,
    TeamID: 18,
    Team: 'OKC',
    Jersey: 2,
    PositionCategory: 'G',
    Position: 'PG',
    FirstName: 'Shai',
    LastName: 'Gilgeous-Alexander',
    BirthDate: '1998-07-12T00:00:00',
    BirthCity: 'Toronto',
    BirthState: 'ON',
    BirthCountry: 'Canada',
    GlobalTeamID: 20000018,
    Height: 78,
    Weight: 200,
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8c/2023-08-09_Deutschland_gegen_Kanada_%28Basketball-L%C3%A4nderspiel%29_by_Sandro_Halank%E2%80%93109.jpg/640px-2023-08-09_Deutschland_gegen_Kanada_%28Basketball-L%C3%A4nderspiel%29_by_Sandro_Halank%E2%80%93109.jpg'
  },
  {
    PlayerID: 20000468,
    TeamID: 27,
    Team: 'LAL',
    Jersey: 3,
    PositionCategory: 'C',
    Position: 'C',
    FirstName: 'Anthony',
    LastName: 'Davis',
    BirthDate: '1993-03-11T00:00:00',
    BirthCity: 'Chicago',
    BirthState: 'IL',
    BirthCountry: 'USA',
    GlobalTeamID: 20000027,
    Height: 82,
    Weight: 253,
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/Anthony_Davis_pre-game_%28cropped%29.jpg/640px-Anthony_Davis_pre-game_%28cropped%29.jpg'
  }
];
