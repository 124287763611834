import dayjs from 'dayjs';

const weekday = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const weekdayKeys = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const monthKeys = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC'
];

// Formatters
export const formatTime = (dateString, withTime = false) => {
  let strTime;
  let hours = dateString.getHours();
  let minutes = dateString.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours %= 12;
  hours = hours || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  if (withTime) {
    strTime = `${hours}:${minutes} ${ampm}`;
  } else {
    strTime = `${hours}:${minutes}`;
  }
  return strTime;
};

export const formatTimezone = dateString => {
  const zone = dateString
    .toLocaleTimeString('en-us', { timeZoneName: 'short' })
    .split(' ')[2];
  return zone;
};

export const formatTimeAndZone = dateString => {
  const hours = dateString.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const zone = formatTimezone(dateString);

  return `${ampm} ${zone}`;
};

export const getDayOfWeek = dateString => {
  const date = new Date(dateString);
  const day = weekday[date.getDay()];

  return day;
};

export const getDayOfWeekKey = dateString => {
  const date = new Date(dateString);
  const day = weekdayKeys[date.getDay()];

  return day;
};

export const isToday = dateString => {
  const date = new Date(dateString);
  const today = new Date();

  if (today.toDateString() === date.toDateString()) {
    return true;
  }

  return false;
};

export const isTomorrow = dateString => {
  const date = new Date(dateString);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  if (tomorrow.toDateString() === date.toDateString()) {
    return true;
  }

  return false;
};

// To be used on API calls as a parameter
export const getDateParam = dateString => {
  const date = new Date(dateString);
  const fullYear = date.getFullYear();
  const month =
    // getMonth returns zero-based value for month
    date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const dayOfMonth =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

  return `${fullYear}-${month}-${dayOfMonth}`;
};

// To be used on tiles
export const getTileDate = dateString => {
  if (isToday(dateString)) {
    return 'TODAY';
  }

  if (isTomorrow(dateString)) {
    return 'TOMORROW';
  }

  const date = new Date(dateString);
  const month = monthKeys[date.getMonth()];
  const dayOfWeek = getDayOfWeekKey(dateString);
  const dayOfMonth = date.getDate();

  return `${dayOfWeek}. ${month} ${dayOfMonth}`;
};

// To be used on the hero banner
export const getHeroDate = dateString => {
  const date = new Date(dateString);
  const dayOfWeek = getDayOfWeek(dateString);
  const month = months[date.getMonth()];
  const dayOfMonth = date.getDate();
  const time = formatTime(date, true);
  const zone = formatTimezone(date);

  return `${dayOfWeek} ${month} ${dayOfMonth}, ${time} ${zone}`;
};

// To be used on the date switcher component
export const getScheduleDate = dateString => {
  const date = new Date(dateString);
  const month = months[date.getMonth()];
  const dayOfMonth = date.getDate();
  const fullYear = date.getFullYear();
  return `${month} ${dayOfMonth}, ${fullYear}`;
};

// To get player's age
export const getPlayerAge = dateString => {
  const diffMs = Date.now() - dateString.getTime();
  const ageDt = new Date(diffMs);

  return Math.abs(ageDt.getUTCFullYear() - 1970);
};

// To get player's birthdate
export const getBirthDate = dateString => {
  const date = new Date(dateString);
  const month = monthKeys[date.getMonth()];
  const dayOfMonth = date.getDate();
  const fullYear = date.getFullYear();

  return `${month} ${dayOfMonth} ${fullYear}`;
};

export const formatDate = date => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  const newDate = `${year}/${month}/${day}`;
  return newDate;
};

// Schedule calendar view utilities

const firstWeekCheck = arr => {
  const first7days = arr.slice(0, 7);
  let n = 0;
  let removeFirstWeek = true;
  while (n < first7days.length) {
    if (first7days[n].currentMonth) {
      removeFirstWeek = false;
    }
    n += 1;
  }
  return removeFirstWeek;
};

const lastWeekCheck = arr => {
  const last7days = arr.slice(arr.length - 7, arr.length);
  let n = 0;
  let removeLastWeek = true;
  while (n < last7days.length) {
    if (last7days[n].currentMonth) {
      removeLastWeek = false;
    }
    n += 1;
  }
  return removeLastWeek;
};

const getMonthDays = (currentDay, currentMonth, scheduleData) => {
  const firstDayOfMonth = new Date(currentDay.getFullYear(), currentMonth, 1);
  const weekdayOfFirstDay = firstDayOfMonth.getDay();
  const currentDays = [];

  for (let day = 0; day < 42; day += 1) {
    if (day === 0 && weekdayOfFirstDay === 0) {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 7);
    } else if (day === 0) {
      firstDayOfMonth.setDate(
        firstDayOfMonth.getDate() + (day - weekdayOfFirstDay)
      );
    } else {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
    }

    const number = firstDayOfMonth.getDate();
    const year = firstDayOfMonth.getFullYear();
    const month = firstDayOfMonth.getMonth();

    const isToday =
      new Date(year, month, number).toDateString() ===
      new Date().toDateString();

    const currentDate = dayjs(new Date(year, month, number));

    const calendarDay = {
      currentMonth: firstDayOfMonth.getMonth() === currentMonth,
      date: new Date(firstDayOfMonth),
      month: firstDayOfMonth.getMonth(),
      number,
      isToday,
      year: firstDayOfMonth.getFullYear(),
      gameData: (() => {
        const gameItem = scheduleData.filter(game => {
          if (
            dayjs(game.Day).format('MM-DD-YYYY') ===
            currentDate.format('MM-DD-YYYY')
          ) {
            return game;
          }
          return false;
        });

        return gameItem;
        //   scheduleData[Math.floor(Math.random() * scheduleData.length)];
        // if (isToday) {
        //   return [gameItem];
        // }

        // if (number % 5 === 0) {
        //   return [gameItem];
        // }
      })()
    };

    currentDays.push(calendarDay);
  }

  return currentDays;
};

export { weekdayKeys, months, firstWeekCheck, lastWeekCheck, getMonthDays };
