const BoxScore = rawPlayerGame => {
  const {
    Name,
    Assists,
    Steals,
    BlockedShots,
    Turnovers,
    PersonalFouls,
    Points,
    PlayerID,
    TeamID,
    photoUrl
  } = rawPlayerGame;

  const getId = () => {
    return PlayerID;
  };

  const getName = () => {
    return Name;
  };

  const getPhotoUrl = () => {
    return photoUrl;
  };

  const getAssists = () => {
    return Assists;
  };

  const getBlockedShots = () => {
    return BlockedShots;
  };

  const getTurnovers = () => {
    return Turnovers;
  };

  const getFouls = () => {
    return PersonalFouls;
  };

  const getPoints = () => {
    return Points;
  };

  const getSteals = () => {
    return Steals;
  };

  const getTeamId = () => {
    return TeamID;
  };

  return {
    player: getName(),
    photoUrl: getPhotoUrl(),
    id: getId(),
    teamId: getTeamId(),
    stats: [
      {
        name: 'Assists',
        value: getAssists()
      },
      {
        name: 'Steals',
        value: getSteals()
      },
      {
        name: 'Blocked Shots',
        value: getBlockedShots()
      },
      {
        name: 'Turnovers',
        value: getTurnovers()
      },
      {
        name: 'Fouls',
        value: getFouls()
      },
      {
        name: 'Points',
        value: getPoints()
      }
    ]
  };
};

export default BoxScore;
