/**
 * @module services/sports/nba
 * @description
 * NBA SportsLeague Service
 */
import { NBA_ENDPOINTS, PROVIDER_TYPE } from '#/config/constants';

import ovp from '#/providers/ovp';
import Game from '#/models/nba/game/game';
import Player from '#/models/nba/player/player';
import Referee from '#/models/nba/referee/referee';
import Team from '#/models/nba/team/team';
import BoxScore from '#/models/nba/boxScore/boxScore';
import Merchandise from '#/models/nba/merchandise/merchandise';
import LiveData from '#/models/nba/liveData/liveData';
import CatchupData from '#/models/nba/catchup/catchupData';

import { getDateParam } from '#/utils/dateUtils';
import fetcher from '../../helpers/fetcher';

const getTeamById = (teams, teamId) => {
  return teams.find(t => t.TeamID === teamId);
};

// To populate Upcoming, Final and Daily games
// swimlanes, the query params comes from Control
// e.g. /GamesByDate/{{upcoming}}
const getDateFromQueryParam = param => {
  const parameter = param.toString();
  const date = new Date();

  const tomorrow = new Date().setDate(date.getDate() + 1);
  const yesterday = new Date().setDate(date.getDate() - 1);

  if (parameter === 'final') {
    return getDateParam(yesterday);
  }
  if (parameter === 'upcoming') {
    return getDateParam(tomorrow);
  }
  return getDateParam(date);
};

const logError = error => {
  console.error(error);
};

/* NBA listing services */

const getAllTeams = async (parse = true) => {
  const rawTeams = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-nba-all-teams`,
    fetchFn: () => ovp.getNbaAllTeams()
  }).catch(logError);

  if (parse) {
    return rawTeams?.map(team => Team(team));
  }

  return rawTeams;
};

const getGameBySchedule = async (params, date) => {
  const rawGames = await fetcher({
    // cacheId: `${PROVIDER_TYPE.ovp}-nba-games-by-date-${date}`,
    fetchFn: () => ovp.getNbaGamesSchedule(params, date)
  }).catch(logError);

  const allTeams = await getAllTeams(false);
  const demoStream = await ovp.getDemoStream();

  return rawGames?.map(game => {
    const homeTeam = getTeamById(allTeams, game.HomeTeamID);
    const awayTeam = getTeamById(allTeams, game.AwayTeamID);
    return Game({
      ...game,
      homeTeam,
      awayTeam,
      demoStream
    });
  });
};

const getGamesByDate = async (date, params) => {
  let rawGames = await fetcher({
    // cacheId: `${PROVIDER_TYPE.ovp}-nba-games-by-date-${date}`,
    fetchFn: () => ovp.getNbaGamesByDate(date, params)
  }).catch(logError);

  if (!rawGames) {
    // Use mock data
    rawGames = require('#/__mocks__/nbaRawGames.json');
  }
  console.debug('>> getGamesByDate AWAIT RAWGAMES', rawGames);

  const allTeams = await getAllTeams(false);
  const demoStream = await ovp.getDemoStream();

  return rawGames?.map(game => {
    const homeTeam = getTeamById(allTeams, game.HomeTeamID);
    const awayTeam = getTeamById(allTeams, game.AwayTeamID);
    return Game({
      ...game,
      homeTeam,
      awayTeam,
      demoStream
    });
  });
};

const getGamesBySeason = async (season, seasonType) => {
  const rawGames = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-nba-games-by-season-${season}`,
    fetchFn: () => ovp.getNbaGamesBySeason(season, seasonType)
  });

  return rawGames?.map(game => Game(game));
};

const getDemoPlayers = async () => {
  const rawPlayers = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-nba-players-by-team-demo`,
    fetchFn: () => ovp.getNbaDemoPlayers()
  });

  return rawPlayers?.map(player => Player(player));
};

const getPlayersByTeam = async team => {
  const rawPlayers = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-nba-players-by-team-${team}`,
    fetchFn: () => ovp.getNbaPlayersByTeam(team)
  });

  return rawPlayers?.map(player => Player(player));
};

const getReferees = async () => {
  const rawReferees = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-nba-referees`,
    fetchFn: () => ovp.getNbaReferees()
  });

  return rawReferees?.map(referee => Referee(referee));
};

const getActiveTeams = async () => {
  const rawTeams = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-nba-active-teams`,
    fetchFn: () => ovp.getNbaActiveTeams()
  }).catch(logError);

  return rawTeams?.map(team => Team(team));
};

const getBoxScoreByGame = async id => {
  const rawPlayerGames = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-nba-box-score-${id}`,
    fetchFn: () => ovp.getNbaBoxScoreByGame(id)
  });

  return rawPlayerGames?.map(team => BoxScore(team));
};

const getAllMerch = async () => {
  const rawData = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-nba-all-merch`,
    fetchFn: () => ovp.getMerchandise()
  }).catch(logError);

  return rawData?.map(merch => Merchandise(merch));
};

const getLiveData = async () => {
  const rawData = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-nba-live`,
    fetchFn: () => ovp.getVideoData()
  }).catch(logError);

  return rawData?.map(item => LiveData(item));
};

const getCatchupData = async () => {
  const rawData = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-nba-catchup`,
    fetchFn: () => ovp.getCatchupData()
  }).catch(logError);

  return rawData?.map(item => CatchupData(item));
};

/**
 * @param {String} query The query for the OVP.
 * @returns {Array} Array of Items
 */

const getItemsByQuery = async ({ query }) => {
  // Match with '{{something}}'
  const params = query?.match(/[^{}]+(?=})/g);
  let items;

  if (query.includes(NBA_ENDPOINTS.gamesByDate)) {
    const date = getDateFromQueryParam(params);
    items = await getGamesByDate(date, params);
  }

  if (query.includes(NBA_ENDPOINTS.activeTeams)) {
    items = await getActiveTeams();
  }

  if (query.includes(NBA_ENDPOINTS.allTeams)) {
    items = await getAllTeams();
  }

  if (query.includes(NBA_ENDPOINTS.playerByTeam)) {
    if (params) {
      items = await getPlayersByTeam(params);
    } else {
      items = await getDemoPlayers();
    }
  }

  if (query === '/merchandise') {
    items = await getAllMerch();
  }

  if (query === '/catch-up') {
    items = await getCatchupData();
  }

  if (query === '/live') {
    items = await getLiveData();
  }

  if (query === '/recommended') {
    items = await getGameBySchedule(query);
  }

  if (query === '/all-games') {
    items = await getGameBySchedule(query);
  }

  if (query === '/completed') {
    items = await getGameBySchedule(query);
  }

  return {
    items: items || [],
    total: items?.length || 0
  };
};

export {
  getGamesByDate,
  getGameBySchedule,
  getGamesBySeason,
  getPlayersByTeam,
  getReferees,
  getAllTeams,
  getActiveTeams,
  getBoxScoreByGame,
  getItemsByQuery,
  getAllMerch
};
