import React from 'react';
import PropTypes from 'prop-types';
import { withFocus } from '@accedo/vdkweb-navigation';
import {
  SettingsIcon,
  SearchIcon,
  HomeIcon,
  ScheduleIcon
} from '#/components/Icons/Icons';
import style from './menuItem.scss';

const icons = {
  home: ({ className }) => <HomeIcon className={className} />,
  calendar: ({ className }) => <ScheduleIcon className={className} />,
  search: ({ className }) => <SearchIcon className={className} />,
  settings: ({ className }) => <SettingsIcon className={className} />
};

const getIcon = ({ label, className }) => {
  return icons[label]
    ? icons[label]({ className: `${className} ${style.icon}` })
    : null;
};

const getIconAndLabel = ({ label, className, text }) => {
  return (
    <>
      {getIcon({ label, className })}
      <span>{text}</span>
    </>
  );
};

/**
 * MenuItem Component
 * @param {Object} props Component Props
 * @param {Function} props.onClick Callback function to dispatch when item clicked
 * @param {Function} props.onFocus Callback function to dispatch when item focused
 * @param {Function} props.onBlur Callback function to dispatch when item blur
 * @param {Object} props.data The content to be displayed
 * @param {Boolean} props.isOpen isMenuOpen expanded or collapsed
 * @returns {React.ReactComponentElement} Component
 */
const MenuItem = ({
  onClick,
  onFocus,
  onBlur,
  data,
  nav,
  isActive,
  isOpen
}) => {
  const { viaIconLabel, image, itemGroup } = data;
  const userImg = image ? (
    <img className={style.profilePicture} src={image} alt="" />
  ) : null;

  const className =
    itemGroup === 'primary'
      ? `${style.item} ${style.primary}`
      : `${style.item} ${style.secondary}`;
  const itemClassName = `${className} ${nav.isFocused ? style.itemFocused : ''}
  ${isOpen ? style.expanded : style.collapsed}`;

  const buttonClass = isActive ? style['is-active'] : '';

  const itemIcon = getIcon({ label: viaIconLabel, className });

  let itemContent = userImg || itemIcon || data.displayText;

  if (isOpen) {
    itemContent = getIconAndLabel({
      label: viaIconLabel,
      className,
      text: data.displayText
    });
  }

  return (
    <div
      className={itemClassName}
      onMouseOver={nav.onMouseOver}
      onMouseOut={nav.onMouseOut}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <button onClick={onClick} className={buttonClass}>
        {itemContent}
        <div className="underline">
          <span />
        </div>
      </button>
    </div>
  );
};

MenuItem.propTypes = {
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  data: PropTypes.object,
  nav: PropTypes.object,
  isActive: PropTypes.bool,
  isOpen: PropTypes.bool
};

export default withFocus(MenuItem);
