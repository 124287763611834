import seven6er from '#/static/images/teams/76.svg';
import warrior from '#/static/images/teams/warrior.svg';
import blaze from '#/static/images/teams/blaze.svg';
import buck from '#/static/images/teams/buck.svg';
import bull from '#/static/images/teams/bull.svg';
import celt from '#/static/images/teams/celt.svg';
import cleve from '#/static/images/teams/cleve.svg';
import clipp from '#/static/images/teams/clipp.svg';
import grizz from '#/static/images/teams/grizz.svg';
import hawk from '#/static/images/teams/hawk.svg';
import heat from '#/static/images/teams/heat.svg';
import horn from '#/static/images/teams/horn.svg';
import jazz from '#/static/images/teams/jazz.svg';
import kings from '#/static/images/teams/kings.svg';
import knick from '#/static/images/teams/knick.svg';
import magic from '#/static/images/teams/magic.svg';
import mav from '#/static/images/teams/mav.svg';
import nets from '#/static/images/teams/nets.svg';
import nuggets from '#/static/images/teams/nuggets.svg';
import pace from '#/static/images/teams/pace.svg';
import pelican from '#/static/images/teams/pelican.svg';
import piston from '#/static/images/teams/piston.svg';
import rapt from '#/static/images/teams/rapt.svg';
import rock from '#/static/images/teams/rock.svg';
import spur from '#/static/images/teams/spur.svg';
import suns from '#/static/images/teams/suns.svg';
import thunder from '#/static/images/teams/thunder.svg';
import timber from '#/static/images/teams/timber.svg';
import wizard from '#/static/images/teams/wizard.svg';
import lake from '#/static/images/teams/lake.svg';

export const TeamsMockData = [
  {
    TeamID: 1,
    Key: 'WAS',
    Active: true,
    City: 'Washington',
    Name: 'Wizards',
    LeagueID: 3,
    StadiumID: 1,
    Conference: 'Eastern',
    Division: 'Southeast',
    PrimaryColor: '0D2240',
    SecondaryColor: 'E31837',
    TertiaryColor: 'C4CED4',
    QuaternaryColor: 'FFFFFF',
    logo: wizard,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/0/02/Washington_Wizards_logo.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000001,
    NbaDotComTeamID: 1610612764,
    HeadCoach: 'Wes Unseld Jr.'
  },
  {
    TeamID: 2,
    Key: 'CHA',
    Active: true,
    City: 'Charlotte',
    Name: 'Hornets',
    LeagueID: 3,
    StadiumID: 2,
    Conference: 'Eastern',
    Division: 'Southeast',
    PrimaryColor: '1D1160',
    SecondaryColor: '1D1160',
    TertiaryColor: 'A1A1A4',
    QuaternaryColor: 'FFFFFF',
    logo: horn,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/c/c4/Charlotte_Hornets_%282014%29.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000002,
    NbaDotComTeamID: 1610612766,
    HeadCoach: 'Steve Clifford'
  },
  {
    TeamID: 3,
    Key: 'ATL',
    Active: true,
    City: 'Atlanta',
    Name: 'Hawks',
    LeagueID: 3,
    StadiumID: 3,
    Conference: 'Eastern',
    Division: 'Southeast',
    PrimaryColor: 'C8102E',
    SecondaryColor: 'FDB927',
    TertiaryColor: '000000',
    QuaternaryColor: '9EA2A2',
    logo: hawk,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/2/24/Atlanta_Hawks_logo.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000003,
    NbaDotComTeamID: 1610612737,
    HeadCoach: 'Quin Snyder'
  },
  {
    TeamID: 4,
    Key: 'MIA',
    Active: true,
    City: 'Miami',
    Name: 'Heat',
    LeagueID: 3,
    StadiumID: 4,
    Conference: 'Eastern',
    Division: 'Southeast',
    PrimaryColor: '1E1D21',
    SecondaryColor: '98002E',
    TertiaryColor: 'F9A01B',
    QuaternaryColor: null,
    logo: heat,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/f/fb/Miami_Heat_logo.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000004,
    NbaDotComTeamID: 1610612748,
    HeadCoach: 'Erik Spoelstra'
  },
  {
    TeamID: 5,
    Key: 'ORL',
    Active: true,
    City: 'Orlando',
    Name: 'Magic',
    LeagueID: 3,
    StadiumID: 5,
    Conference: 'Eastern',
    Division: 'Southeast',
    PrimaryColor: '0B77BD',
    SecondaryColor: '000000',
    TertiaryColor: 'C4CED4',
    QuaternaryColor: null,
    logo: magic,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/1/10/Orlando_Magic_logo.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000005,
    NbaDotComTeamID: 1610612753,
    HeadCoach: 'Jamahl Mosley'
  },
  {
    TeamID: 6,
    Key: 'NY',
    Active: true,
    City: 'New York',
    Name: 'Knicks',
    LeagueID: 3,
    StadiumID: 6,
    Conference: 'Eastern',
    Division: 'Atlantic',
    PrimaryColor: 'F58426',
    SecondaryColor: 'F58426',
    TertiaryColor: '9EA2A2',
    QuaternaryColor: '000000',
    logo: knick,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/2/25/New_York_Knicks_logo.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000006,
    NbaDotComTeamID: 1610612752,
    HeadCoach: 'Tom Thibodeau'
  },
  {
    TeamID: 7,
    Key: 'PHI',
    Active: true,
    City: 'Philadelphia',
    Name: '76ers',
    LeagueID: 3,
    StadiumID: 7,
    Conference: 'Eastern',
    Division: 'Atlantic',
    PrimaryColor: '1D428A',
    SecondaryColor: 'C8102E',
    TertiaryColor: 'C4CED4',
    QuaternaryColor: '002B5C',
    logo: seven6er,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/0/0e/Philadelphia_76ers_logo.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000007,
    NbaDotComTeamID: 1610612755,
    HeadCoach: 'Nick Nurse'
  },
  {
    TeamID: 8,
    Key: 'BKN',
    Active: true,
    City: 'Brooklyn',
    Name: 'Nets',
    LeagueID: 3,
    StadiumID: 8,
    Conference: 'Eastern',
    Division: 'Atlantic',
    PrimaryColor: '1E1D21',
    SecondaryColor: 'FFFFFF',
    TertiaryColor: '707271',
    QuaternaryColor: null,
    logo: nets,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/commons/4/44/Brooklyn_Nets_newlogo.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000008,
    NbaDotComTeamID: 1610612751,
    HeadCoach: 'Jacque Vaughn'
  },
  {
    TeamID: 9,
    Key: 'BOS',
    Active: true,
    City: 'Boston',
    Name: 'Celtics',
    LeagueID: 3,
    StadiumID: 9,
    Conference: 'Eastern',
    Division: 'Atlantic',
    PrimaryColor: '008248',
    SecondaryColor: 'FFFFFF',
    TertiaryColor: '000000',
    QuaternaryColor: 'BB9753',
    logo: celt,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/8/8f/Boston_Celtics.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000009,
    NbaDotComTeamID: 1610612738,
    HeadCoach: 'Joe Mazzulla'
  },
  {
    TeamID: 10,
    Key: 'TOR',
    Active: true,
    City: 'Toronto',
    Name: 'Raptors',
    LeagueID: 3,
    StadiumID: 10,
    Conference: 'Eastern',
    Division: 'Atlantic',
    // PrimaryColor: 'CE1141',
    PrimaryColor: '1E1D21',
    SecondaryColor: '000000',
    TertiaryColor: '393A96',
    QuaternaryColor: 'B4975A',
    logo: rapt,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/3/36/Toronto_Raptors_logo.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000010,
    NbaDotComTeamID: 1610612761,
    HeadCoach: 'Darko Rajakovic'
  },
  {
    TeamID: 11,
    Key: 'CHI',
    Active: true,
    City: 'Chicago',
    Name: 'Bulls',
    LeagueID: 3,
    StadiumID: 11,
    Conference: 'Eastern',
    Division: 'Central',
    PrimaryColor: '1E1D21',
    SecondaryColor: '000000',
    TertiaryColor: 'FFFFFF',
    QuaternaryColor: null,
    logo: bull,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/6/67/Chicago_Bulls_logo.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000011,
    NbaDotComTeamID: 1610612741,
    HeadCoach: 'Billy Donovan'
  },
  {
    TeamID: 12,
    Key: 'CLE',
    Active: true,
    City: 'Cleveland',
    Name: 'Cavaliers',
    LeagueID: 3,
    StadiumID: 12,
    Conference: 'Eastern',
    Division: 'Central',
    PrimaryColor: '860038',
    SecondaryColor: 'BC945C',
    TertiaryColor: '000000',
    QuaternaryColor: null,
    logo: cleve,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Cleveland_Cavaliers_logo.svg/1024px-Cleveland_Cavaliers_logo.svg.png',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000012,
    NbaDotComTeamID: 1610612739,
    HeadCoach: 'J.B. Bickerstaff'
  },
  {
    TeamID: 13,
    Key: 'IND',
    Active: true,
    City: 'Indiana',
    Name: 'Pacers',
    LeagueID: 3,
    StadiumID: 13,
    Conference: 'Eastern',
    Division: 'Central',
    PrimaryColor: 'FDBB30',
    SecondaryColor: 'FDBB30',
    TertiaryColor: 'BEC0C2',
    QuaternaryColor: null,
    logo: pace,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/1/1b/Indiana_Pacers.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000013,
    NbaDotComTeamID: 1610612754,
    HeadCoach: 'Rick Carlisle'
  },
  {
    TeamID: 14,
    Key: 'DET',
    Active: true,
    City: 'Detroit',
    Name: 'Pistons',
    LeagueID: 3,
    StadiumID: 14,
    Conference: 'Eastern',
    Division: 'Central',
    PrimaryColor: 'C8102E',
    SecondaryColor: 'C8102E',
    TertiaryColor: 'BEC0C2',
    QuaternaryColor: '000000',
    logo: piston,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/commons/c/c9/Logo_of_the_Detroit_Pistons.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000014,
    NbaDotComTeamID: 1610612765,
    HeadCoach: 'Monty Williams'
  },
  {
    TeamID: 15,
    Key: 'MIL',
    Active: true,
    City: 'Milwaukee',
    Name: 'Bucks',
    LeagueID: 3,
    StadiumID: 15,
    Conference: 'Eastern',
    Division: 'Central',
    PrimaryColor: 'E1CB9F',
    SecondaryColor: 'EEE1C6',
    TertiaryColor: '0077C0',
    QuaternaryColor: '000000',
    logo: buck,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/4/4a/Milwaukee_Bucks_logo.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000015,
    NbaDotComTeamID: 1610612749,
    HeadCoach: 'Adrian Griffin'
  },
  {
    TeamID: 16,
    Key: 'MIN',
    Active: true,
    City: 'Minnesota',
    Name: 'Timberwolves',
    LeagueID: 3,
    StadiumID: 16,
    Conference: 'Western',
    Division: 'Northwest',
    PrimaryColor: '0C2340',
    SecondaryColor: '266092',
    TertiaryColor: '79BC43',
    QuaternaryColor: 'A0A2A3',
    logo: timber,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/c/c2/Minnesota_Timberwolves_logo.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000016,
    NbaDotComTeamID: 1610612750,
    HeadCoach: 'Chris Finch'
  },
  {
    TeamID: 17,
    Key: 'UTA',
    Active: true,
    City: 'Utah',
    Name: 'Jazz',
    LeagueID: 3,
    StadiumID: 17,
    Conference: 'Western',
    Division: 'Northwest',
    PrimaryColor: '1E1D21',
    SecondaryColor: '000000',
    TertiaryColor: 'E5E7E6',
    QuaternaryColor: 'FFFFFF',
    logo: jazz,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/5/52/Utah_Jazz_logo_2022.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000017,
    NbaDotComTeamID: 1610612762,
    HeadCoach: 'Will Hardy'
  },
  {
    TeamID: 18,
    Key: 'OKC',
    Active: true,
    City: 'Oklahoma City',
    Name: 'Thunder',
    LeagueID: 3,
    StadiumID: 18,
    Conference: 'Western',
    Division: 'Northwest',
    PrimaryColor: '1E3160',
    SecondaryColor: 'EF3B24',
    TertiaryColor: 'FDBB30',
    QuaternaryColor: '002D62',
    logo: thunder,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/5/5d/Oklahoma_City_Thunder.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000018,
    NbaDotComTeamID: 1610612760,
    HeadCoach: 'Mark Daigneault'
  },
  {
    TeamID: 19,
    Key: 'POR',
    Active: true,
    City: 'Portland',
    Name: 'Trail Blazers',
    LeagueID: 3,
    StadiumID: 19,
    Conference: 'Western',
    Division: 'Northwest',
    PrimaryColor: '1E1D21',
    SecondaryColor: '000000',
    TertiaryColor: 'FFFFFF',
    QuaternaryColor: null,
    logo: blaze,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/2/21/Portland_Trail_Blazers_logo.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000019,
    NbaDotComTeamID: 1610612757,
    HeadCoach: 'Chauncey Billups'
  },
  {
    TeamID: 20,
    Key: 'DEN',
    Active: true,
    City: 'Denver',
    Name: 'Nuggets',
    LeagueID: 3,
    StadiumID: 20,
    Conference: 'Western',
    Division: 'Northwest',
    PrimaryColor: '0D2240',
    SecondaryColor: 'FEC524',
    TertiaryColor: '8B2131',
    QuaternaryColor: '244289',
    logo: nuggets,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/7/76/Denver_Nuggets.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000020,
    NbaDotComTeamID: 1610612743,
    HeadCoach: 'Michael Malone'
  },
  {
    TeamID: 21,
    Key: 'MEM',
    Active: true,
    City: 'Memphis',
    Name: 'Grizzlies',
    LeagueID: 3,
    StadiumID: 21,
    Conference: 'Western',
    Division: 'Southwest',
    PrimaryColor: '12173F',
    SecondaryColor: '12173F',
    TertiaryColor: '707271',
    QuaternaryColor: 'F5B112',
    logo: grizz,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/f/f1/Memphis_Grizzlies.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000021,
    NbaDotComTeamID: 1610612763,
    HeadCoach: 'Taylor Jenkins'
  },
  {
    TeamID: 22,
    Key: 'HOU',
    Active: true,
    City: 'Houston',
    Name: 'Rockets',
    LeagueID: 3,
    StadiumID: 22,
    Conference: 'Western',
    Division: 'Southwest',
    PrimaryColor: '1E1D21',
    SecondaryColor: '2b2b2b',
    TertiaryColor: '9EA2A2',
    QuaternaryColor: '373A36',
    logo: rock,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/2/28/Houston_Rockets.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000022,
    NbaDotComTeamID: 1610612745,
    HeadCoach: 'Ime Udoka'
  },
  {
    TeamID: 23,
    Key: 'NO',
    Active: true,
    City: 'New Orleans',
    Name: 'Pelicans',
    LeagueID: 3,
    StadiumID: 23,
    Conference: 'Western',
    Division: 'Southwest',
    PrimaryColor: '8D7748',
    SecondaryColor: 'CE0E2D',
    TertiaryColor: 'B9975B',
    QuaternaryColor: null,
    logo: pelican,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/0/0d/New_Orleans_Pelicans_logo.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000023,
    NbaDotComTeamID: 1610612740,
    HeadCoach: 'Willie Green'
  },
  {
    TeamID: 24,
    Key: 'SA',
    Active: true,
    City: 'San Antonio',
    Name: 'Spurs',
    LeagueID: 3,
    StadiumID: 24,
    Conference: 'Western',
    Division: 'Southwest',
    PrimaryColor: '1E1D21',
    SecondaryColor: '000000',
    TertiaryColor: null,
    QuaternaryColor: null,
    logo: spur,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/a/a2/San_Antonio_Spurs.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000024,
    NbaDotComTeamID: 1610612759,
    HeadCoach: 'Gregg Popovich'
  },
  {
    TeamID: 25,
    Key: 'DAL',
    Active: true,
    City: 'Dallas',
    Name: 'Mavericks',
    LeagueID: 3,
    StadiumID: 25,
    Conference: 'Western',
    Division: 'Southwest',
    PrimaryColor: '0064B1',
    SecondaryColor: '00285E',
    TertiaryColor: 'BBC4CA',
    QuaternaryColor: '000000',
    logo: mav,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/9/97/Dallas_Mavericks_logo.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000025,
    NbaDotComTeamID: 1610612742,
    HeadCoach: 'Jason Kidd'
  },
  {
    TeamID: 26,
    Key: 'GS',
    Active: true,
    City: 'Golden State',
    Name: 'Warriors',
    LeagueID: 3,
    StadiumID: 51,
    Conference: 'Western',
    Division: 'Pacific',
    PrimaryColor: '1D428A',
    SecondaryColor: 'FDB927',
    TertiaryColor: null,
    QuaternaryColor: null,
    logo: warrior,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/0/01/Golden_State_Warriors_logo.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000026,
    NbaDotComTeamID: 1610612744,
    HeadCoach: 'Steve Kerr'
  },
  {
    TeamID: 27,
    Key: 'LAL',
    Active: true,
    City: 'Los Angeles',
    Name: 'Lakers',
    LeagueID: 3,
    StadiumID: 27,
    Conference: 'Western',
    Division: 'Pacific',
    PrimaryColor: 'FDB722',
    SecondaryColor: 'FDB927',
    TertiaryColor: '000000',
    QuaternaryColor: null,
    logo: lake,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/commons/3/3c/Los_Angeles_Lakers_logo.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000027,
    NbaDotComTeamID: 1610612747,
    HeadCoach: 'Darvin Ham'
  },
  {
    TeamID: 28,
    Key: 'LAC',
    Active: true,
    City: 'Los Angeles',
    Name: 'Clippers',
    LeagueID: 3,
    StadiumID: 27,
    Conference: 'Western',
    Division: 'Pacific',
    PrimaryColor: 'D0103C',
    SecondaryColor: 'C8102E',
    TertiaryColor: '000000',
    QuaternaryColor: 'BEC0C2',
    logo: clipp,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/b/bb/Los_Angeles_Clippers_%282015%29.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000028,
    NbaDotComTeamID: 1610612746,
    HeadCoach: 'Tyronn Lue'
  },
  {
    TeamID: 29,
    Key: 'PHO',
    Active: true,
    City: 'Phoenix',
    Name: 'Suns',
    LeagueID: 3,
    StadiumID: 28,
    Conference: 'Western',
    Division: 'Pacific',
    PrimaryColor: '1D1160',
    SecondaryColor: 'E56020',
    TertiaryColor: '000000',
    QuaternaryColor: '63727A',
    logo: suns,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/d/dc/Phoenix_Suns_logo.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000029,
    NbaDotComTeamID: 1610612756,
    HeadCoach: 'Frank Vogel'
  },
  {
    TeamID: 30,
    Key: 'SAC',
    Active: true,
    City: 'Sacramento',
    Name: 'Kings',
    LeagueID: 3,
    StadiumID: 29,
    Conference: 'Western',
    Division: 'Pacific',
    PrimaryColor: '592D81',
    SecondaryColor: '5A2B81',
    TertiaryColor: '707271',
    QuaternaryColor: null,
    logo: kings,
    WikipediaLogoUrl:
      'https://upload.wikimedia.org/wikipedia/en/c/c7/SacramentoKings.svg',
    WikipediaWordMarkUrl: null,
    GlobalTeamID: 20000030,
    NbaDotComTeamID: 1610612758,
    HeadCoach: 'Mike Brown'
  }
];
