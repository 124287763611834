import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { GAME_STATUS } from '#/config/constants';
import { getTranslation } from '#/services/i18n';
import gameTheme from './game.scss';

const GameCard = ({ data, theme }) => {
  const backgroundClassName = classNames(
    gameTheme.background,
    gameTheme.cardHeight
  );

  const getGameStatus = status => {
    if (status === GAME_STATUS.LIVE || status === 'inprogress') {
      return getTranslation('liveGameStatus');
    }

    if (status === GAME_STATUS.FINAL || status === 'closed') {
      return getTranslation('finalGameStatus');
    }

    if (status === GAME_STATUS.SCHEDULED || status === 'scheduled') {
      return getTranslation('scheduleGameStatus');
    }

    return '';
  };

  const isScheduled =
    data?.status === GAME_STATUS.SCHEDULED || data?.status === 'scheduled';
  const isFinal =
    data?.status === GAME_STATUS.FINAL || data?.status === 'closed';

  return (
    <>
      <div
        className={backgroundClassName}
        style={{
          background: `linear-gradient(110deg, ${data?.homeTeamColors?.primary} 50%, #fff, ${data?.awayTeamColors?.primary} 50%)`
        }}
      >
        <div className={theme.overlay} />
        <div className={gameTheme.teamsSection}>
          <div className={gameTheme.teamContainer}>
            <img
              src={data?.homeTeamLogo}
              className={gameTheme.teamLogo}
              alt={data?.homeTeam}
            />
            <div className={gameTheme.teamAbbr}>{data?.homeTeam}</div>
          </div>
          <div className={gameTheme.teamContainer}>
            <img
              src={data?.awayTeamLogo}
              className={gameTheme.teamLogo}
              alt={data?.awayTeam}
            />
            <div className={gameTheme.teamAbbr}>{data?.awayTeam}</div>
          </div>
        </div>
      </div>
      <div className={gameTheme.gameStatusContainer}>
        <div>
          <p>{data?.homeTeamName}</p>
          <p className={gameTheme.home}>{data?.homeTeamScore}</p>
        </div>
        <div>
          <p>
            {data?.shortDate
              ?.split('.')[1]
              ?.trim()
              .toLowerCase()}
          </p>
          {isScheduled && <p>{data?.time}</p>}
          {isFinal && <p>{getGameStatus(data?.status).toLowerCase()}</p>}
        </div>
        <div>
          <p>{data?.awayTeamName}</p>
          <p className={gameTheme.away}>{data?.awayTeamScore}</p>
        </div>
      </div>
    </>
  );
};

GameCard.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object
};

export default GameCard;
