import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import Shelf from '#/components/Shelf';
import ContainerComp from '#/components/Container/Container';
import ErrorBoundary from '#/components/ErrorBoundary/ErrorBoundary';
import interpolateTextWithObject from '#/utils/interpolateTextWithObject';

const Container = props => {
  const {
    contextData,
    items,
    query,
    template,
    sportsLeague,
    nav,
    moreItems,
    index,
    id,
    title,
    displayText,
    gridConfig,
    removeEmptyContainers
  } = props;

  const interpolatedDisplayText = useMemo(
    () => interpolateTextWithObject(displayText, contextData),
    [displayText, contextData]
  );
  const hasItems = items && items.length > 0;

  const removeContainer = useCallback(() => {
    removeEmptyContainers(id);

    console.warn(`[debug] Empty container in the index ${index}`);
  }, [removeEmptyContainers, index, id]);

  if (!hasItems && !query) {
    return null;
  }

  const shelfProps = {
    contextData,
    config: {
      displayText: interpolatedDisplayText,
      index,
      id,
      moreItems,
      query,
      title,
      template,
      sportsLeague,
      prefix: `prefix-${id}`,
      ...gridConfig
    },
    nav,
    template,
    removeEmptyContainers: removeContainer
  };

  return (
    <ErrorBoundary>
      <ContainerComp {...props}>
        <Shelf {...shelfProps} items={items || []} query={query} />
      </ContainerComp>
    </ErrorBoundary>
  );
};

Container.propTypes = {
  contextData: PropTypes.object,
  gridConfig: PropTypes.shape({
    useInternalArrows: PropTypes.bool,
    gridRef: PropTypes.object,
    onChange: PropTypes.func
  }),
  id: PropTypes.string,
  index: PropTypes.number,
  items: PropTypes.array,
  moreItems: PropTypes.bool,
  nav: PropTypes.object,
  query: PropTypes.string,
  title: PropTypes.string,
  displayText: PropTypes.string,
  template: PropTypes.string,
  sportsLeague: PropTypes.string,
  removeEmptyContainers: PropTypes.func
};

export default Container;
