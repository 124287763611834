import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';

import logger from '#/utils/logger';
import { getItemsByQuery } from '#/services/ovp';
import {
  ACCEDO_CONTROL_CONTAINER_TEMPLATES,
  CONTAINER_ID_TEMPLATE_MAP,
  DEFAULT_TEMPLATE,
  ROUTES,
  TYPE,
  LEAGUES
} from '#/config/constants';
import useHistoryPush from '#/hooks/history/useHistoryPush';
import useAppConfig from '#/hooks/useAppConfig';
import { AuthContext } from '#/context/AuthContext';
import {
  CONTAINER_ID_TO_COMPONENT_MAP,
  determineItemTypeByTemplate,
  determineAligment
} from './templatesMapper';

const getItemOnClickFn = ({ historyPush, template }) => {
  return item => {
    const { type, videoUrl, league } = item;
    const isCatchup = type === TYPE.CATCHUP;
    const isGame = type === TYPE.GAME;
    const isLive = type === TYPE.LIVE;
    const isHeroTemplate = template === ACCEDO_CONTROL_CONTAINER_TEMPLATES.hero;
    if (isGame || isCatchup || isLive) {
      if (isHeroTemplate) {
        logger.debug('Redirect to: schedule');
        historyPush({
          path: league === LEAGUES.NBA ? ROUTES.schedule : ROUTES.mlbSchedule
        });

        return;
      }

      if (league === LEAGUES.MLB) {
        logger.debug('Redirect to: details');
        historyPush({
          path: ROUTES.mlbGameDetails,
          state: {
            asset: item
          }
        });

        return;
      }

      logger.debug('Redirect to: player');
      historyPush({
        path: ROUTES.player,
        state: {
          asset: item,
          isLive: false,
          videoUrl
        }
      });
    }
  };
};

const Shelf = ({
  contextData,
  items: defaultItems,
  query,
  template,
  config,
  nav,
  removeEmptyContainers
}) => {
  const { config: appConfig = {} } = useAppConfig();

  const historyPush = useHistoryPush();

  if (!CONTAINER_ID_TEMPLATE_MAP[template]) {
    logger.warn(`Template "${template}" is not supported`);
  }

  const containerId =
    CONTAINER_ID_TEMPLATE_MAP[template] ||
    CONTAINER_ID_TEMPLATE_MAP[DEFAULT_TEMPLATE];

  const ShelfComponent =
    CONTAINER_ID_TO_COMPONENT_MAP[containerId] ||
    CONTAINER_ID_TO_COMPONENT_MAP.Default;

  const itemType = determineItemTypeByTemplate(template);
  console.debug(`ITEM TYPE: ${itemType}`);
  const aligment = determineAligment(template);

  const authContext = useContext(AuthContext);
  const userId = authContext.getUser()?.userId;

  const dataSource = useMemo(() => {
    let totalNumber;
    const hasViewAll =
      template === ACCEDO_CONTROL_CONTAINER_TEMPLATES.carouselViewAllPortrait;

    // The userId is needed to get the Jump recommendations
    const contextDataUpdated = { ...contextData, userId };
    const { sportsLeague } = config;

    return {
      hasData: async () => {
        return !!totalNumber;
      },
      isPaginationAllowed: async () => {
        return !hasViewAll; // no pagination allowed for this template
      },
      getTotalNumber: async () => {
        return totalNumber;
      },
      getData: async (
        page,
        // eslint-disable-next-line default-param-last
        itemsPerPage = appConfig.ovpPageSize,
        lastIndex
      ) => {
        const { items, total } = await getItemsByQuery({
          query,
          template,
          sportsLeague,
          contextData: contextDataUpdated,
          pageNumber: page,
          itemsPerPage
        });
        totalNumber = total;
        if (hasViewAll) {
          // we include a new item in the position of the lastIndex from the ContentGrid and push the rest in the list
          // ie: ['0', '1', '2', '3'] => ['0', '1', 'viewall', '2',]
          const startIndex = lastIndex ? lastIndex - 1 : items.length - 1;
          const itemToPush = {
            viewAll: true,
            query,
            displayText: config.displayText,
            id: `${template}-${Math.random()}`
          };
          items?.splice(startIndex, 0, itemToPush);
          // Limit the totalNumber to the position of the viewAll item to avoid Navigation issues
          totalNumber = lastIndex + 1;
        }

        if (!items?.length && page <= 1) {
          removeEmptyContainers();
        }

        return {
          data: items,
          total: totalNumber
        };
      }
    };
  }, [
    template,
    userId,
    contextData,
    appConfig.ovpPageSize,
    query,
    config,
    removeEmptyContainers
  ]);

  return (
    <ShelfComponent
      ds={defaultItems.length ? null : dataSource}
      items={defaultItems.length ? defaultItems : null}
      itemType={itemType}
      gridAlignment={aligment}
      config={config}
      onClick={getItemOnClickFn({ historyPush, template })}
      nav={nav}
      query={config.query}
    />
  );
};

Shelf.propTypes = {
  config: PropTypes.object,
  contextData: PropTypes.object,
  nav: PropTypes.object,
  items: PropTypes.array,
  query: PropTypes.string,
  template: PropTypes.string,
  removeEmptyContainers: PropTypes.func
};

export default Shelf;
