import { DEFAULT_COLOR, LEAGUES, TYPE } from '#/config/constants';

const Team = rawTeam => {
  const {
    TeamID,
    Key,
    Active,
    City,
    Name,
    LeagueID,
    StadiumID,
    Conference,
    Division,
    PrimaryColor,
    SecondaryColor,
    TertiaryColor,
    QuaternaryColor,
    logo,
    WikipediaLogoUrl
  } = rawTeam;

  const getId = () => {
    return TeamID?.toString();
  };

  const getKey = () => {
    return Key;
  };

  const isActve = () => {
    return Active;
  };

  const getCity = () => {
    return City;
  };

  const getName = () => {
    return Name;
  };

  const getLeagueId = () => {
    return LeagueID;
  };

  const getStadiumId = () => {
    return StadiumID;
  };

  const getConference = () => {
    return Conference;
  };

  const getDivision = () => {
    return Division;
  };

  const getPrimaryColor = () => {
    return !PrimaryColor ? DEFAULT_COLOR.primary : `#${PrimaryColor}`;
  };

  const getSecondaryColor = () => {
    return !SecondaryColor ? DEFAULT_COLOR.secondary : `#${SecondaryColor}`;
  };

  const getTertiaryColor = () => {
    return !TertiaryColor ? DEFAULT_COLOR.tertiary : `#${TertiaryColor}`;
  };

  const getQuaternaryColor = () => {
    return !QuaternaryColor ? DEFAULT_COLOR.quaternary : `#${QuaternaryColor}`;
  };

  const getLogo = () => {
    return logo;
  };

  return {
    id: getId(),
    key: getKey(),
    isActive: isActve(),
    city: getCity(),
    name: getName(),
    leagueId: getLeagueId(),
    stadiumId: getStadiumId(),
    conference: getConference(),
    division: getDivision(),
    primaryColor: getPrimaryColor(),
    secondaryColor: getSecondaryColor(),
    tertiaryColor: getTertiaryColor(),
    quaternaryColor: getQuaternaryColor(),
    logo: getLogo(),
    type: TYPE.TEAM,
    league: LEAGUES.NBA
  };
};

export default Team;
