export const buttonRegularSpacingItemGap = 12;
export const buttonRegularSpacingPaddingHorizontal = '40px';
export const buttonRegularSpacingPaddingVertical = '24px';
export const buttonBorderRadius = '0';
export const buttonWidth = '216px';
export const buttonIcon1 = '100px';
export const buttonIconSecondary = '70px';
export const buttonHeight = '88px';
export const buttonDefault = '2px solid #fff';
export const buttonFocus = '8px solid #fff';
export const buttonDisabled = '2px solid rgba(255,255,255,0.5)';
export const buttonTextPrimaryContainerDefaultFill = '#334155';
export const buttonTextPrimaryContainerDefaultBorderWidth = '0';
export const buttonTextPrimaryContainerDefaultBorderRadius = 4;
export const buttonTextPrimaryContainerFocusFill = '#0064FF';
export const buttonTextPrimaryContainerFocusBorderWidth = '0';
export const buttonTextPrimaryContainerFocusBorderRadius = 4;
export const buttonTextPrimaryContainerDisabledFill = 'rgba(51,65,85,0.6)';
export const buttonTextPrimaryContainerDisabledBorderWidth = '0';
export const buttonTextPrimaryContainerDisabledBorderRadius = 4;
export const buttonTextPrimaryTypographyDefaultFill = '#fff';
export const buttonTextPrimaryTypographyDefaultTypography =
  '500 24px/1.1 Outfit';
export const buttonTextPrimaryTypographyFocusFill = '#fff';
export const buttonTextPrimaryTypographyFocusTypography = '500 24px/1.1 Outfit';
export const buttonTextPrimaryTypographyDisabledFill = 'rgba(253,253,253,0.8)';
export const buttonTextPrimaryTypographyDisabledTypography =
  '500 24px/1.1 Outfit';
export const buttonTextPrimaryIconDefaultFill = '#fff';
export const buttonTextPrimaryIconDefaultSizing = '40px';
export const buttonTextPrimaryIconFocusFill = '#fff';
export const buttonTextPrimaryIconFocusSizing = '40px';
export const buttonTextPrimaryIconDisabledFill = 'rgba(253,253,253,0.8)';
export const buttonTextPrimaryIconDisabledSizing = '40px';
export const buttonTextSecondaryContainerDefaultFill = '#002eff';
export const buttonTextSecondaryContainerDefaultBorderRadius = 4;
export const buttonTextSecondaryContainerDefaultBorder = '2px solid #fff';
export const buttonTextSecondaryContainerFocusFill = '#0064FF';
export const buttonTextSecondaryContainerFocusBorderRadius = 4;
export const buttonTextSecondaryContainerFocusBorder = '8px solid #fff';
export const buttonTextSecondaryContainerDisabledFill = '#002eff';
export const buttonTextSecondaryContainerDisabledBorderRadius = 4;
export const buttonTextSecondaryContainerDisabledBorder =
  '2px solid rgba(255,255,255,0.5)';
export const buttonTextSecondaryTypographyDefaultTypography =
  '500 24px/1.1 Outfit';
export const buttonTextSecondaryTypographyDefaultFill = '#fff';
export const buttonTextSecondaryTypographyFocusTypography =
  '500 24px/1.1 Outfit';
export const buttonTextSecondaryTypographyFocusFill = '#fff';
export const buttonTextSecondaryTypographyDisabledTypography =
  '500 24px/1.1 Outfit';
export const buttonTextSecondaryTypographyDisabledFill = '#fff';
export const buttonTextSecondaryIconDefaultFill = '#fff';
export const buttonTextSecondaryIconDefaultSizing = '40px';
export const buttonTextSecondaryIconFocusFill = '#fff';
export const buttonTextSecondaryIconFocusSizing = '40px';
export const buttonTextSecondaryIconDisabledFill = '#fff';
export const buttonTextSecondaryIconDisabledSizing = '40px';
export const buttonTextAllHorizontalPadding = '40px';
export const buttonTextAllVerticalPadding = '24px';
export const buttonTextAllItemSpacing = 16;
export const buttonIconPrimaryContainerDefaultFill = '#334155';
export const buttonIconPrimaryContainerDefaultBorderWidth = '0';
export const buttonIconPrimaryContainerDefaultSizing = '100px';
export const buttonIconPrimaryContainerFocusFill = '#0064FF';
export const buttonIconPrimaryContainerFocusBorderWidth = '0';
export const buttonIconPrimaryContainerFocusSizing = '100px';
export const buttonIconPrimaryContainerDisabledFill = 'rgba(51,65,85,0.6)';
export const buttonIconPrimaryContainerDisabledBorderWidth = '0';
export const buttonIconPrimaryContainerDisabledSizing = '100px';
export const buttonIconPrimaryIconDefaultFill = '#fff';
export const buttonIconPrimaryIconDefaultSizing = '40px';
export const buttonIconPrimaryIconFocusFill = '#fff';
export const buttonIconPrimaryIconFocusSizing = '40px';
export const buttonIconPrimaryIconDisabledFill = 'rgba(253,253,253,0.8)';
export const buttonIconPrimaryIconDisabledSizing = '40px';
export const buttonIconAllHorizontalPadding = 24;
export const buttonIconAllVerticalPadding = 24;
export const buttonIconAllBorderRadius = '1000px';
export const buttonIconSecondaryContainerDefaultFill = '#334155';
export const buttonIconSecondaryContainerDefaultBorderWidth = '0';
export const buttonIconSecondaryContainerDefaultSizing = '70px';
export const buttonIconSecondaryContainerFocusFill = '#0064FF';
export const buttonIconSecondaryContainerFocusBorderWidth = '0';
export const buttonIconSecondaryContainerFocusSizing = '70px';
export const buttonIconSecondaryContainerDisabledFill = 'rgba(51,65,85,0.6)';
export const buttonIconSecondaryContainerDisabledBorderWidth = '0';
export const buttonIconSecondaryContainerDisabledSizing = '70px';
export const buttonIconSecondaryIconDefaultFill = '#fff';
export const buttonIconSecondaryIconDefaultSizing = '40px';
export const buttonIconSecondaryIconFocusFill = '#fff';
export const buttonIconSecondaryIconFocusSizing = '40px';
export const buttonIconSecondaryIconDisabledFill = 'rgba(253,253,253,0.8)';
export const buttonIconSecondaryIconDisabledSizing = '40px';
export const units2 = 2;
export const units4 = 4;
export const units6 = 6;
export const units8 = 8;
export const units12 = 12;
export const units16 = 16;
export const units24 = 24;
export const units32 = 32;
export const units40 = 40;
export const units48 = 48;
export const units56 = 56;
export const units64 = 64;
export const units72 = 72;
export const units88 = 88;
export const units100 = 100;
export const units140 = 140;
export const units232 = 232;
export const units288 = 288;
export const units320 = 320;
export const units400 = 400;
export const units512 = 512;
export const spacingGlobalQuarck = 4;
export const spacingGlobalNano = 8;
export const spacingGlobalXxs = 12;
export const spacingGlobalXs = 16;
export const spacingGlobalSm = 24;
export const spacingGlobalMd = 40;
export const spacingGlobalLg = 56;
export const spacingGlobalXl = 80;
export const spacingGlobalXxl = 112;
export const spacingGlobal3xl = 144;
export const fontFamilyBrand = 'Outfit';
export const fontFamilyApple = "'SF Pro Text'";
export const fontFamilyAndroid = 'Roboto';
export const fontWeightRegular = 400;
export const fontWeightMedium = 500;
export const fontWeightBold = 700;
export const fontLineHeightTight = 1.1;
export const fontLineHeightDefault = 1.3;
export const fontLineHeightDistant = 1.5;
export const fontLetterSpacingDefault = '0.2';
export const fontLetterSpacingTight = '-0.2';
export const fontLetterSpacingDistant = '1.5';
export const fontParagraphSpacingDefault = 0;
export const fontParagraphSpacingDistant = 24;
export const fontSize8 = '8px';
export const fontSize10 = '10px';
export const fontSize12 = '12px';
export const fontSize14 = '14px';
export const fontSize16 = '16px';
export const fontSize18 = '18px';
export const fontSize20 = '20px';
export const fontSize24 = '24px';
export const fontSize26 = '26px';
export const fontSize32 = '32px';
export const fontSize40 = '40px';
export const fontSize48 = '48px';
export const fontSize56 = '56px';
export const fontSize64 = '64px';
export const fontSize72 = '72px';
export const typoTvHeading1FontFamily = 'Outfit';
export const typoTvHeading1FontWeight = 400;
export const typoTvHeading1LineHeight = 1.1;
export const typoTvHeading1FontSize = '72px';
export const typoTvHeading1LetterSpacing = '0.2';
export const typoTvHeading1ParagraphSpacing = 0;
export const typoTvHeading2FontFamily = 'Outfit';
export const typoTvHeading2FontWeight = 500;
export const typoTvHeading2LineHeight = 1.1;
export const typoTvHeading2FontSize = '56px';
export const typoTvHeading2LetterSpacing = '0.2';
export const typoTvHeading2ParagraphSpacing = 0;
export const typoTvHeading3FontFamily = 'Outfit';
export const typoTvHeading3FontWeight = 700;
export const typoTvHeading3LineHeight = 1.3;
export const typoTvHeading3FontSize = '48px';
export const typoTvHeading3LetterSpacing = '0.2';
export const typoTvHeading3ParagraphSpacing = 0;
export const typoTvHeading1a = '400 72px/1.1 Outfit';
export const typoTvTitleLargeFontFamily = 'Outfit';
export const typoTvTitleLargeFontWeight = 500;
export const typoTvTitleLargeLineHeight = 1.3;
export const typoTvTitleLargeFontSize = '40px';
export const typoTvTitleLargeLetterSpacing = '0.2';
export const typoTvTitleLargeParagraphSpacing = 0;
export const typoTvTitleMediumFontFamily = 'Outfit';
export const typoTvTitleMediumFontWeight = 500;
export const typoTvTitleMediumLineHeight = 1.3;
export const typoTvTitleMediumFontSize = '32px';
export const typoTvTitleMediumLetterSpacing = '0.2';
export const typoTvTitleMediumParagraphSpacing = 0;
export const typoTvTitleSmallFontFamily = 'Outfit';
export const typoTvTitleSmallFontWeight = 700;
export const typoTvTitleSmallLineHeight = 1.3;
export const typoTvTitleSmallFontSize = '20px';
export const typoTvTitleSmallLetterSpacing = '0.2';
export const typoTvTitleSmallParagraphSpacing = 0;
export const typoTvTitleLarge1 = '500 40px/1.3 Outfit';
export const typoTvButtonDefaultFontFamily = 'Outfit';
export const typoTvButtonDefaultFontWeight = 700;
export const typoTvButtonDefaultLineHeight = 1.1;
export const typoTvButtonDefaultFontSize = '24px';
export const typoTvButtonDefaultLetterSpacing = '1.5';
export const typoTvButtonDefaultParagraphSpacing = 0;
export const typoTvButtonDefault1 = '500 24px/1.1 Outfit';
export const typoTvButtonNav = '500 26px/1.1 Outfit';
export const typoTvBodyDefaultFontFamily = 'Outfit';
export const typoTvBodyDefaultFontWeight = 400;
export const typoTvBodyDefaultLineHeight = 1.3;
export const typoTvBodyDefaultFontSize = '24px';
export const typoTvBodyDefaultLetterSpacing = '1.5';
export const typoTvBodyDefaultParagraphSpacing = 0;
export const typoTvBodySmallFontFamily = 'Outfit';
export const typoTvBodySmallFontWeight = 400;
export const typoTvBodySmallLineHeight = 1.3;
export const typoTvBodySmallFontSize = '18px';
export const typoTvBodySmallLetterSpacing = '1.5';
export const typoTvBodySmallParagraphSpacing = 0;
export const typoTvBodySmall1 = '700 18px/1.3 Outfit'; // was 400
export const typoTvBodyDefault1 = '700 24px/1.3 Outfit'; // was 400
export const typoTvCaptionDefaultFontFamily = 'Outfit';
export const typoTvCaptionDefaultFontWeight = 400;
export const typoTvCaptionDefaultLineHeight = 1.3;
export const typoTvCaptionDefaultFontSize = '16px';
export const typoTvCaptionDefaultLetterSpacing = '1.5';
export const typoTvCaptionDefaultParagraphSpacing = 0;
export const typoTvCaptionEmphasisFontFamily = 'Outfit';
export const typoTvCaptionEmphasisFontWeight = 700;
export const typoTvCaptionEmphasisLineHeight = 1.3;
export const typoTvCaptionEmphasisFontSize = '16px';
export const typoTvCaptionEmphasisLetterSpacing = '1.5';
export const typoTvCaptionEmphasisParagraphSpacing = 0;
export const borderRadius10 = '10px';
export const borderRadiusNone = '0';
export const borderRadiusSmall = 4;
export const borderRadiusMedium = 8;
export const borderRadiusLarge = 16;
export const borderRadiusXlarge = 24;
export const borderRadiusPill = '1000px';
export const borderWidthNone = '0';
export const borderWidthThin = '1px';
export const borderWidthDefault = '2px';
export const borderWidthThick = '4px';
export const borderWidthHeavy = '8px';
export const progressbarCardSpacingPaddingHorizontal = 16;
export const progressbarCardSpacingPaddingVertical = 12;
export const progressbarCardHeight = '12px';
export const progressbarPlayerWidth = '1140px';
export const progressbarPlayerHeight = 12;
export const cardItemPortraitHeight = 512;
export const cardItemPortraitWidth = 320;
export const cardItemPortraitBorderRadius = 16;
export const cardItemLandscapeHeight = 232;
export const cardItemLandscapeWidth = 400;
export const cardItemLandscapeBorderRadius = 16;
export const cardItemHighlightedLandscapeBorderRadius = 16;
export const cardItemHighlightedPortraitBorderRadius = 16;
export const cardItemLabelFill = '#fff';
export const labelWidth = '176px';
export const labelHeight = '88px';
export const tabWidth = '112px';
export const tabHeight = '64px';
export const inputSearchWidth = '238px';
export const inputSearchHeight = '48px';
export const inputSpacingItemGap = 8;
export const inputSpacingPaddingHorizontal = 24;
export const inputSpacingPaddingVertical = 24;
export const inputDefaultColor = '#64748B';
export const inputDefaultWidth = '2px';
export const inputDefaultStyle = 'solid';
export const inputFocusedColor = '#0064FF';
export const inputFocusedWidth = '2px';
export const inputFocusedStyle = 'solid';
export const inputTypingColor = '#0064FF';
export const inputTypingWidth = '2px';
export const inputTypingStyle = 'solid';
export const inputEnteredColor = '#0064FF';
export const inputErrorColor = '#f00';
export const inputErrorWidth = '2px';
export const inputErrorStyle = 'solid';
export const inputSuccessColor = '#00ff58';
export const inputSuccessWidth = '2px';
export const inputSuccessStyle = 'solid';
export const inputDisabledColor = '#475569';
export const inputDisabledWidth = '2px';
export const inputDisabledStyle = 'solid';
export const inputContainerDefaultVerticalPadding = 24;
export const inputContainerDefaultHorizontalPadding = 24;
export const inputContainerDefaultBorderRadius = 4;
export const inputContainerDefaultMinWidth = 320;
export const inputContainerFocusedVerticalPadding = 24;
export const inputContainerFocusedHorizontalPadding = 24;
export const inputContainerFocusedBorderRadius = 4;
export const inputContainerFocusedMinWidth = 320;
export const inputContainerTypingVerticalPadding = 24;
export const inputContainerTypingHorizontalPadding = 24;
export const inputContainerTypingBorderRadius = 4;
export const inputContainerTypingMinWidth = 320;
export const inputContainerEnteredVerticalPadding = 24;
export const inputContainerEnteredHorizontalPadding = 24;
export const inputContainerEnteredBorderRadius = 4;
export const inputContainerEnteredMinWidth = 320;
export const inputContainerErrorVerticalPadding = 24;
export const inputContainerErrorHorizontalPadding = 24;
export const inputContainerErrorBorderRadius = 4;
export const inputContainerErrorMinWidth = 320;
export const inputContainerSuccessVerticalPadding = 24;
export const inputContainerSuccessHorizontalPadding = 24;
export const inputContainerSuccessBorderRadius = 4;
export const inputContainerSuccessMinWidth = 320;
export const inputContainerDisabledVerticalPadding = 24;
export const inputContainerDisabledHorizontalPadding = 24;
export const inputContainerDisabledBorderRadius = 4;
export const inputContainerDisabledMinWidth = 320;
export const inputContainerDisabledFill = '#475569';
export const inputContainerDefault1Border = '2px solid #fff';
export const inputContainerFocusBorder = '2px solid #0064FF';
export const inputContainerTyping1Border = '2px solid #0064FF';
export const inputContainerEntered1Border = '2px solid #fff';
export const inputContainerError1Border = '2px solid #f00';
export const inputContainerSuccess1Border = '2px solid #00ff1f';
export const inputContainerDisabled1Border = '2px solid rgba(255,255,255,0.5)';
export const inputContainerAllVerticalPadding = 24;
export const inputContainerAllHorizontalPadding = 24;
export const inputContainerAllBorderRadius = 4;
export const inputContainerAllMinWidth = '320';
export const inputTextDefaultFill = 'rgba(255,255,255,0.4)';
export const inputTextTypingFill = '#fff';
export const inputTextEnteredFill = '#fff';
export const inputTextErrorFill = '#fff';
export const inputTextSuccessFill = '#fff';
export const inputTextDisabledFill = 'rgba(255,255,255,0.4)';
export const inputTextDefault1Typography = '700 24px/1.3 Outfit';
export const inputTextDefault1Fill = 'rgba(255,255,255,0.4)';
export const inputTextTyping1Typography = '700 24px/1.3 Outfit';
export const inputTextTyping1Fill = '#fff';
export const inputTextEntered1Typography = '700 24px/1.3 Outfit';
export const inputTextEntered1Fill = '#fff';
export const inputTextError1Typography = '700 24px/1.3 Outfit';
export const inputTextError1Fill = '#fff';
export const inputTextSuccess1Typography = '700 24px/1.3 Outfit';
export const inputTextSuccess1Fill = '#fff';
export const inputTextDisabled1Typography = '700 24px/1.3 Outfit';
export const inputTextDisabled1Fill = 'rgba(255,255,255,0.4)';
export const inputLabelDefaultFill = '#fff';
export const inputLabelFocusedFill = '#fff';
export const inputLabelTypingFill = '#fff';
export const inputLabelErrorFill = '#fff';
export const inputLabelSuccessFill = '#fff';
export const inputLabelDisabledFill = '#fff';
export const inputLabelDefault1Typography = '700 18px/1.3 Outfit';
export const inputLabelDefault1Fill = '#fff';
export const inputLabelFocusTypography = '700 18px/1.3 Outfit';
export const inputLabelFocusFill = '#fff';
export const inputLabelError1Typography = '700 18px/1.3 Outfit';
export const inputLabelError1Fill = '#fff';
export const inputLabelSuccess1Typography = '700 18px/1.3 Outfit';
export const inputLabelSuccess1Fill = '#fff';
export const inputLabelDisabled1Typography = '700 18px/1.3 Outfit';
export const inputLabelDisabled1Fill = '#fff';
export const inputSupportDefaultFill = '#fff';
export const inputSupportFocusedFill = '#fff';
export const inputSupportTypingFill = '#fff';
export const inputSupportEnteredFill = '#fff';
export const inputSupportErrorFill = '#fff';
export const inputSupportSuccessFill = '#fff';
export const inputSupportDisabledFill = '#fff';
export const inputSupportDefault1Typography = '700 18px/1.3 Outfit';
export const inputSupportDefault1Fill = '#fff';
export const inputSupportFocusTypography = '700 18px/1.3 Outfit';
export const inputSupportFocusFill = '#fff';
export const inputSupportTyping1Typography = '700 18px/1.3 Outfit';
export const inputSupportTyping1Fill = '#fff';
export const inputSupportEntered1Typography = '700 18px/1.3 Outfit';
export const inputSupportEntered1Fill = '#fff';
export const inputSupportError1Typography = '700 18px/1.3 Outfit';
export const inputSupportError1Fill = '#fff';
export const inputSupportSuccess1Typography = '700 18px/1.3 Outfit';
export const inputSupportSuccess1Fill = '#fff';
export const inputSupportDisabled1Typography = '700 18px/1.3 Outfit';
export const inputSupportDisabled1Fill = '#fff';
export const inputIconErrorSizing = '40px';
export const inputIconErrorFill = '#f00';
export const inputIconSuccessSizing = '40px';
export const inputIconSuccessFill = '#00ff58';
export const inputIconError1Sizing = '40px';
export const inputIconError1Fill = '#f00';
export const inputIconSuccess1Sizing = '40px';
export const inputIconSuccess1Fill = '#00ff58';
export const inputDefault1 = '2px solid #fff';
export const inputFocus = '2px solid #0064FF';
export const inputTyping1 = '2px solid #0064FF';
export const inputEntered1 = '2px solid #fff';
export const inputError1 = '2px solid #f00';
export const inputSuccess1 = '2px solid #00ff1f';
export const inputDisabled1 = '2px solid rgba(255,255,255,0.5)';
export const iconCard = '88px';
export const iconButton = '40px';
export const iconMetadata = 32;
export const iconPlayerPlay = '140px';
export const iconPlayerForwardRewind = '100px';
export const iconPlayerSubtitle = '60px';
export const heroWidth = '1920px';
export const heroHeight = '880px';
export const keyboardLargeWidth = '260px';
export const keyboardLargeHeight = '64px';
export const keyboardButtonContainerRegularFill = 'rgba(255,255,255,0.2)';
export const keyboardButtonContainerRegularHeight = 64;
export const keyboardButtonContainerRegularWidth = '56px';
export const keyboardButtonContainerWideHeight = '64px';
export const keyboardButtonContainerWideWidth = '260px';
export const keyboardButtonContainerWideFill = '#1E293B';
export const keyboardButtonContainerHighlightedFill = '#0F172A';
export const keyboardButtonContainerSmallDefaultFill = 'rgba(255,255,255,0.2)';
export const keyboardButtonContainerSmallDefaultHeight = 64;
export const keyboardButtonContainerSmallDefaultWidth = '56px';
export const keyboardButtonContainerSmallFocusFill = '#0064FF';
export const keyboardButtonContainerSmallFocusHeight = 76.8;
export const keyboardButtonContainerSmallFocusWidth = '67.2px';
export const keyboardButtonContainerMediumDefaultFill = 'rgba(255,255,255,0.2)';
export const keyboardButtonContainerMediumDefaultHeight = '64px';
export const keyboardButtonContainerMediumDefaultWidth = '124px';
export const keyboardButtonContainerMediumFocusFill = '#0064FF';
export const keyboardButtonContainerMediumFocusHeight = '76.8px';
export const keyboardButtonContainerMediumFocusWidth = '148.8px';
export const keyboardButtonContainerLargeDefaultFill = 'rgba(255,255,255,0.2)';
export const keyboardButtonContainerLargeDefaultHeight = '64px';
export const keyboardButtonContainerLargeDefaultWidth = '260px';
export const keyboardButtonContainerLargeFocusFill = '#0064FF';
export const keyboardButtonContainerLargeFocusHeight = '70.4px';
export const keyboardButtonContainerLargeFocusWidth = '286px';
export const keyboardButtonLettersFill = '#fff';
export const keyboardButtonIconHeight = 40;
export const keyboardButtonIconWidth = 40;
export const keyboardButtonIconFill = '#fff';
export const keyboardButtonTypographyFill = '#fff';
export const keyboardButtonTypographyTypography = '500 24px/1.1 Outfit';
export const keyboardButtonIcon1Sizing = '40px';
export const keyboardButtonIcon1Fill = '#fff';
export const keyboardSmallWidth = '56px';
export const keyboardSmallHeight = 64;
export const keyboardMediumWidth = '124px';
export const keyboardMediumHeight = '64px';
export const scrollbarWidth = '16px';
export const scrollbarHeight = '372px';
export const soundbarWidth = '112px';
export const soundbarHeight = 8;
export const avatarDefault = '288px';
export const avatarContainerDefaultSizing = '288px';
export const avatarContainerDefaultFill = '#000';
export const avatarContainerHighlightedSizing = '288px';
export const avatarContainerHighlightedFill = '#000';
export const avatarContainerDefault1Sizing = '288px';
export const avatarContainerDefault1Fill = '#000';
export const avatarContainerDefault1Border = '0 solid rgba(0,0,0,0)';
export const avatarContainerFocusSizing = '288px';
export const avatarContainerFocusFill = '#0064FF';
export const avatarContainerFocusBorder = '0 solid #000';
export const avatarTitleFill = '#fff';
export const avatarIconHeight = '152px';
export const avatarIconWidth = '152px';
export const avatarIconFill = '#fff';
export const avatarIconDefaultSizing = '152px';
export const avatarIconDefaultFill = '#fff';
export const avatarIconFocusSizing = '152px';
export const avatarIconFocusFill = '#fff';
export const avatarHighlightedColor = '#0064FF';
export const avatarHighlightedWidth = '8px';
export const avatarHighlightedStyle = 'solid';
export const avatarItemSpacing = 32;
export const avatarAvatarSpacing = '80px';
export const avatarDefault1 = '0 solid rgba(0,0,0,0)'; //
export const avatarFocus = '0 solid #000';
export const avatarTypographyDefaultFill = '#fff';
export const avatarTypographyDefaultTypography = '500 40px/1.3 Outfit';
export const avatarTypographyFocusFill = '#fff';
export const avatarTypographyFocusTypography = '500 40px/1.3 Outfit';
export const playerWidth = '1920px';
export const playerHeight = '1080px';
export const playerIconPlayFill = '#fff';
export const playerIconPlaySizing = '140px';
export const playerIconForwardRewindFill = '#fff';
export const playerIconForwardRewindSizing = '100px';
export const playerIconSubtitleFill = '#fff';
export const playerIconSubtitleSizing = '60px';
export const zero = 0;
export const light = 24;
export const medium = 48;
export const intense = 80;
export const full = 100;
export const capitalize = 'capitalize'; // With Value "Capitalize", The First Letter In Each Word Are Capitalized. This Is Good For Titles And Subtitles.
export const uppercase = 'uppercase'; // WITH VALUE "UPPER", ALL THE LETTERS ARE UPPERCASE. THIS IS GOOD FOR GRABBING ATTENTION.
export const lowercase = 'lowercase'; // with value "lower", all the letters are lowercase. this is good for poetry perhaps.
export const none = 'none';
export const metadataRowTextFill = '#fff';
export const metadataRowBulletHeight = 4;
export const metadataRowBulletWidth = 4;
export const metadataRowBulletFill = '#fff';
export const cardsItemHighlightedColor = '#fff';
export const cardsItemHighlightedWidth = '4px';
export const cardsItemHighlightedStyle = 'solid';
export const colorGlobalBlue1 = '#8AB8FF';
export const colorGlobalBlue2 = '#3B82F6';
export const colorGlobalBlue3 = '#1D4ED8';
export const colorGlobalBlue4 = '#002866';
export const colorGlobalTangerine1 = '#FFB45D';
export const colorGlobalTangerine2 = '#FEA134';
export const colorGlobalTangerine3 = '#FE7235';
export const colorGlobalTangerine4 = '#C84D18';
export const colorGlobalWhite = '#fff';
export const colorGlobalGrey1 = '#E2E8F0';
export const colorGlobalGrey2 = 'rgba(255,255,255,0.4)';
export const colorGlobalGrey3 = '#64748B';
export const colorGlobalGrey4 = '#475569';
export const colorGlobalGrey5 = '#334155';
export const colorGlobalGrey6 = '#1E293B';
export const colorGlobalGrey7 = '#0F172A';
export const colorGlobalBlack = '#000';
export const colorGlobalRed = '#FF2B62';
export const colorGlobalGreen = '#04BB42';
export const colorGlobalYellow = '#FFB71E';
export const colorGlobalLightBlue = '#00A3FF';
export const colorPrimaryBrandDefault = '#0064FF';
export const colorPrimaryBrandEmphasis = '#1D4ED8';
export const colorPrimaryBrandEmphasis2 = '#002866';
export const colorPrimaryBrandSubtle = '#8AB8FF';
export const colorSecondaryBrandDefault = '#FEA134';
export const colorSecondaryBrandEmphasis = '#FE7235';
export const colorSecondaryBrandEmphasis2 = '#C84D18';
export const colorSecondaryBrandSubtle = '#FFB45D';
export const colorGreyscaleGrey1 = '#E2E8F0';
export const colorGreyscaleGrey2 = 'rgba(255,255,255,0.4)';
export const colorGreyscaleGrey3 = '#64748B';
export const colorGreyscaleGrey4 = '#475569';
export const colorGreyscaleGrey5 = '#334155';
export const colorGreyscaleGrey6 = 'rgba(255,255,255,0.2)';
export const colorGreyscaleGrey7 = '#0F172A';
export const colorBaseWhite = '#fff';
export const colorBaseBlack = '#000';
export const colorSystemError = '#f00';
export const colorSystemSuccess = '#00ff58';
export const colorSystemAlert = '#FFB71E';
export const colorSystemInfo = '#00A3FF';
export const colorButtonPrimaryContainerDefault = '#334155';
export const colorButtonPrimaryContainerEmphasis = '#0064FF';
export const colorButtonPrimaryContainerInactive = 'rgba(51,65,85,0.6)';
export const colorButtonPrimaryLabelDefault = '#fff';
export const colorButtonPrimaryLabelFocus = '#fff';
export const colorButtonPrimaryLabelDisabled = 'rgba(253,253,253,0.8)';
export const colorButtonPrimaryIconDefault = '#fff';
export const colorButtonPrimaryIconFocus = '#fff';
export const colorButtonPrimaryIconDisabled = 'rgba(253,253,253,0.8)';
export const colorButtonSecondaryContainerDefault = '#002eff';
export const colorButtonSecondaryContainerFocus = '#0064FF';
export const colorButtonSecondaryContainerInactive = '#002eff';
export const colorButtonSecondaryLabelDefault = '#fff';
export const colorButtonSecondaryLabelFocus = '#fff';
export const colorButtonSecondaryLabelInactive = '#fff';
export const colorButtonSecondaryIconDefault = '#fff';
export const colorButtonSecondaryIconFocus = '#fff';
export const colorButtonSecondaryIconInactive = '#fff';
export const colorButtonSecondaryBorderDefault = '#3B82F6';
export const colorButtonSecondaryBorderFocus = '#002866';
export const colorButtonSecondaryBorderInactive = '#8AB8FF';
export const colorInputFieldOutlineDefault = '#64748B';
export const colorInputFieldOutlineFocused = '#0064FF';
export const colorInputFieldOutlineTyping = '#0064FF';
export const colorInputFieldOutlineEntered = '#0064FF';
export const colorInputFieldOutlineError = '#f00';
export const colorInputFieldOutlineSuccess = '#00ff58';
export const colorInputFieldOutlineInactive = 'rgba(255,255,255,0.4)';
export const colorInputFieldContainerInactive = '#475569';
export const colorAvatarHighlightedOutline = '#0064FF';
export const colorCardItemHighlightedOutline = '#fff';
export const colorTextLight = '#fff';
export const colorTextMedium = 'rgb(255,255,255,0.2)';
export const progressBarCardHeight = '12px';
export const progressBarPlayerWidth = '1140px';
export const progressBarPlayerHeight = 12;
export const progressBarColorBackgroundFill = 'rgba(255,255,255,0.2)';
export const progressBarColorProgressFill = '#0064FF';
export const card16by9LargeDefaultHeight = '380px';
export const card16by9LargeDefaultWidth = '573px';
export const card16by9LargeDefaultBorderRadius = '10px';
export const card16by9LargeDefaultBoxShadow = '0 0 0 0 #000000';
export const card16by9LargeFocusHeight = '418px';
export const card16by9LargeFocusWidth = '630.3px';
export const card16by9LargeFocusBorderRadius = '10px';
export const card16by9LargeFocusBoxShadow = '4px 4px 20px 20px rgba(0,0,0,0.2)';
export const card16by9LargeHeight = '380px';
export const card16by9LargeWidth = '573px';
export const card16by9MediumHeight = '361px';
export const card16by9MediumWidth = '546px';
export const card16by9MediumDefaultHeight = '361px';
export const card16by9MediumDefaultWidth = '546px';
export const card16by9MediumDefaultBorderRadius = '10px';
export const card16by9MediumDefaultBoxShadow = '0 0 0 0 #000000';
export const card16by9MediumFocusHeight = '397.1px';
export const card16by9MediumFocusWidth = '600.6px';
export const card16by9MediumFocusBorderRadius = '10px';
export const card16by9MediumFocusBoxShadow =
  '4px 4px 20px 20px rgba(0,0,0,0.2)';
export const card9by16DefaultHeight = '584px';
export const card9by16DefaultWidth = '425px';
export const card9by16DefaultBorderRadius = '10px';
export const card9by16DefaultBoxShadow = '0 0 0 0 #000000';
export const card9by16FocusHeight = '642.4px';
export const card9by16FocusWidth = '467.5px';
export const card9by16FocusBorderRadius = '10px';
export const card9by16FocusBoxShadow = '4px 4px 20px 20px rgba(0,0,0,0.2)';
export const card9by16Height = '584px';
export const card9by16Width = '425px';
export const cardFocus = '4px 4px 20px 20px rgba(0,0,0,0.2)';
export const noShadow = '0 0 0 0 #000000';
export const metadataTextTypography = '700 24px/1.3 Outfit';
export const metadataTextFill = '#fff';
export const metadataBulletSizing = 4;
export const metadataBulletFill = '#fff';
export const navigationMenuFill = '#000';
export const navigationMenuBorderRadius = '0';
export const navigationMenuItemSpacing = 40;
export const navigationMenuItemCollapsedContainerDefaultFill = 'rgba(0,0,0,0)';
export const navigationMenuItemCollapsedContainerDefaultBorderWidth = '0';
export const navigationMenuItemCollapsedContainerDefaultVerticalPadding = 16;
export const navigationMenuItemCollapsedContainerDefaultHorizontalPadding = 24;
export const navigationMenuItemCollapsedContainerSelectedFill = 'rgba(0,0,0,0)';
export const navigationMenuItemCollapsedContainerSelectedBorderWidth = '0';
export const navigationMenuItemCollapsedContainerSelectedVerticalPadding = 16;
export const navigationMenuItemCollapsedContainerSelectedHorizontalPadding = 24;
export const navigationMenuItemCollapsedContainerAllBorderRadius = '1000px';
export const navigationMenuItemCollapsedIconDefaultSizing = '40px';
export const navigationMenuItemCollapsedIconDefaultFill =
  'rgba(253,253,253,0.6)';
export const navigationMenuItemCollapsedIconSelectedSizing = '40px';
export const navigationMenuItemCollapsedIconSelectedFill =
  'rgba(253,253,253,0.6)';
export const navigationMenuItemCollapsedUnderlineDefaultFill = 'rgba(0,0,0,0)';
export const navigationMenuItemCollapsedUnderlineDefaultHeight = '0';
export const navigationMenuItemCollapsedUnderlineDefaultWidth = '0';
export const navigationMenuItemCollapsedUnderlineSelectedFill = '#0064FF';
export const navigationMenuItemCollapsedUnderlineSelectedHeight = '4';
export const navigationMenuItemCollapsedUnderlineSelectedWidth = '28';
export const navigationMenuItemExpandedTypographyDefaultTypography =
  '500 26px/1.1 Outfit';
export const navigationMenuItemExpandedTypographyDefaultFill =
  'rgb(255,255,255,0.2)';
export const navigationMenuItemExpandedTypographyFocusTypography =
  '500 26px/1.1 Outfit';
export const navigationMenuItemExpandedTypographyFocusFill = '#fff';
export const navigationMenuItemExpandedTypographySelectedTypography =
  '500 26px/1.1 Outfit';
export const navigationMenuItemExpandedTypographySelectedFill = '#fff';
export const navigationMenuItemExpandedContainerDefaultFill = 'rgba(0,0,0,0)';
export const navigationMenuItemExpandedContainerDefaultBorderWidth = '0';
export const navigationMenuItemExpandedContainerDefaultVerticalPadding = 16;
export const navigationMenuItemExpandedContainerDefaultHorizontalPadding = 24;
export const navigationMenuItemExpandedContainerFocusFill = '#0064FF';
export const navigationMenuItemExpandedContainerFocusBorderWidth = '0';
export const navigationMenuItemExpandedContainerFocusVerticalPadding = 16;
export const navigationMenuItemExpandedContainerFocusHorizontalPadding = 24;
export const navigationMenuItemExpandedContainerSelectedFill =
  'rgba(51,65,85,0.6)';
export const navigationMenuItemExpandedContainerSelectedBorderWidth = '0';
export const navigationMenuItemExpandedContainerSelectedVerticalPadding = 16;
export const navigationMenuItemExpandedContainerSelectedHorizontalPadding = 24;
export const navigationMenuItemExpandedContainerAllBorderRadius = '1000px';
export const navigationMenuItemExpandedIconDefaultSizing = '40px';
export const navigationMenuItemExpandedIconDefaultFill = 'rgb(255,255,255,0.2)';
export const navigationMenuItemExpandedIconFocusSizing = '40px';
export const navigationMenuItemExpandedIconFocusFill = '#fff';
export const navigationMenuItemExpandedIconSelectedSizing = '40px';
export const navigationMenuItemExpandedIconSelectedFill = '#fff';
