function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

// R G B channel differences white: 255, 255, 255 to black: 0, 0, 0
// Multiply the difference between each individual color channel
// Bit of an arbitrary difference value of 125 comparing against,
// in testing the worst NBA team colour matches scored 90 and 112
function contrast(color1, color2) {
  const color1rgb = hexToRgb(color1);
  const color2rgb = hexToRgb(color2);

  const red =
    Math.max(color1rgb.r, color2rgb.r) - Math.min(color1rgb.r, color2rgb.r);
  const green =
    Math.max(color1rgb.g, color2rgb.g) - Math.min(color1rgb.g, color2rgb.g);
  const blue =
    Math.max(color1rgb.b, color2rgb.b) - Math.min(color1rgb.b, color2rgb.b);

  return red + green + blue > 125;
}

export { contrast };
