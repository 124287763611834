import ovp from '#/providers/ovp/accedo';
import Show from '#/models/show/show';
import { PROVIDER_TYPE } from '#/config/constants';
import { getSegmentationCachePart } from '#/utils/segmentationUtils';

import fetcher from '../../helpers/fetcher';

const getShow = async (id, segmentationValue) => {
  const segmentationCachePart = getSegmentationCachePart(segmentationValue);
  const rawShow = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-show-${id}${segmentationCachePart}`,
    fetchFn: () => ovp.getTvShowById(id, segmentationValue)
  });
  return Show(rawShow);
};

export { getShow };
