export const scheduleData = [
  {
    GameID: 19593,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-24T00:00:00',
    DateTime: '2023-10-24T19:30:00',
    AwayTeam: 'LAL',
    HomeTeam: 'DEN',
    AwayTeamID: 27,
    HomeTeamID: 20,
    StadiumID: 20,
    AwayTeamScore: 107,
    HomeTeamScore: 119,
    Updated: '2024-01-30T04:01:14',
    GlobalGameID: 20019593,
    GlobalAwayTeamID: 20000027,
    GlobalHomeTeamID: 20000020,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-24T23:30:00'
  },
  {
    GameID: 19594,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-24T00:00:00',
    DateTime: '2023-10-24T22:00:00',
    AwayTeam: 'PHO',
    HomeTeam: 'GS',
    AwayTeamID: 29,
    HomeTeamID: 26,
    StadiumID: 51,
    AwayTeamScore: 108,
    HomeTeamScore: 104,
    Updated: '2024-01-30T04:01:14',
    GlobalGameID: 20019594,
    GlobalAwayTeamID: 20000029,
    GlobalHomeTeamID: 20000026,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-25T02:00:00'
  },
  {
    GameID: 19595,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-25T00:00:00',
    DateTime: '2023-10-25T19:00:00',
    AwayTeam: 'ATL',
    HomeTeam: 'CHA',
    AwayTeamID: 3,
    HomeTeamID: 2,
    StadiumID: 2,
    AwayTeamScore: 110,
    HomeTeamScore: 116,
    Updated: '2024-01-30T04:01:21',
    GlobalGameID: 20019595,
    GlobalAwayTeamID: 20000003,
    GlobalHomeTeamID: 20000002,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-25T23:00:00'
  },
  {
    GameID: 19596,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-25T00:00:00',
    DateTime: '2023-10-25T19:00:00',
    AwayTeam: 'WAS',
    HomeTeam: 'IND',
    AwayTeamID: 1,
    HomeTeamID: 13,
    StadiumID: 13,
    AwayTeamScore: 120,
    HomeTeamScore: 143,
    Updated: '2024-01-30T04:01:21',
    GlobalGameID: 20019596,
    GlobalAwayTeamID: 20000001,
    GlobalHomeTeamID: 20000013,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-25T23:00:00'
  },
  {
    GameID: 19597,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-25T00:00:00',
    DateTime: '2023-10-25T19:00:00',
    AwayTeam: 'BOS',
    HomeTeam: 'NY',
    AwayTeamID: 9,
    HomeTeamID: 6,
    StadiumID: 6,
    AwayTeamScore: 108,
    HomeTeamScore: 104,
    Updated: '2024-01-30T04:01:21',
    GlobalGameID: 20019597,
    GlobalAwayTeamID: 20000009,
    GlobalHomeTeamID: 20000006,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-25T23:00:00'
  },
  {
    GameID: 19598,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-25T00:00:00',
    DateTime: '2023-10-25T19:00:00',
    AwayTeam: 'HOU',
    HomeTeam: 'ORL',
    AwayTeamID: 22,
    HomeTeamID: 5,
    StadiumID: 5,
    AwayTeamScore: 86,
    HomeTeamScore: 116,
    Updated: '2024-01-30T04:01:21',
    GlobalGameID: 20019598,
    GlobalAwayTeamID: 20000022,
    GlobalHomeTeamID: 20000005,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-25T23:00:00'
  },
  {
    GameID: 19599,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-25T00:00:00',
    DateTime: '2023-10-25T19:30:00',
    AwayTeam: 'CLE',
    HomeTeam: 'BKN',
    AwayTeamID: 12,
    HomeTeamID: 8,
    StadiumID: 8,
    AwayTeamScore: 114,
    HomeTeamScore: 113,
    Updated: '2024-01-30T04:01:21',
    GlobalGameID: 20019599,
    GlobalAwayTeamID: 20000012,
    GlobalHomeTeamID: 20000008,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-25T23:30:00'
  },
  {
    GameID: 19600,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-25T00:00:00',
    DateTime: '2023-10-25T19:30:00',
    AwayTeam: 'DET',
    HomeTeam: 'MIA',
    AwayTeamID: 14,
    HomeTeamID: 4,
    StadiumID: 4,
    AwayTeamScore: 102,
    HomeTeamScore: 103,
    Updated: '2024-01-30T04:01:21',
    GlobalGameID: 20019600,
    GlobalAwayTeamID: 20000014,
    GlobalHomeTeamID: 20000004,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-25T23:30:00'
  },
  {
    GameID: 19601,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-25T00:00:00',
    DateTime: '2023-10-25T19:30:00',
    AwayTeam: 'MIN',
    HomeTeam: 'TOR',
    AwayTeamID: 16,
    HomeTeamID: 10,
    StadiumID: 10,
    AwayTeamScore: 94,
    HomeTeamScore: 97,
    Updated: '2024-01-30T04:01:21',
    GlobalGameID: 20019601,
    GlobalAwayTeamID: 20000016,
    GlobalHomeTeamID: 20000010,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-25T23:30:00'
  },
  {
    GameID: 19602,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-25T00:00:00',
    DateTime: '2023-10-25T20:00:00',
    AwayTeam: 'OKC',
    HomeTeam: 'CHI',
    AwayTeamID: 18,
    HomeTeamID: 11,
    StadiumID: 11,
    AwayTeamScore: 124,
    HomeTeamScore: 104,
    Updated: '2024-01-30T04:01:21',
    GlobalGameID: 20019602,
    GlobalAwayTeamID: 20000018,
    GlobalHomeTeamID: 20000011,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-26T00:00:00'
  },
  {
    GameID: 19603,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-25T00:00:00',
    DateTime: '2023-10-25T20:00:00',
    AwayTeam: 'NO',
    HomeTeam: 'MEM',
    AwayTeamID: 23,
    HomeTeamID: 21,
    StadiumID: 21,
    AwayTeamScore: 111,
    HomeTeamScore: 104,
    Updated: '2024-01-30T04:01:21',
    GlobalGameID: 20019603,
    GlobalAwayTeamID: 20000023,
    GlobalHomeTeamID: 20000021,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-26T00:00:00'
  },
  {
    GameID: 19604,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-25T00:00:00',
    DateTime: '2023-10-25T21:00:00',
    AwayTeam: 'SAC',
    HomeTeam: 'UTA',
    AwayTeamID: 30,
    HomeTeamID: 17,
    StadiumID: 17,
    AwayTeamScore: 130,
    HomeTeamScore: 114,
    Updated: '2024-01-30T04:01:21',
    GlobalGameID: 20019604,
    GlobalAwayTeamID: 20000030,
    GlobalHomeTeamID: 20000017,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-26T01:00:00'
  },
  {
    GameID: 19605,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-25T00:00:00',
    DateTime: '2023-10-25T21:30:00',
    AwayTeam: 'DAL',
    HomeTeam: 'SA',
    AwayTeamID: 25,
    HomeTeamID: 24,
    StadiumID: 24,
    AwayTeamScore: 126,
    HomeTeamScore: 119,
    Updated: '2024-01-30T04:01:21',
    GlobalGameID: 20019605,
    GlobalAwayTeamID: 20000025,
    GlobalHomeTeamID: 20000024,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-26T01:30:00'
  },
  {
    GameID: 19606,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-25T00:00:00',
    DateTime: '2023-10-25T22:30:00',
    AwayTeam: 'POR',
    HomeTeam: 'LAC',
    AwayTeamID: 19,
    HomeTeamID: 28,
    StadiumID: 27,
    AwayTeamScore: 111,
    HomeTeamScore: 123,
    Updated: '2024-01-30T04:01:21',
    GlobalGameID: 20019606,
    GlobalAwayTeamID: 20000019,
    GlobalHomeTeamID: 20000028,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-26T02:30:00'
  },
  {
    GameID: 19607,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-26T00:00:00',
    DateTime: '2023-10-26T19:30:00',
    AwayTeam: 'PHI',
    HomeTeam: 'MIL',
    AwayTeamID: 7,
    HomeTeamID: 15,
    StadiumID: 15,
    AwayTeamScore: 117,
    HomeTeamScore: 118,
    Updated: '2024-01-30T04:01:30',
    GlobalGameID: 20019607,
    GlobalAwayTeamID: 20000007,
    GlobalHomeTeamID: 20000015,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-26T23:30:00'
  },
  {
    GameID: 19608,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-26T00:00:00',
    DateTime: '2023-10-26T22:00:00',
    AwayTeam: 'PHO',
    HomeTeam: 'LAL',
    AwayTeamID: 29,
    HomeTeamID: 27,
    StadiumID: 27,
    AwayTeamScore: 95,
    HomeTeamScore: 100,
    Updated: '2024-01-30T04:01:30',
    GlobalGameID: 20019608,
    GlobalAwayTeamID: 20000029,
    GlobalHomeTeamID: 20000027,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-27T02:00:00'
  },
  {
    GameID: 19609,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-27T00:00:00',
    DateTime: '2023-10-27T19:00:00',
    AwayTeam: 'DET',
    HomeTeam: 'CHA',
    AwayTeamID: 14,
    HomeTeamID: 2,
    StadiumID: 2,
    AwayTeamScore: 111,
    HomeTeamScore: 99,
    Updated: '2024-01-30T04:01:35',
    GlobalGameID: 20019609,
    GlobalAwayTeamID: 20000014,
    GlobalHomeTeamID: 20000002,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-27T23:00:00'
  },
  {
    GameID: 19611,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-27T00:00:00',
    DateTime: '2023-10-27T19:00:00',
    AwayTeam: 'DEN',
    HomeTeam: 'MEM',
    AwayTeamID: 20,
    HomeTeamID: 21,
    StadiumID: 21,
    AwayTeamScore: 108,
    HomeTeamScore: 104,
    Updated: '2024-01-30T04:01:35',
    GlobalGameID: 20019611,
    GlobalAwayTeamID: 20000020,
    GlobalHomeTeamID: 20000021,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-27T23:00:00'
  },
  {
    GameID: 19613,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-27T00:00:00',
    DateTime: '2023-10-27T19:30:00',
    AwayTeam: 'NY',
    HomeTeam: 'ATL',
    AwayTeamID: 6,
    HomeTeamID: 3,
    StadiumID: 3,
    AwayTeamScore: 126,
    HomeTeamScore: 120,
    Updated: '2024-01-30T04:01:35',
    GlobalGameID: 20019613,
    GlobalAwayTeamID: 20000006,
    GlobalHomeTeamID: 20000003,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-27T23:30:00'
  },
  {
    GameID: 19616,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-27T00:00:00',
    DateTime: '2023-10-27T19:30:00',
    AwayTeam: 'MIA',
    HomeTeam: 'BOS',
    AwayTeamID: 4,
    HomeTeamID: 9,
    StadiumID: 9,
    AwayTeamScore: 111,
    HomeTeamScore: 119,
    Updated: '2024-01-30T04:01:35',
    GlobalGameID: 20019616,
    GlobalAwayTeamID: 20000004,
    GlobalHomeTeamID: 20000009,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-27T23:30:00'
  },
  {
    GameID: 19618,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-27T00:00:00',
    DateTime: '2023-10-27T19:30:00',
    AwayTeam: 'OKC',
    HomeTeam: 'CLE',
    AwayTeamID: 18,
    HomeTeamID: 12,
    StadiumID: 12,
    AwayTeamScore: 108,
    HomeTeamScore: 105,
    Updated: '2024-01-30T04:01:35',
    GlobalGameID: 20019618,
    GlobalAwayTeamID: 20000018,
    GlobalHomeTeamID: 20000012,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-27T23:30:00'
  },
  {
    GameID: 19620,
    Season: 2024,
    SeasonType: 1,
    Status: 'F/OT',
    Day: '2023-10-27T00:00:00',
    DateTime: '2023-10-27T20:00:00',
    AwayTeam: 'TOR',
    HomeTeam: 'CHI',
    AwayTeamID: 10,
    HomeTeamID: 11,
    StadiumID: 11,
    AwayTeamScore: 103,
    HomeTeamScore: 104,
    Updated: '2024-01-30T04:01:35',
    GlobalGameID: 20019620,
    GlobalAwayTeamID: 20000010,
    GlobalHomeTeamID: 20000011,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-28T00:00:00'
  },
  {
    GameID: 19622,
    Season: 2024,
    SeasonType: 1,
    Status: 'F/OT',
    Day: '2023-10-27T00:00:00',
    DateTime: '2023-10-27T20:00:00',
    AwayTeam: 'HOU',
    HomeTeam: 'SA',
    AwayTeamID: 22,
    HomeTeamID: 24,
    StadiumID: 24,
    AwayTeamScore: 122,
    HomeTeamScore: 126,
    Updated: '2024-01-30T04:01:35',
    GlobalGameID: 20019622,
    GlobalAwayTeamID: 20000022,
    GlobalHomeTeamID: 20000024,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-28T00:00:00'
  },
  {
    GameID: 19624,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-27T00:00:00',
    DateTime: '2023-10-27T20:30:00',
    AwayTeam: 'BKN',
    HomeTeam: 'DAL',
    AwayTeamID: 8,
    HomeTeamID: 25,
    StadiumID: 25,
    AwayTeamScore: 120,
    HomeTeamScore: 125,
    Updated: '2024-01-30T04:01:35',
    GlobalGameID: 20019624,
    GlobalAwayTeamID: 20000008,
    GlobalHomeTeamID: 20000025,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-28T00:30:00'
  },
  {
    GameID: 19626,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-27T00:00:00',
    DateTime: '2023-10-27T21:30:00',
    AwayTeam: 'LAC',
    HomeTeam: 'UTA',
    AwayTeamID: 28,
    HomeTeamID: 17,
    StadiumID: 17,
    AwayTeamScore: 118,
    HomeTeamScore: 120,
    Updated: '2024-01-30T04:01:35',
    GlobalGameID: 20019626,
    GlobalAwayTeamID: 20000028,
    GlobalHomeTeamID: 20000017,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-28T01:30:00'
  },
  {
    GameID: 19628,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-27T00:00:00',
    DateTime: '2023-10-27T22:00:00',
    AwayTeam: 'ORL',
    HomeTeam: 'POR',
    AwayTeamID: 5,
    HomeTeamID: 19,
    StadiumID: 19,
    AwayTeamScore: 102,
    HomeTeamScore: 97,
    Updated: '2024-01-30T04:01:35',
    GlobalGameID: 20019628,
    GlobalAwayTeamID: 20000005,
    GlobalHomeTeamID: 20000019,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-28T02:00:00'
  },
  {
    GameID: 19630,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-27T00:00:00',
    DateTime: '2023-10-27T22:00:00',
    AwayTeam: 'GS',
    HomeTeam: 'SAC',
    AwayTeamID: 26,
    HomeTeamID: 30,
    StadiumID: 29,
    AwayTeamScore: 122,
    HomeTeamScore: 114,
    Updated: '2024-01-30T04:01:35',
    GlobalGameID: 20019630,
    GlobalAwayTeamID: 20000026,
    GlobalHomeTeamID: 20000030,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-28T02:00:00'
  },
  {
    GameID: 19631,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-28T00:00:00',
    DateTime: '2023-10-28T19:00:00',
    AwayTeam: 'CHI',
    HomeTeam: 'DET',
    AwayTeamID: 11,
    HomeTeamID: 14,
    StadiumID: 14,
    AwayTeamScore: 102,
    HomeTeamScore: 118,
    Updated: '2024-01-30T04:01:43',
    GlobalGameID: 20019631,
    GlobalAwayTeamID: 20000011,
    GlobalHomeTeamID: 20000014,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-28T23:00:00'
  },
  {
    GameID: 19633,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-28T00:00:00',
    DateTime: '2023-10-28T19:00:00',
    AwayTeam: 'NY',
    HomeTeam: 'NO',
    AwayTeamID: 6,
    HomeTeamID: 23,
    StadiumID: 23,
    AwayTeamScore: 87,
    HomeTeamScore: 96,
    Updated: '2024-01-30T04:01:43',
    GlobalGameID: 20019633,
    GlobalAwayTeamID: 20000006,
    GlobalHomeTeamID: 20000023,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-28T23:00:00'
  },
  {
    GameID: 19632,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-28T00:00:00',
    DateTime: '2023-10-28T19:00:00',
    AwayTeam: 'MEM',
    HomeTeam: 'WAS',
    AwayTeamID: 21,
    HomeTeamID: 1,
    StadiumID: 1,
    AwayTeamScore: 106,
    HomeTeamScore: 113,
    Updated: '2024-01-30T04:01:43',
    GlobalGameID: 20019632,
    GlobalAwayTeamID: 20000021,
    GlobalHomeTeamID: 20000001,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-28T23:00:00'
  },
  {
    GameID: 19634,
    Season: 2024,
    SeasonType: 1,
    Status: 'Final',
    Day: '2023-10-28T00:00:00',
    DateTime: '2023-10-28T19:30:00',
    AwayTeam: 'IND',
    HomeTeam: 'CLE',
    AwayTeamID: 13,
    HomeTeamID: 12,
    StadiumID: 12,
    AwayTeamScore: 125,
    HomeTeamScore: 113,
    Updated: '2024-01-30T04:01:43',
    GlobalGameID: 20019634,
    GlobalAwayTeamID: 20000013,
    GlobalHomeTeamID: 20000012,
    IsClosed: true,
    NeutralVenue: false,
    DateTimeUTC: '2023-10-28T23:30:00'
  }
];
