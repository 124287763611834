import { TYPE, LEAGUES } from '#/config/constants';

const CatchupData = rawData => {
  const { id, title, description, date, image, url } = rawData;

  const getId = () => {
    return id?.toString();
  };

  const getTitle = () => {
    return title;
  };

  const getDescription = () => {
    return description;
  };

  const getDate = () => {
    return date;
  };

  const getImage = () => {
    return image;
  };

  const getVideoUrl = () => {
    return url;
  };

  return {
    id: getId(),
    title: getTitle(),
    description: getDescription(),
    date: getDate(),
    image: getImage(),
    videoUrl: getVideoUrl(),
    type: TYPE.CATCHUP,
    league: LEAGUES.NBA
  };
};

export default CatchupData;
