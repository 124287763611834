import { useContext, useCallback, useEffect } from 'react';
import { MenuContext } from '#/context/MenuContext';

const useMenu = ({ menuVisible = true, menuOpen = true } = {}) => {
  const { show, hide, isVisible, open, close, isOpen } = useContext(
    MenuContext
  );

  useEffect(() => {
    if (menuVisible) {
      show();
    } else {
      hide();
    }

    if (menuOpen) {
      open();
    } else {
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showMenu = useCallback(() => {
    return show();
  }, [show]);

  const hideMenu = useCallback(() => {
    return hide();
  }, [hide]);

  const openMenu = useCallback(() => {
    return open();
  }, [open]);

  const closeMenu = useCallback(() => {
    return close();
  }, [close]);

  return {
    showMenu,
    hideMenu,
    isMenuVisible: isVisible,
    openMenu,
    closeMenu,
    isMenuOpen: isOpen
  };
};
export default useMenu;
