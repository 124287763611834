import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import wizard from '#/static/images/teams/wizard.svg';
import lake from '#/static/images/teams/lake.svg';
import theme from './live.scss';

const WatchCard = ({ data }) => {
  const backgroundClassName = classNames(theme.cardHeight, theme.background);
  const { homeTeam, awayTeam } = data;

  return (
    <div className={theme.cardImageWrapper}>
      <div
        className={backgroundClassName}
        style={{
          background: `url(${data?.image}) top no-repeat`
        }}
      />
      <div className={theme.gameDetailsWrapper}>
        <div className={theme.overlay} />
        <div className={theme.gameDetails}>
          <div
            className={theme.homeTeam}
            style={{ backgroundColor: `#${homeTeam.primaryColor}` }}
          >
            <div className={theme.teamLogo}>
              <img src={homeTeam.logo} alt="home team" />
            </div>
            <p className={theme.teamName}>{homeTeam.name}</p>
            <p className={theme.score}>{homeTeam.score}</p>
          </div>
          <div>
            <span className={theme.liveDot} />
            <p>Q4 1:00</p>
          </div>
          <div
            className={theme.awayTeam}
            style={{ backgroundColor: `#${awayTeam.primaryColor}` }}
          >
            <p className={theme.score}>{awayTeam.score}</p>
            <p className={theme.teamName}>{awayTeam.name}</p>
            <div className={theme.teamLogo}>
              <img src={awayTeam.logo} alt="home team" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WatchCard.propTypes = {
  data: PropTypes.object.isRequired
};

export default WatchCard;
