/**
 * @module services/sports
 * @description
 * Service to handle the fetch of sports league data calls
 */
import { LEAGUES } from '#/config/constants';

import * as nba from './nba';
import * as mlb from './mlb';

const sports = {};
// Register the Sports League Services
sports[LEAGUES.NBA] = nba;
sports[LEAGUES.MLB] = mlb;

const getSportsLeagueService = (sportsLeague, name) => {
  const service = sports[sportsLeague] || {};

  return service[name];
};

const getGameSchedule = async (sportsLeague, query, date) => {
  const getSportsLeagueGamesByDate = getSportsLeagueService(
    sportsLeague,
    'getGameBySchedule'
  );

  if (getSportsLeagueGamesByDate) {
    return getSportsLeagueGamesByDate(query, date);
  }
  console.error('NO getGamesByDate METHOD RETURNED');
};

const getGamesByDate = async (date, sportsLeague, query) => {
  const getSportsLeagueGamesByDate = getSportsLeagueService(
    sportsLeague,
    'getGamesByDate'
  );

  if (getSportsLeagueGamesByDate) {
    return getSportsLeagueGamesByDate(date, query);
  }
  console.error('NO getGamesByDate METHOD RETURNED');
};

const getGamesBySeason = async (season, seasonType, sportsLeague) => {
  const getSportsLeagueGamesBySeason = getSportsLeagueService(
    sportsLeague,
    'getGamesBySeason'
  );

  if (getSportsLeagueGamesBySeason) {
    return getSportsLeagueGamesBySeason(season, seasonType);
  }
  console.error('NO getGamesBySeason METHOD RETURNED');
};

const getPlayersByTeam = async (team, sportsLeague) => {
  const getSportsLeaguePlayersByTeam = getSportsLeagueService(
    sportsLeague,
    'getPlayersByTeam'
  );

  if (getSportsLeaguePlayersByTeam) {
    return getSportsLeaguePlayersByTeam(team);
  }
  console.error('NO getPlayersByTeam METHOD RETURNED');
};

const getReferees = async sportsLeague => {
  const getSportsLeagueReferees = getSportsLeagueService(
    sportsLeague,
    'getReferees'
  );

  if (getSportsLeagueReferees) {
    return getSportsLeagueReferees();
  }
  console.error('NO getReferees METHOD RETURNED');
};

const getAllTeams = async sportsLeague => {
  const getSportsLeagueAllTeams = getSportsLeagueService(
    sportsLeague,
    'getAllTeams'
  );

  if (getSportsLeagueAllTeams) {
    // pass the parse value whether the returned values are parsed into Team models
    return getSportsLeagueAllTeams(true);
  }
  console.error('NO getAllTeams METHOD RETURNED');
};

const getActiveTeams = async sportsLeague => {
  const getSportsLeagueActiveTeams = getSportsLeagueService(
    sportsLeague,
    'getActiveTeams'
  );

  if (getSportsLeagueActiveTeams) {
    return getSportsLeagueActiveTeams();
  }
  console.error('NO getActiveTeams METHOD RETURNED');
};

const getBoxScoreByGame = async (id, sportsLeague) => {
  const getSportsLeagueBoxScoreByGame = getSportsLeagueService(
    sportsLeague,
    'getBoxScoreByGame'
  );

  if (getSportsLeagueBoxScoreByGame) {
    return getSportsLeagueBoxScoreByGame();
  }
  console.error('NO getBoxScoreByGame METHOD RETURNED');
};

/**
 * @param {String} query The query for the OVP.
 * @param {Number} pageNumber number used for cacheId
 * @param {String} sportsLeague to determine correct data API and models
 * @returns {Array} Array of Items
 */

const getItemsByQuery = async ({ query, pageNumber, sportsLeague }) => {
  const getSportsLeagueItemsByQuery = getSportsLeagueService(
    sportsLeague,
    'getItemsByQuery'
  );

  if (getSportsLeagueItemsByQuery) {
    return getSportsLeagueItemsByQuery({ query });
  }
  console.error('NO getItemsByQuery METHOD RETURNED');

  return {
    items: [],
    total: 0
  };
};

export {
  getGamesByDate,
  getGamesBySeason,
  getPlayersByTeam,
  getReferees,
  getAllTeams,
  getActiveTeams,
  getBoxScoreByGame,
  getItemsByQuery,
  getGameSchedule
};
