import { TYPE, LEAGUES } from '#/config/constants';

const MlbTeam = rawTeam => {
  const { id, abbr, market, name } = rawTeam;

  const getId = () => {
    return id;
  };

  const getKey = () => {
    return abbr;
  };

  const getCity = () => {
    return market;
  };

  const getName = () => {
    return name;
  };

  return {
    id: getId(),
    key: getKey(),
    city: getCity(),
    name: getName(),
    league: LEAGUES.MLB,
    type: TYPE.TEAM
  };
};

export default MlbTeam;
