import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import theme from './player.scss';

const PlayerCard = ({ data }) => {
  const backgroundClassName = classNames(theme.background, theme.cardHeight);
  return (
    <>
      <div
        className={backgroundClassName}
        style={{
          backgroundImage: `url(${data?.photoUrl})`
        }}
      />
      <div className={theme.playerInfo}>
        <div className={theme.playerTeamLogo}>
          <div>
            <img src={data.team.teamLogo} alt={data.firstName} />
          </div>
        </div>
        <p>
          {data?.firstName} <br /> {data?.lastName}
        </p>
        <p>
          #{data.jersey} <span>|</span> {data.positionCategory}/{data.position}
        </p>
      </div>
    </>
  );
};

PlayerCard.propTypes = {
  data: PropTypes.object.isRequired
};

export default PlayerCard;
